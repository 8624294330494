const KEY_RESELLER_CODE = 'r-code'
const saveResellerCode = (code) => {
    sessionStorage.setItem(KEY_RESELLER_CODE, code)
}
const getResellerCode = () => {
    return sessionStorage.getItem(KEY_RESELLER_CODE)
}

export {
    saveResellerCode, getResellerCode
}