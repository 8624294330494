<template>
    <!-- REJECTED -->
    <section class="section section--top--space section--bottom--space section-activeSimCard">
        <div class="container container-1170">
            <div>
                <div class="text-center">
                    <img :src="rejectedImg" alt="" />
                    <h2>{{ isTransfer ? $t('enroll.transfer') : $t('enroll.enrollment') }} {{ $t('enroll.failure') }}</h2>
                </div>
                <h3 class="text-center mt-32">{{ $t('enroll.errorReceived') }}</h3>
                <div class="text-center mt-32">{{ error || (isTransfer ? $t('enroll.error1') : $t('enroll.error2')) }}</div>

                <div class="text-center mt-32">
                    <button v-on:click="$router.push('/PersonalInfoPage?profileStep=1')" class="btn-round btn-green btnBackgroundZero">
                        {{ $t('enroll.verifyAgain') }}
                    </button>
                </div>
            </div>
            <div class="section-activeSimCard-small-background"></div>
            <div class="section-activeSimCard-middle-background"></div>
        </div>
    </section>
</template>

<script>
import {
  getErrorStatus,
  destroyErrorStatus
} from '@/common/eligibility.service'
import { removeUserData } from '@/eligibility-enroll/nlad'

export default {
    components: {},
    methods: {},
    created() {
      this.isTransfer = this.$route.path === "/Failure"
      const e = getErrorStatus()
      if (e) {
        this.error = JSON.parse(e)[1]
      }
      destroyErrorStatus()
      removeUserData()
    },

  data() {
        return {
            isTransfer: false,
            error: "",
            checkActivation: true,
            eligibilityCheckId: '',
            applicationId: '',
            rejectedImg: require('../../../assets/images/Rejected.svg'),
            closeImg: require('../../../assets/images/Close.svg'),
        }
    },
}
</script>

<style>
.tablet-table tr {
  text-align: left;
}

.tablet-table td {
  padding: 20px;
}
.section-tablet-table td {
  border-bottom: 1px solid #475569;
  padding-top: 16px;
  padding-bottom: 16px;
}
</style>
