<template>
  <section class="section section--top--space section--bottom--space section-activeSimCard section-check-enroll-status">
    <Loader v-if="loading" />
    <div class="container container-1170">
      <div class="check-enroll-container">
        <div class="section-title text-center">
          <span class="main-green-color">{{ $t('enroll.title') }}</span>
        </div>
        <div class="text-center">
          <h3 style="color: #272d37">{{ $t('enroll.subtitle') }}</h3>
          <div>{{ $t('enroll.body') }}</div>
        </div>
        <div>
          <div class="position-relative general-card general-card-970 mb-32">
            <div class="flex-item">
              <label style="padding-bottom: 0">
                <span class="font-weight-bold mr-8">{{ $t('enroll.appId') }}:</span>{{ applicationId }}</label>
            </div>
            <div class="flex-item">
              <label style="padding-bottom: 0">
                <span class="font-weight-bold mr-8">{{ $t('enroll.eligibilityCheckId') }}:</span>{{ eligibilityCheckId }}</label>
            </div>
          </div>
        </div>

        <div class="text-center">
          <div class="text-error">{{ error }}</div>
        </div>
      </div>
      <div class="section-activeSimCard-small-background"></div>
      <div class="section-activeSimCard-middle-background"></div>
    </div>
  </section>
</template>

<script>
import eligibilityService from '../../../common/services/checkEligibility'
import {
  saveApplicationId,
  getApplicationId,
  getEligibilityCheckId,
  saveEligibilityCheckId, saveTribalStatus
} from '@/common/eligibility.service'
import { PENDING_RESOLUTION_HREF, WAITING_RESULT } from '@/eligibility-enroll/status'
import Loader from '@/components/helpers/Loader.vue'

export default {
  components: { Loader },
  computed: {
    personalData: {
      get() {
        return this.$store.state.PersonalStore.personalData
      },
    },
  },
  methods: {
    async success() {
      this.loading = true
      const resultData = await eligibilityService.postEligibilityCheck(this.applicationId, this.eligibilityCheckId)
      this.loading = false

      const appId = resultData?.data?.applicationId
      appId && saveApplicationId(appId)
      const eligibilityCheckId = resultData?.data?.eligibilityCheckId
      eligibilityCheckId && saveEligibilityCheckId(eligibilityCheckId)

      if (PENDING_RESOLUTION_HREF.includes(resultData.data.status)) {
        window.location.href = String(resultData.data._links.resolution.href)
      } else
      if (resultData.data.status === 'PENDING_CERT') {
        window.location.href = String(resultData.data._links.certification.href)
      } else
      if (WAITING_RESULT.includes(resultData.data.status)) {
        this.$router.push('/Reviewing')
      } else
      if (resultData.data.status === 'COMPLETE') {
        saveTribalStatus(resultData.data.tribalStatusConfirmed)
        this.$router.push('/EnrollStep')
      } else if (resultData.data.status === 'APPLICATION_NOT_FOUND') {
        this.$router.resultData('/EnrolmentFailure')
      } else {
        this.$router.push('/Rejected')
      }
    },
  },
  created() {
    this.applicationId = getApplicationId()
    this.eligibilityCheckId = getEligibilityCheckId()
    this.success()
  },
  data() {
    return {
      checkActivation: true,
      eligibilityCheckId: '',
      applicationId: '',
      error: '',
      loading: false
    }
  },
}
</script>

<style>
.general-card-970 {
  margin: auto;
  max-width: 970px;
  margin-bottom: 20px;
}

.btnBackgroundZero {
  background: none;
  color: #39404d !important;
}

.btnBackgroundGray {
  background: #777;
  pointer-events: none;
  color: #fff !important;
}

.tablet-table {
  margin-top: 40px;
}

.tablet-table tr {
  text-align: left;
}

.tablet-table td {
  padding: 20px;
}

.section-tablet-table {
  color: #fff;
  width: 100%;
}

.section-tablet-table td {
  border-bottom: 1px solid #475569;
  padding-top: 16px;
  padding-bottom: 16px;
}

.section-tablet-black {
  background: #000;
  padding-top: 38px;
  padding-left: 57px;
  padding-bottom: 53px;
  padding-right: 40px;
  margin-bottom: 144px;
}

.section-tablet-h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-transform: capitalize;
  color: #ffffff;
}

.section-tablet-h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -1.1px;
  color: #ffffff;
}

</style>
