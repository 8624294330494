<template>
    <section class="mt-42 mb-80">
        <div class="container">
            <div class="text-center header-title">
                <h2 class="">
                    Please fill in the information to get
                    <span class="main-blue-color">benefits</span>
                </h2>
            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="register-card">
                <div class="register-card-title">
                    <h2 class="main-blue-color mb-0">Fill out your information</h2>
                </div>
                <div class="mb-32 register-card-sub-title">
                    <h3>Registration</h3>
                    <p>Enter the mail and confirm password</p>
                </div>
                <div class="mb-16">
                    <label>Email</label>
                    <input class="w-100" type="text" placeholder="Email" />
                </div>
                <div class="mb-16">
                    <div class="register-passwords">
                        <div class="w-100 mr-16 password">
                            <label>Password</label>
                            <input class="w-100" type="text" placeholder="Password" />
                        </div>
                        <div class="w-100">
                            <label>Confirm Password</label>
                            <input class="w-100" type="text" placeholder="Confirm Password" />
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-32">
                    <div>
                        <router-link to="/" class="text-blue-btn">Back</router-link>
                    </div>
                    <div>
                        <router-link to="/ShippigDetailsPage" class="blue-btn">Next</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    components: {},
    methods: {},
    data() {
        return {}
    },
}
</script>
<style scoped>
.register-card {
    width: 1170px;
    margin: auto;
}
.header-title > h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

p {
    font-size: 12px;
    line-height: 14px;
}

.register-card-title {
    text-align: center;
    margin-bottom: 32px;
}

.register-passwords {
    display: flex;
}

@media (max-width: 768px) {
    .register-card {
        width: auto;
    }
    .container {
        padding: 0;
    }
    .header-title > h2 {
        display: block;
    }
    .register-passwords {
        display: block;
    }
    .register-passwords > .password {
        margin-bottom: 16px;
    }
}

@media (max-width: 376px) {
    .container {
        padding: 12px;
    }
    .header-title > h2 {
        font-size: 35px;
        line-height: 35.22px;
        font-weight: 500;
    }
    .register-card {
        width: 343px;
        padding: 24px 16px;
    }
    .register-card-title {
        text-align: left;
        margin-bottom: 16px;
    }
    .register-card > .register-card-sub-title > h3,
    .register-card > .register-card-title > h2 {
        font-size: 25px;
        line-height: 29.35px;
        font-weight: 500;
    }
}
</style>
