<template>
    <section class="mt-42 mb-160">
        <div class="container " :class="{'full-height':radio_flag===2}">
            <div class="home-card">
                <div class="text-center">
                    <h2 class="main-blue-color">Fill out your information</h2>
                    <h3 class="mt-32 mb-32 text-left">
                        Do you want us to check your identity with the last four digits of your Social Security Number?
                    </h3>
                </div>
                <div class="mb-32">
                    <div class="mb-16">
<!--                        <input type="radio" id="huey" name="drone" v-model="radio" checked />-->
                        <input type="radio" v-model="radio" value="1" class="radio-size"
                               @change="changeRadio(1)"  name="data" />
                        <span class=" radio-button-text">Yes, use the last four digits of my Social
                            Security Number</span>
                    </div>
                    <div>
                        <input type="radio" v-model="radio" value="2" class="radio-size"
                               @change="changeRadio(2)"   name="data" />
                            <span class=" radio-button-text"> No, I want to provide an official document
                             (Driver License, Taxpayer Identification Number, Passport, etc.) or use my Tribal ID Number
                            </span>
                    </div>
                    <div v-if="radio_flag === 2">
<!--                        <label>Other Government Identification</label>-->
                        <div style="width: 213px">
                            <CustomDropdown
                                    :label="'Other Government Identification'"
                                    placeholder="Select"
                                    @valueSelected="valueSelected = $event"
                                    :modelValue="this.monthStr"
                                    :list="selectGovernmentOptions"
                            />
                        </div>

                        <!--<select name="cars" id="cars" >
                            <option v-for="(item,idx) in selectGovernmentOptions" v-model="item.value"
                                    :key="item.value+idx">{{item.text}}</option>
                        </select>-->
                        <label class="mt-1">Attach photo of the ID</label>
                        <span class="radio2span">Please attach a scanned copy or picture of your form of identification. Files must be less
                            than 10 MB and one of the following file types: jpg, jpeg, png, pdf, or gif.</span>
                        <div class="mt-1">
                            <router-link to="" class="blue-btn">Select Photo</router-link>
                        </div>
                    </div>
                    <div v-else>
                        <div class="mt-32">Last 4 digits of your Social Security Number</div>
                        <div class="mt-16">XXXX-XX- <input type="text" class="XXXX-input-box" placeholder="XXXX" /></div>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-32">
                    <div>
                        <router-link to="/ContactInformation" class="text-blue-btn">Back</router-link>
                    </div>
                    <div class="next-button">
                        <router-link to="/PersonalInformationPage" class="blue-btn">Next</router-link>
                    </div>
                </div>
                <div class="text-error text-right">{{ error }}</div>
            </div>
        </div>
    </section>
</template>

<script>
import checkValidation from '../../validation/checkValidation'
import CustomDropdown from '../../../components/helpers/CustomDropdown.vue'


export default {
    components: {CustomDropdown,/*CustomDropdown1*/},
    methods: {
        success() {
            // if (this.showHide === true) {
            //     const contactState = this.findShortCodeState(this.state)?.shortcode
            //     this.$store.dispatch('addToPersonal', ['contactState', contactState])
            //     this.$store.dispatch('addToPersonal', ['contactAddress', this.address])
            //     this.$store.dispatch('addToPersonal', ['contactZipCode', this.zipCode])
            // }

            this.$router.push('/QuestionPage')
        },
        goNext() {
            const validation = {}

            if (this.radio === null) {
                this.error = 'Option is mandatory'
                return
            }

            checkValidation(validation) === true ? this.success() : (this.error = 'Zip code is mandatory')
        },
        changeRadio(value){
            this.radio_flag = value
        }
    },
    data() {
        return {
            radio: 'no',
            radio_flag: 1,
            // No, I Want to provide an official document (Driver License, Taxpayer Identification Number, Passport, etc.) or use my Tribal ID Number
            selectGovernmentOptions:[
                {text:'Driver License',value:'driver_license'},
                {text:'Taxpayer Identification Number',value:'taxpayer_identification_number'},
                {text:'Passport',value:'passport'},
                ],
            valueSelected:'',
            error: '',
        }
    },
}
</script>
<style>
.home-card {
    width: inherit;
    height: inherit;
    background: #ffffff;
    box-shadow: 0px 8px 16px -4px rgba(0, 12, 32, 0.12);
    border-radius: 32px;
    padding: 44px 84px;
    gap: 32px;
}

.home-card input {
    padding: 16px 12px;
    gap: 8px;
    background: #ffffff;
    border: 1px solid #e5e7eb;
}
</style>
<style scoped>
    .container{
        width: 1290px;
        height: 516px;
        box-shadow: unset;
    }
    .full-height{
        height: 100%!important;
    }
    .XXXX-input-box{
        width:  219px;
        height:36px;
        padding: 8px 12px 8px 12px;
        border-radius: 8px;
        gap: 8px;

    }
    .radio-button-text{
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.03em;
        text-align: left;
        margin-left: 1rem;
    }
    .radio-size{
        width: 20px;
        height: 20px;
        padding: 0 !important;
        border-radius: 50%;
        }
        .radio-size:checked {
            width: 20px;
            height: 20px;
            background-size: cover !important;
            background-repeat: no-repeat;
            background: url('../../../../src/assets/images/general/Radio.svg');
        }
    label{
        font-weight: 400;
        font-size:14px ;
        line-height: 22px;
    }
    .radio2span{
        font-weight: 400;
        font-size:12px ;
        line-height: 22px;
    }

    @media (max-width: 1400px) {
        .container{
            width: 100%;
            height: 100%;
        }
    }
    @media (min-width: 1400px) {
        .next-button{
            margin-top: -20px;
        }
    }
</style>
