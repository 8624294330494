<template>
    <section>
        <div class="container-box">
            <div class="text-center mb-62">
                <h2>How to be eligible</h2>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-4 inline-flex" >
                    <div :style="{marginTop: size_width - 25 > 575 ? 0 : '20px'}">
                        <img :src=" size_width - 25 > 575  ? QualifyImage : QualifyImageVertical" class="eligibleImage" alt="" />
                    </div>

                    <div>
                        <div class="eligibleSubtitle eligibleSubtitle-checker">Check if You Qualify</div>
                        <p class="eligibleParagraph">
                            It is very important for the customer to be aware of the customer's needs, but it is the same
                            thing that happens at the same time with some great labor and pain
                        </p>
                    </div>

                </div>
                <div class="col-md-4 col-sm-4 inline-flex">
                    <div :style="{marginTop: size_width - 25 > 575 ? 0 : '20px'}">
                        <img :src="size_width -25 > 575  ? EnrollImage : EnrollImageVertical" class="eligibleImage" alt="" />
                    </div>
                    <div>
                        <div class="eligibleSubtitle">Enroll in ACP</div>
                        <p class="eligibleParagraph">
                            It is very important for the customer to be aware of the customer's needs, but it is the same
                            thing that happens at the same time with some great labor and pain
                        </p>
                    </div>

                </div>
                <div class="col-md-4 col-sm-4 inline-flex">
                    <div :style="{marginTop: size_width - 25 > 575 ? 0 : '20px'}">
                        <img :src="ReceiveImage" class="eligibleImage" alt="" />
                    </div>
                    <div>
                        <div class="eligibleSubtitle">Receive FREE Mobile Service and Tablet</div>
                        <p class="eligibleParagraph">
                            It is very important for the customer to be aware of the customer's needs, but it is the same
                            thing that happens at the same time with some great labor and pain
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    components: {},
    methods: {
        width () {
            this.size_width = window.innerWidth
        },
    },
    data() {
        return {
            QualifyImage: require('../../../assets/images/sections/HomePage/03_Eligible/Qualify.svg'),
            QualifyImageVertical: require('../../../assets/images/sections/HomePage/03_Eligible/vertical_vector.svg'),
            EnrollImage: require('../../../assets/images/sections/HomePage/03_Eligible/Enroll.svg'),
            EnrollImageVertical: require('../../../assets/images/sections/HomePage/03_Eligible/EnrollImageVertical.svg'),
            ReceiveImage: require('../../../assets/images/sections/HomePage/03_Eligible/Receive.svg'),
            size_width:0,
        }

    },
    created() {
        this.size_width = window.innerWidth
        window.addEventListener('resize', this.width)
    }
}
</script>
<style>
@import '../../../assets/styles/components/sections/HomePage/03_Eligible.css';
.white-space {
    white-space: nowrap;
}
    .container-box{
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        width: 100%;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        margin: 0 auto;
        max-width: 1290px;

    }
</style>
<style scoped>
    @media (max-width: 575px) {
        .eligibleImage{
            width: 300%;
        }
        .inline-flex{
            display: inline-flex;
        }
    }
    @media (max-width: 667px) {
        .eligibleSubtitle{
            font-weight: 500;
            font-size: 20px;
            line-height: 23px;
        }
        h2{
            font-weight: 500;
            font-size: 30px;
            line-height: 35px;
        }
    }
    @media (min-width: 768px) and (max-width: 800px) {
          .eligibleSubtitle-checker{
              max-width: 150px;
          }
    }
</style>

