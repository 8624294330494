<template>
  <section class="section section-profileTitle font-raleway">
    <div class="container container-1170">
      <h2>Check <span class="main-green-color">Eligibility</span></h2>
    </div>
  </section>
  <section class="section section-profile section-profile-eligibility pb-80 font-raleway">
    <div class="container container-1170">
      <div class="row">
        <div class="col-md-3">
          <ProflieBreadcrumbs :stepNum="profileStep"/>
        </div>
        <div class="col-md-8 col general-card-wrapper">
          <h4 class="mb-24">General Info</h4>
          <div class="general-card">
            <div class="d-flex gap-16px mb-16">
              <div class="flex-item">
                <label>First Name <span class="label-required">*</span></label>
                <input
                    @keypress="onlyAlhpabet($event)"
                    required
                    placeholder="John"
                    v-model="firstName"
                    maxlength="24"
                    type="text"
                />
              </div>
              <div class="flex-item">
                <label>Last Name <span class="label-required">*</span></label>
                <input
                    @keypress="onlyAlhpabet($event)"
                    required
                    placeholder="Doe"
                    v-model="lastName"
                    maxlength="24"
                    type="text"
                />
              </div>
            </div>
            <label>Date of birth <span class="label-required">*</span></label>
            <div class="d-flex gap-16px mb-16">
              <div class="flex-item">
                <div>
                  <CustomDropdown
                      class="personal-info-dropdown"
                      placeholder="Month"
                      :modelValue="monthStr"
                      :list="[
                                    {text:'January',value:'january'},
                                    {text : 'February',value : 'february'},
                                    {text : 'March',value : 'march'},
                                    {text : 'April',value : 'april'},
                                    {text : 'May',value : 'may'},
                                    {text : 'June',value : 'june'},
                                    {text : 'July',value : 'july'},
                                    {text : 'August',value : 'august'},
                                    {text : 'September',value : 'september'},
                                    {text : 'October',value : 'october'},
                                    {text : 'November',value : 'november'},
                                    {text : 'December',value : 'december'},
                                        ]"
                      v-model="monthStr"
                  />
                </div>
              </div>
              <div class="flex-item">
                <div>
                  <CustomDropdown
                      type="number"
                      v-model="day"
                      :modelValue="day"
                      :list="generateDayData()"
                      class="personal-info-dropdown"
                      placeholder="Day"
                  />
                </div>
              </div>
              <div class="flex-item">
                <div>
                  <CustomDropdown
                      type="number"
                      class="personal-info-dropdown"
                      v-model="year"
                      :modelValue="year"
                      :list="generatYearData()"
                      placeholder="Year"
                  />
                </div>
                <div class="text-error"></div>
              </div>
            </div>
            <div class="row  d-mobile-column" style="margin-bottom: 16px !important;">
              <div class="col-12 col-md-6">
                <label
                >Last 4 digits of Social Security Number
                  <span class="label-required">*</span></label
                >
                <input
                        style="width: 100%"
                    @keypress="this.onlyNumberKey($event)"
                    required
                    placeholder="0000"
                    type="text"
                    maxlength="4"
                    v-model="ssn4"
                    class="personal-info-last4"
                />
              </div>
              <div class=" col-12 col-md-6 mt-md-0 mt-3">
                <label>Language <span class="label-required">*</span></label>
                <CustomDropdown
                    class="personal-info-dropdown"
                    placeholder="Language"
                    :modelValue="this.language"
                    :list="[
                                        'English',
                                        'Spanish'
                                    ]"
                    v-model="language"
                />
                <!--'Chinese', //hidden by MP-994
                    'Korean',
                    'Japanase',
                    'Vietnamese',
                    'Tagalog', -->
              </div>
            </div>
            <div>
              <p class="font-size-14" style="line-height: 22px;">
                Your Social Security Number is required in order to verify your eligibility and receive
                benefits from our Government Assistance Program.
              </p>
            </div>
            <div class="d-flex flex-row-reverse">
              <button :disabled="nextDisabled" class="btn-round btn-green" @click="finilizeStep">Continue</button>
            </div>
            <div class="text-error text-right">{{ error }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProflieBreadcrumbs from '../../../helpers/ProfileBreadcrumbs.vue'
import CustomDropdown from '../../../helpers/CustomDropdown.vue'
import { checkDay, checkMonth, checkYear } from '../../../validation/birthday'
import { getDaysInMonth, generatYear } from '../../../generation/date'
import checkValidation from '../../../validation/checkValidation'
import { checkString } from '../../../validation/value'
import onlyAlhpabet from '@/components/validation/onlyAlhpabet'

const monthList = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const monthNumber = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12
}

export default {
  components: {
    ProflieBreadcrumbs,
    CustomDropdown
  },
  data() {
    return {
      monthStr: monthList[this.getCurrrentMonthNumber()],
      IsDropdownListActive: false,
      itemValueSelected: '',
      error: '',
      day:
          (String(this.parseBOD('day', this.$store.state.PersonalStore?.personalData?.dob))[0] == '0'
              ? this.parseBOD('day', this.$store.state.PersonalStore?.personalData?.dob)[1]
              : this.parseBOD('day', this.$store.state.PersonalStore?.personalData?.dob)) || '',
      month: this.parseBOD('month', this.$store.state.PersonalStore?.personalData?.dob) || '',
      year: this.parseBOD('year', this.$store.state.PersonalStore?.personalData?.dob) || '',
      language: this.$store.state.PersonalStore?.personalData?.language || 'English',
      ssn4: +this.$store.state.PersonalStore?.personalData?.ssn4 || '',
      firstName: this.$store.state.PersonalStore?.personalData?.firstName,
      lastName: this.$store.state.PersonalStore?.personalData?.lastName
    }
  },
  created() {
    if (this.$store.state.PersonalStore?.personalData?.zipCode.length < 1) {
      this.$router.push('/ZipCodePage')
    }
  },
  computed: {
    profileStep() {
      return this.$route.query.profileStep
    },
    personalData: {
      get() {
        return this.$store.state.PersonalStore.personalData
      }
    },
    nextDisabled() {
      return this.firstName.length < 2 || this.lastName.length < 2 || !this.day || !this.year || this.ssn4.length < 4 || !this.language
    }
  },
  methods: {
    onlyAlhpabet,
    validate() {
      if (this.firstName.length < 2) {
        this.error = 'First Name is mandatory'
        return
      }
      if (this.lastName.length < 2) {
        this.error = 'Last name is mandatory'
        return
      }
      if (this.day == '' || this.year == '') {
        this.error = 'BOD is mandatory'
        return
      }
      if (this.ssn4 == '') {
        this.error = 'Ssn should be 4 numbers'
        return
      }
      if (this.language == '') {
        this.error = 'Language is mandatory'
        return
      }
    },
    getCurrrentMonthNumber() {

      const numberMonth =
          +(String(this.parseBOD('month', this.$store.state.PersonalStore?.personalData?.dob))[0] == '0'
              ? +this.parseBOD('month', this.$store.state.PersonalStore?.personalData?.dob)[1] - 1
              : +this.parseBOD('month', this.$store.state.PersonalStore?.personalData?.dob)) - 1 || ''
      console.log(numberMonth,'-------------numberMonth')
      return numberMonth < 0 ? 0 : numberMonth
    },
    onlyNumberKey(evt) {
      evt = evt ? evt : window.event
      let charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    parseBOD(period, value) {
      if (value.length < 2 && value === undefined) return

      const splitValue = value.split('-')

      if (period === 'year') return splitValue[0]
      if (period === 'month') return splitValue[1]
      if (period === 'day') return splitValue[2]
    },
    zeroFill(i) {
      return (i < 10 ? '0' : '') + i
    },
    setRegisterData() {
      const month = monthNumber[this.monthStr]

      this.$store.dispatch('addToPersonal', ['language', this.language])
      this.$store.dispatch('addToPersonal', ['firstName', this.firstName])
      this.$store.dispatch('addToPersonal', ['lastName', this.lastName])
      this.$store.dispatch('addToPersonal', ['ssn4', this.ssn4.toString()])
      this.$store.dispatch('addToPersonal', [
        'dob',
        this.year + '-' + this.zeroFill(month) + '-' + this.zeroFill(this.day)
      ])

      this.$router.push('/ContactInfoPage?profileStep=2')
    },
    generatYearData() {
      return generatYear(1900)
    },
    generateDayData() {
      return getDaysInMonth(this.month, this.year)
    },
    finilizeStep() {
      const month = monthNumber[this.monthStr]

      const validation = {
        day: checkDay(this.day),
        month: checkMonth(month),
        year: checkYear(this.year),
        language: checkString(this.language),
        firstName: checkString(this.firstName),
        lastName: checkString(this.lastName),
        ssn4: checkString(this.ssn4)
      }

      if (this.firstName.length < 2) {
        this.error = 'First Name is mandatory'
        return
      }

      if (this.lastName.length < 2) {
        this.error = 'Last name is mandatory'
        return
      }

      if (!this.day || !month || !this.year) {
        this.error = 'DOB is mandatory'
        return
      }

      if (!this.ssn4) {
        this.error = 'Ssn should be 4 numbers'
        return
      }

      if (!this.language) {
        this.error = 'Language is mandatory'
        return
      }

      checkValidation(validation) === true ? this.setRegisterData() : (this.error = 'All fields are mandatory')
    },
    selectItem(itemValueSelected) {
      this.itemValueSelected = itemValueSelected
      this.IsDropdownListActive = false
    },
    showDropdownList() {
      this.IsDropdownListActive = !this.IsDropdownListActive
    }
  }
}
</script>

<style scoped>
button[disabled] {
  background: gray;
  border: gray;
}

button {
  padding: 10px 32.23px !important;
}

.flex-item input {
  width: 100%;
}

@media (min-width: 1025px) {
  .general-card-wrapper{
    max-width: 670px;
  }

  .general-card{
    min-width: 670px;
    height: 506px;
  }
}
@media (max-width: 1024px) {
  .general-card{
    min-width: 670px;
    height: 488px;
  }
  .general-card-wrapper{
    max-width: 670px;
    margin: 0 auto;
    padding: 0;
  }
}
@media (max-width: 768px) {
  h2 {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .general-card{
    min-width: 100% !important;
    height: unset !important ;
  }
  .general-card-wrapper{
    max-width: 100% !important;
    margin: 0 auto;
    padding: 10px;
  }
  h4{
    font-size: 24px;
    line-height: 33px;
  }
}
@media (min-width: 768px) {
  h4 {
    font-size: 40px;
    line-height: 46px;
  }
}

:deep(.personal-info-dropdown input) {
  height: 36px !important;
}
</style>