<template>
    <div class="header">
        <div class="container">
            <div class="row">
                <div class="header-icon-width">
                    <!--                    col-5 col-md-3 col-sm-4-->
                    <div class="d-flex align-items-center">
                        <div class="custom-mobile-menu-d-bloke custom-d-bloke icon-Burger">
                            <div @click="ShowNavBar">
                                <img :src="iconBurger" alt="" class="iconBurger" />
                            </div>
                            <div v-if="showNavBar" class="nav-bar-parent">
                                <div @click="showNavBar = false">
                                    <img :src="iconClose" alt="" class="iconClose" />
                                </div>
                                <div>
                                    <ul>
                                        <li @click="showNavBar = false">
                                            <router-link
                                                to="/"
                                                active-class=" header-menu-link--active"
                                                class="header-menu-link"
                                                >Home</router-link
                                            >
                                        </li>
                                        <li @click="showNavBar = false">
                                            <router-link
                                                to="/AboutACPPage"
                                                active-class=" header-menu-link--active"
                                                class="header-menu-link white-space"
                                            >
                                                About ACP
                                            </router-link>
                                        </li>

                                        <li @click="showNavBar = false">
                                            <router-link
                                                to="/ContactUsPage"
                                                active-class=" header-menu-link--active"
                                                class="header-menu-link white-space"
                                            >
                                                Contact us
                                            </router-link>
                                        </li>
                                        <li @click="showNavBar = false">
                                            <router-link
                                                to="/ResourcesPage"
                                                active-class=" header-menu-link--active"
                                                class="header-menu-link white-space"
                                            >
                                                Resources
                                            </router-link>
                                        </li>
                                        <li @click="showNavBar = false">
                                            <router-link
                                                to="/CheckEnrollStatusPage"
                                                active-class="header-menu-link--active"
                                                class="header-menu-link white-space"
                                            >
                                                Check Application Status
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <router-link to="/">
                            <img :src="iconLogo" alt="" class="iconLogo" />
                        </router-link>
                    </div>
                </div>
                <!--                col-6 col-md-6-->
                <div style="width: 700px" class="custom-d-none custom-d-bloke">
                    <ul class="header-menu">
                        <li style="padding-left: 0">
                            <router-link to="/" active-class=" header-menu-link--active" class="header-menu-link"
                                >Home</router-link
                            >
                        </li>
                        <li>
                            <router-link
                                to="/AboutACPPage"
                                active-class=" header-menu-link--active"
                                class="header-menu-link white-space"
                            >
                                About ACP
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                to="/ContactUsPage"
                                active-class=" header-menu-link--active"
                                class="header-menu-link white-space"
                            >
                                Contact us
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                to="/ResourcesPage"
                                active-class=" header-menu-link--active"
                                class="header-menu-link white-space"
                            >
                                Resources
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                to="/CheckEnrollStatusPage"
                                style="background: unset"
                                active-class="header-menu-link--active"
                                class="header-menu-link white-space"
                            >
                                Check Application Status
                            </router-link>
                        </li>
                    </ul>
                </div>
                <!--                class="col-7 col-md-3 col-sm-8"-->
                <div class="validate-button-style">
                    <div class="d-flex header-right">
                       <!-- <div class="ml-85 language-select">
                            <div class="dropdown dropdownHeader">
                                <button
                                    class="btn dropdown-toggle text-uppercase"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    @click="toggle"
                                    @blur="blurSelecte"
                                >
                                    {{ select_value }}
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" href="#">FR</a>
                                </div>
                                <div v-if="visible" class="select-box">
                                    <ul>
                                        <li
                                            v-for="(item, index) in list"
                                            :key="index"
                                            style="color: black; cursor: pointer"
                                            @click.passive="switchLanguage(item)"
                                        >
                                            {{ $t(`locale.${item}`) }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>-->
                        <router-link to="/ZipCodePage" class="link ghost-btn white-color h-46 mt-57 text-nowrap "
                            >Validate your eligibility</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const iconLogo = ref(require('../../assets/images/components/header/logo.svg'))
const iconBurger = ref(require('../../assets/images/components/header/Hamburger.svg'))
const iconClose = ref(require('../../assets/images/icons/Close_MD.svg'))

// import Tr from '@/i18n/translation'
import { ref, onMounted, onUnmounted } from 'vue'

const windowWidth = ref(window.innerWidth)
const windowHeight = ref(window.innerHeight)

// const list = ref(Tr.supportedLocales)
// const select_value = ref(Tr.guessDefaultLocale())
// const visible = ref(false)
let showNavBar = ref(false)

/*const switchLanguage = async (value) => {
    select_value.value = value
    await Tr.switchLanguage(value)
}*/

const handleResize = () => {
    windowWidth.value = window.innerWidth
    windowHeight.value = window.innerHeight
}
/*const toggle = () => {
    visible.value = !visible.value
}*/
/*const blurSelecte = () => {
    setTimeout(() => {
        visible.value = false
    }, 150)
}*/
const ShowNavBar = () => {
    showNavBar.value = !showNavBar.value
}

onMounted(() => {
    window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
    window.removeEventListener('resize', handleResize)
})
</script>

<style>
@import '../../assets/styles/components/layouts/Header.css';
</style>
<style scoped>
    .ghost-btn{
        margin-left: auto;
    }
@media (max-width: 374px) {
    .ghost-btn {
        padding: 12px 2px;
    }
    .header {
        padding: 2px !important;
        padding: 16px 2px !important;
    }
}

@media (max-width: 768px) {
    .header {
        height: 136px !important;
        padding: 16px 0 16px 0;
    }
    .header-right {
        justify-content: end;
        height: 100%;
        align-items: center;
    }
    .iconLogo {
        margin-top: 0;
        margin-bottom: 0;
    }
    .ghost-btn {
        margin: auto 0;
    }
    .dropdownHeader {
        padding-top: 0;
    }
    .iconClose {
        margin-top: 16px !important;
        margin-left: 29px !important;
        width: 31px !important;
        height: 31px !important;
    }
    .validate-button-style {
        width: 200px !important;
        position: relative;
        left: 50px;
    }
    .header-icon-width {
        position: relative;
        right: 50px;
    }
}
@media (max-width: 376px) {
    .iconLogo {
        width: 65px;
        height: 70px;
    }
    .header {
        padding: 16px;
    }
    .dropdownHeader {
        display: none;
    }
    .ghost-btn {
        padding: 12px 16px;
        font-size: 14px;
        line-height: 19.6px;
    }
    .iconBurger {
        width: 30px;
        height: 30px;
        margin-right: 4px !important;
    }
    .custom-mobile-menu-d-bloke {
        margin-left: -19px;
        margin-right: 20px;
    }
}
@media (max-width: 1368px) {
    .custom-d-none {
        display: none;
    }
}
@media (min-width: 1368px) {
    .custom-d-bloke {
        display: block;
    }
    .custom-mobile-menu-d-bloke {
        display: none;
    }
}
@media (max-width: 1486px) {
    .header-icon-width {
        width: 100px !important;
    }
}
@media (max-width: 667px) {
    .iconClose {
        width: 22px !important;
        height: 22px !important;
    }
    .nav-bar-parent {
        width: 240px !important;
        height: 800px !important;
    }
    .language-select {
        display: none;
    }
    .iconLogo {
        width: 65px;
        height: 70px;
    }
    .header {
        height: 102px !important;
    }
    .iconBurger {
        margin-right: 10px;
        width: 26px !important;
    }
}
@media (max-width: 446px) {
    .header-icon-width {
        right: 65px;
    }
    .validate-button-style {
        left: 65px;
    }
    .ghost-btn {
        padding: 12px 10px;
        width: 172px;
        font-size: 14px;
    }
    .validate-button-style {
        width: 50px !important;
    }
}
.iconBurger {
    margin-right: 30px;
    width: 42px;
    height: 42px;
}
.header-icon-width {
    width: 200px;
}
.validate-button-style {
    width: 400px;
    margin-left: auto;
}
.icon-Burger ul {
    margin-right: 40px;
    height: 175px;
}
.nav-bar-parent ul li {
    margin-left: auto;
    display: table;
    margin-top: 15px;
}
.nav-bar-parent ul li .header-menu-link {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #282858;
}
.select-box {
    border: 1px black solid;
    background-color: white;
    border-radius: 10px;
    width: 80px;
    height: 52px;
}
.select-box ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
}
.select-box li:hover {
    color: white !important;
    background: black;
}
.container {
    max-width: 100% !important;
    padding: 0 84px;
}
.nav-bar-parent {
    z-index: 1000;
    position: fixed;
    background: #f1f5f9;
    top: 0;
    left: 0;
    height: 1024px;
    width: 340px;
    border: #f1f5f9 solid 1px;
    border-radius: 0 8px 8px 0;
}
.nav-bar-parent ul li /deep/ .header-menu-link--active {
    background: unset;
    padding: 0;
}

.iconClose {
    margin-top: 37px;
    margin-left: 40px;
    width: 62px;
    height: 62px;
}
.header {
    height: 154px;
}
</style>
