<template>
    <section class="section mt-132 section--bottom--space">
        <div class="container container-1170">
            <div class="text-center">
                <h3 class="section-title mb-32 main-green-color">{{ $t('enroll.thankYou') }}</h3>
                <div>{{ $t('enroll.complited') }}</div>
                <div class="section-pargraph mb-32 position-relative">
                  <Loader v-if="loading" /> {{ $t('enroll.yourUuid') }} {{ !loading && !errorMessage ? $t('enroll.is') : errorMessage ? $t('enroll.cantFound') : '' }} {{ order.fulfillmentId || "" }}
                </div>
                <div class="mt-16">
                    <router-link class="btn-round btn-green mt-12" to="/"> {{ $t('enroll.backToHome') }} </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import orderFulfillmentService from '@/common/services/orderFullfilment';
import { errorInterceptor } from '@/utilities/errorInterseptor'
import Loader from '@/components/helpers/Loader.vue'
import { useToast } from 'vue-toastification'
import { cookieRemovePersonalData, destroyApplicationId } from '@/common/eligibility.service'

export default {
    setup() {
      const toast = useToast()
      return { toast }
    },
    components: { Loader },
    data() {
        return {
            successIcon: require('../../../assets/images/general/Sucess.svg'),
            loading: false,
            order: {},
            errorMessage: ''
        }
    },
  created() {
      this.loadOrderDetails()
      cookieRemovePersonalData()
      destroyApplicationId()
  },
  methods: {
      async loadOrderDetails() {
        const orderId = this.$route.query?.orderId
        if (!orderId) {
          this.toast.error('Order not found')
          return
        }
        try {
            this.loading = true
            const { data } = await orderFulfillmentService.getOrder(orderId)
            this.order = data
            if (data.message) {
              this.errorMessage = data.message
              this.toast.error(data.message)
            }
        } catch (error) {
          const e = errorInterceptor(error)
          e && this.toast.error(e)
        } finally {
          this.loading = false
        }
      }
  }
}
</script>

<style>
.section-pargraph {
    box-shadow: 0px 8px 16px -4px #000c201f;
    width: 322px;
    padding: 24px;
    border-radius: 16px;
    gap: 20px;
    margin: auto;
}
</style>
