<template>
    <section class="section section-profileTitle">
        <div class="container container-1170">
            <h2>Check <span class="main-green-color">Eligibility</span></h2>
        </div>
    </section>

    <section class="section section-profile pb-80">
        <div class="container container-1170">
            <div class="row">
                <div class="col-md-3">
                    <ProflieBreadcrumbs :stepNum="profileStep" />
                </div>
                <div class="col-md-8 col">

                    <div class="general-card">
                        <div class="mb-24">
                        <h3>Household information</h3>
                        </div>
                        <div>
                            <div class="mb-24">
                                <label>1. Do you live with another adult? <span class="label-required">*</span></label>
                                <div class="d-flex">
                                    <div class="mr-16">
                                        <input
                                            @change="clearError"
                                            class="inputRadio"
                                            type="radio"
                                            name="optionAdult"
                                            value="1"
                                            v-model="optionAdult"
                                        />
                                        Yes
                                    </div>
                                    <div>
                                        <input
                                            @change="clearError"
                                            class="inputRadio"
                                            type="radio"
                                            name="optionAdult"
                                            value="2"
                                            v-model="optionAdult"
                                        />
                                        No
                                    </div>
                                </div>
                            </div>
                            <div class="mb-24">
                                <label
                                    >2. Do they receive APC/Lifeline Services?
                                    <span class="label-required">*</span></label
                                >
                                <div class="d-flex">
                                    <div class="mr-16">
                                        <input
                                            @change="clearError"
                                            v-model="consentInd"
                                            class="inputRadio"
                                            type="radio"
                                            name="optionLifeline"
                                            value="1"
                                        />
                                        Yes
                                    </div>
                                    <div>
                                        <input
                                            @change="clearError"
                                            v-model="consentInd"
                                            class="inputRadio"
                                            type="radio"
                                            name="optionLifeline"
                                            value="2"
                                        />
                                        No
                                    </div>
                                </div>
                            </div>
                            <p>
                                Please enter your initials below, confirming you understand the one-per-household rule
                                and consent to transfer or enroll your Lifeline or ACP benefit with Peeringhub Inc.
                            </p>
                            <p>
                                I understand that the one-per-household limit is a Federal Communications Commission
                                (FCC) rule and I will lose my Lifeline/ACP benefit if I break this rule.
                            </p>
                            <div class="mb-24">
                                <input type="text" placeholder="MK" v-model="MK1" @input="clearError" class="w-95" />
                            </div>
                            <p>
                                I consent to apply for and/or transfer my ACP Benefits to Peeringhub Inc. and transition to
                                Lifeline-only services when the ACP benefits ends. I understand I am under no obligation
                                to purchase the unlimited service and will receive the free Lifeline-service, if I
                                qualify, after the ACP Benefits program ends.
                            </p>
                            <div>
                                <input type="text" placeholder="MK" v-model="MK2" @input="clearError" class="w-95" />
                            </div>
                            <div class="d-flex justify-content-between mt-32">
                                <router-link to="/ContactInfoPage?profileStep=2" class="btn-round btn-black-ghost"
                                    >Back</router-link
                                >
                                <button @click="finilizeStep" class="btn-round btn-green">Continue</button>
                            </div>
                            <div class="text-error text-right">{{ error }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ProflieBreadcrumbs from '@/components/helpers/ProfileBreadcrumbs.vue'
import checkValidation from '@/components/validation/checkValidation'
import checkCheckbox from '@/components/validation/checkCheckbBox'

export default {
    mounted() {
        if (this.$store.state.PersonalStore?.personalData?.firstName.length < 1) {
            this.$router.push('/ZipCodePage')
        }
    },
    components: {
        ProflieBreadcrumbs,
    },
    computed: {
        profileStep() {
            return this.$route.query.profileStep
        },
    },
    methods: {
        clearError() {
          this.error = ''
        },
        finilizeStep() {
            const validation = {
                consentInd: checkCheckbox(this.consentInd),
                optionAdult: checkCheckbox(this.optionAdult),
                MK1: checkCheckbox(this.MK1),
                MK2: checkCheckbox(this.MK2),
            }

            if (this.consentInd === null || this.optionAdult == null) {
                this.error = 'Selecting an option is mandatory'
                return
            }

            if (!this.MK1 || !this.MK2) {
                this.error = 'MK is mandatory'
                return
            }

            if (
                this.firstName[0].toLowerCase() == this.MK1[0].toLowerCase() &&
                this.lastName[0].toLowerCase() == this.MK1[1].toLowerCase() &&
                this.firstName[0].toLowerCase() == this.MK2[0].toLowerCase() &&
                this.lastName[0].toLowerCase() == this.MK2[1].toLowerCase()
            ) {
                checkValidation(validation) === true ? this.goNext() : (this.error = 'All fields are mandatory')
            }
            this.error = 'First character of first name and first character of last name is not correct'
        },
        goNext() {
            const consentInd = this.consentInd == 1 ? 'Y' : 'Y'
            this.consentInd = consentInd

            this.$store.dispatch('addToPersonal', ['MK1', this.MK1])
            this.$store.dispatch('addToPersonal', ['MK2', this.MK2])

            this.$store.dispatch('addToPersonal', ['optionAdult', this.optionAdult])
            this.$store.dispatch('addToPersonal', ['consentInd', consentInd])
            this.$router.push('/QualificationPage?profileStep=4')
        },
    },
    data() {
        return {
            error: '',
            consentInd:
                this.$store.state.PersonalStore?.personalData?.consentInd === 'Y'
                    ? 1
                    : this.$store.state.PersonalStore?.personalData?.consentInd === 'N'
                    ? 2
                    : null,
            optionAdult: this.$store.state.PersonalStore?.personalData?.optionAdult,
            firstName: this.$store.state.PersonalStore?.personalData?.firstName,
            lastName: this.$store.state.PersonalStore?.personalData?.lastName,
            MK1: this.$store.state.PersonalStore?.personalData?.MK1,
            MK2: this.$store.state.PersonalStore?.personalData?.MK2,
        }
    },
}
</script>
<style scoped>

    @media (min-width: 375px) and (max-width: 667px) {
        h3 {
            font-size: 24px;
            line-height: 33px;
        }
    }
        @media (min-width: 768px) {
        h3 {
            font-size: 40px;
            line-height: 46px;
        }
    }
    @media (min-width: 768px) and (max-width: 770px) {
        .general-card {
            min-width: 690px;
        }
    }
    @media (max-width: 1024px) {
        .general-card{
            left: 5px;
        }
    }
</style>
