import ApiService from './api.service'
import removeObjectEmptyValues from '@/utilities/removeObjectEmptyValues'
import { format } from 'date-fns'

const carrierUrlPathName = '/ACPEligibilityCheck'
const CARRIER_URL = window.location.origin + carrierUrlPathName

const timeZones = [
    { label: /Pacific(?=.*Samoa)/, id: '2' },
    { label: /US(?=.*Hawaii)/, id: '3' },
    { label: /US(?=.*Alaska)/, id: '4' },
    { label: /Pacific(?!.*Samoa)(?!.*Midway)(?!.*Port_Moresby)(?!.*Guam)/, id: '5' },
    { label: /US(?=.*Arizona)/, id: '6' },
    { label: /(US|Canada)\/Mountain/, id: '8' },
    { label: /(US|Canada)\/Central/, id: '10' },
    { label: /(US|Canada)\/Eastern/, id: '14' },
    { label: /US(?=.*East-Indiana)/, id: '15' },
    { label: /Pacific(?=.*Guam)/, id: '69' },
    { label: /Pacific(?=.*Port_Moresby)/, id: '69' },
    { label: /UTC/, id: '87'},
    { label: /US(?=.*Samoa)/, id: '112' },
    { label: /Pacific(?=.*Midway)/, id: '112' },
]
const getTimeZoneId = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    console.log('Time Zone: ', timeZone)
    return timeZones.find(t => t.label.test(timeZone))?.id
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

function formatDate(date) {
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getUTCMonth() + 1),
            padTo2Digits(date.getUTCDate()),
        ].join('-') +
        ' ' +
        [
            padTo2Digits(date.getUTCHours()),
            padTo2Digits(date.getUTCMinutes()),
            padTo2Digits(date.getUTCSeconds()),
        ].join(':')
    );
}

function getTimeFields() {
    return {
        consentTimeZoneId: getTimeZoneId() || '87',
        consentDateTime: getTimeZoneId() ? format(new Date(), 'yyyy-MM-dd hh:mm:ss') : formatDate(new Date())
    }
}


const eligibilityService = {
    postPersonalDataForCheckEligibility(data) {
        return ApiService.postAuth(`${process.env.VUE_APP_API_LINK}/public/acp/eligibility-check`, {
            ...data,
            carrierUrl: CARRIER_URL
        })
    },
    postEligibilityCheck(applicationId=null, eligibilityCheckId=null) {
        const payload = removeObjectEmptyValues({
            eligibilityCheckId,
            applicationId,
            carrierUrl: CARRIER_URL
        })
        return ApiService.postAuth(
            `${process.env.VUE_APP_API_LINK}/public/acp/eligibility-check/status`, payload)
    },
    acpTransferPlan(data) {
        return ApiService.postAuth(`${process.env.VUE_APP_API_LINK}/public/nlad/transfer`, {
            ...data,
            ...getTimeFields()
        })
    },
    postSubscriberAcpEnroll(data) {
        return ApiService.postAuth(`${process.env.VUE_APP_API_LINK}/public/nlad/enroll`, {
            ...data,
            ...getTimeFields()
        })
    },
    postSubscriberAcpVerify(data) {
        return ApiService.postAuth(`${process.env.VUE_APP_API_LINK}/public/nlad/verify`, data)
    },
}

export default eligibilityService
