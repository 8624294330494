<template>
  <section class="section section-summaryPlan pb-160 mt-132">
    <div class="position-relative container container-1170" id="cartTablet">
      <div class="text-center mb-40">
        <div class="section-title main-green-color">You are already enrolled in ACP!</div>
      </div>
      <div class="congrat-sub-block">
        <div class="section-card-plan-tablet mb-32">
          <div class="section-plan-card" style="min-height: 390px !important;max-height: 390px !important; width: 280px !important; padding-bottom: 0!important; margin: 0!important;">
            <div class="section-plan-card-header">
              <div class="section-plan-card-title">5G LTE DATA</div>
              <div class="section-plan-card-size" v-if="!tribalStatus">20GB <span>/mo</span></div>
              <div class="section-plan-card-size" v-else>UNLIMITED</div>
            </div>
            <div>
              <div class="section-plan-card-price">FREE</div>
              <div>
                <ul class="section-plan-card-list">
                  <li>Unlimited talk & text</li>
                  <li>Nationwide coverage</li>
                  <li>Uses 5G or 4G LTE,</li>
                  <li>whichever is strongest</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="summaryPlan-media">
            <div class="tablet-btn-details">
              <div>
                <div class="mb-8 section-title main-green-color" style="max-width: 216px; !important;">
                    Great!
                </div>
                <div class="mb-24 tablet-title" style="max-width: 216px; !important;">
                  <div>
                    You desire better.
                  </div>
                </div>
                <div class="flex">
                  <div class="mr-8">
                    <input type="checkbox" v-model="transfer" />
                  </div>
                  <div>I want to transfer my ACP Plan to Peeringhub Inc.</div>
                </div>
                <div v-if="!transfer" class="text-error">{{ error }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center">
          <button @click="transferCall" class="btn-round btn-green-light" :disabled="loading">
            <Loader v-if="loading" color="white" borderRadius="20" />
            {{ isResubmit ? 'Resubmit' : 'Transfer' }}
          </button>
        </div>
      </div>

    </div>
  </section>

</template>

<script>
import { getTribalStatus } from '@/common/eligibility.service'
import { appIdIsExist, ERROR_TIMEOUT, errorNlad, getUserData } from '@/eligibility-enroll/nlad'
import eligibilityService from '@/common/services/checkEligibility'
import { errorInterceptor } from '@/utilities/errorInterseptor'
import Loader from '@/components/helpers/Loader.vue'
import utility from '@/common/services/utility'
import { useToast } from 'vue-toastification'

export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  components: {
    Loader
  },
  methods: {
    openModal() {
      this.showModalTablet = true
    },
    async transferCall() {
      this.error = ""
      if (!this.transfer) {
        this.error = "required"
        return
      }
      const shippingFormData = getUserData()
      if (!shippingFormData) {
        this.transferSubmit()
        this.toast.error('Please fill in all the required fields')
        return
      }
      this.loading = true
      try {
        const resellerCode = await utility.isValidResellerCode()
        if (resellerCode) {
          shippingFormData['resellerCode'] = resellerCode
        }
        const response = await eligibilityService.acpTransferPlan(shippingFormData)
        const e = errorNlad(response, shippingFormData)
        this.isResubmit = (e === ERROR_TIMEOUT)
        e && this.toast.error(e)
      } catch (error) {
        const e = errorInterceptor(error)
        this.toast.error(e)
      } finally {
        this.loading = false
      }
    },
    transferSubmit() {
      this.$router.push('/ConfirmAddress?transfer=yes')
    },
  },
  created() {
    appIdIsExist()
  },
  data() {
    return {
      tribalStatus: getTribalStatus() === 'Y',
      transfer: false,
      error: "",
      shippingForm: null,
      isResubmit: false,
      loading: false
    }
  },
}
</script>

<style scoped>
.tablet-table td {
  border-bottom: 1px solid #fff;
}

.tablet-title {
  color: #272D37;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  text-transform: capitalize;
}

.section-card-plan-tablet {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
}
.congrat-sub-block {
  display: flex;
  max-width: 1170px;
  padding: 24px 32px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 24px;
  background: #FFF;
}
.tablet-btn-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.summaryPlan-media {
  height: 390px;
  max-width: 610px !important;
  display: flex;
  padding: 24px 32px;
  align-items: flex-start;
  gap: 32px;
  flex-wrap: no-wrap;
  border-radius: 16px;
  background: #FFF;
}
@media screen and (max-width: 997px) {
  .congrat-sub-block {
    margin: 0 10px 0 10px;
  }
  .tablet-btn-details {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .summaryPlan-media {
    height: max-content;
  }
  .section-card-plan-tablet {
    align-items: flex-start;
    gap: 24px;
  }
  .tablet-title {
    margin-top: 48px;
  }
}
@media (min-width: 769px) and (max-width: 897px) {
  .summaryPlan-media {
    flex-wrap: wrap;
  }
}
@media (min-width: 618px) and (max-width: 769px) {
  .summaryPlan-media {
    flex-wrap: no-wrap;
  }
}
@media screen and (max-width: 618px) {
  .summaryPlan-media {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 440px) {
  .summaryPlan-media {
    border: 0!important;
    box-shadow: none!important;
  }
}
@media screen and (max-width: 769px) {
  .tablet-btn-details {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .summaryPlan-media {
    height: max-content;
  }
  .section-card-plan-tablet {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .tablet-title {
    margin-top: 48px;
  }
}

.tablet-table tr {
  text-align: left;
}

.tablet-table td {
  padding: 20px;
}

.section-tablet-table td {
  border-bottom: 1px solid #475569;
  padding-top: 16px;
  padding-bottom: 16px;
}
</style>
