export default [
    {
        id: 6,
        statename: 'Alaska',
        shortcode: 'AK',
        zipMin: 99501,
        zipMax: 99950,
    },
    {
        id: 5,
        statename: 'Alabama',
        shortcode: 'AL',
        zipMin: 35004,
        zipMax: 36925,
    },
    {
        id: 69,
        statename: 'Arkansas',
        shortcode: 'AR',
        zipMin: 71601,
        zipMax: 72959,
    },
    {
        id: 8,
        statename: 'Arkansas (Texarkana)',
        shortcode: 'AR',
        zipMin: 75502,
        zipMax: 75502,
    },
    {
        id: 7,
        statename: 'Arizona',
        shortcode: 'AZ',
        zipMin: 85001,
        zipMax: 86556,
    },
    {
        id: 9,
        statename: 'California',
        shortcode: 'CA',
        zipMin: 90001,
        zipMax: 96162,
    },
    {
        id: 10,
        statename: 'Colorado',
        shortcode: 'CO',
        zipMin: 80001,
        zipMax: 81658,
    },
    {
        id: 11,
        statename: 'Connecticut',
        shortcode: 'CT',
        zipMin: 6001,
        zipMax: 6389,
    },
    {
        id: 11,
        statename: 'Connecticut',
        shortcode: 'CT',
        zipMin: 6401,
        zipMax: 6928,
    },
    {
        id: 13,
        statename: 'Dist of Columbia',
        shortcode: 'DC',
        zipMin: 20001,
        zipMax: 20039,
    },
    {
        id: 74,
        statename: 'Dist of Columbia',
        shortcode: 'DC',
        zipMin: 20042,
        zipMax: 20599,
    },
    {
        id: 63,
        statename: 'Dist of Columbia',
        shortcode: 'DC',
        zipMin: 20799,
        zipMax: 20799,
    },
    {
        id: 12,
        statename: 'Delaware',
        shortcode: 'DE',
        zipMin: 19701,
        zipMax: 19980,
    },
    {
        id: 14,
        statename: 'Florida',
        shortcode: 'FL',
        zipMin: 32004,
        zipMax: 34997,
    },
    {
        id: 15,
        statename: 'Georgia',
        shortcode: 'GA',
        zipMin: 30001,
        zipMax: 31999,
    },
    {
        id: 65,
        statename: 'Georgia (Atlanta)',
        shortcode: 'GA',
        zipMin: 39901,
        zipMax: 39901,
    },
    {
        id: 65,
        statename: 'Hawaii',
        shortcode: 'HI',
        zipMin: 96701,
        zipMax: 96898,
    },
    {
        id: 65,
        statename: 'Iowa',
        shortcode: 'IA',
        zipMin: 96701,
        zipMax: 52809,
    },
    {
        id: 65,
        statename: 'Iowa (OMAHA)',
        shortcode: 'IA',
        zipMin: 68119,
        zipMax: 68120,
    },
    {
        id: 17,
        statename: 'Idaho',
        shortcode: 'ID',
        zipMin: 83201,
        zipMax: 83876,
    },
    {
        id: 18,
        statename: 'Illinois',
        shortcode: 'IL',
        zipMin: 60001,
        zipMax: 62999,
    },
    {
        id: 19,
        statename: 'Indiana',
        shortcode: 'IN',
        zipMin: 46001,
        zipMax: 47997,
    },
    {
        id: 21,
        statename: 'Kansas',
        shortcode: 'KS',
        zipMin: 66002,
        zipMax: 67954,
    },
    {
        id: 22,
        statename: 'Kentucky',
        shortcode: 'KY',
        zipMin: 40003,
        zipMax: 42788,
    },
    {
        id: 68,
        statename: 'Louisiana',
        shortcode: 'LA',
        zipMin: 70001,
        zipMax: 71232,
    },
    {
        id: 23,
        statename: 'Louisiana',
        shortcode: 'LA',
        zipMin: 71234,
        zipMax: 71497,
    },
    {
        id: 26,
        statename: 'Massachusetts',
        shortcode: 'MA',
        zipMin: 1001,
        zipMax: 2791,
    },
    {
        id: 58,
        statename: 'Massachusetts (Andover)',
        shortcode: 'MA',
        zipMin: 5501,
        zipMax: 5544,
    },
    {
        id: 77,
        statename: 'Maryland',
        shortcode: 'MD',
        zipMin: 20331,
        zipMax: 20331,
    },
    {
        id: 78,
        statename: 'Maryland',
        shortcode: 'MD',
        zipMin: 20335,
        zipMax: 20797,
    },
    {
        id: 25,
        statename: 'Maryland',
        shortcode: 'MD',
        zipMin: 20812,
        zipMax: 21930,
    },
    {
        id: 24,
        statename: 'Maine',
        shortcode: 'ME',
        zipMin: 3901,
        zipMax: 4992,
    },
    {
        id: 27,
        statename: 'Michigan',
        shortcode: 'MI',
        zipMin: 48001,
        zipMax: 49971,
    },
    {
        id: 28,
        statename: 'Minnesota',
        shortcode: 'MN',
        zipMin: 55001,
        zipMax: 56763,
    },
    {
        id: 57,
        statename: 'Minnekc96 DataMOsota',
        shortcode: 'MO',
        zipMin: 63001,
        zipMax: 65899,
    },
    {
        id: 29,
        statename: 'Mississippi',
        shortcode: 'MS',
        zipMin: 38601,
        zipMax: 39776,
    },
    {
        id: 59,
        statename: 'Mississippi(Warren)',
        shortcode: 'MS',
        zipMin: 71233,
        zipMax: 71233,
    },
    {
        id: 31,
        statename: 'Montana',
        shortcode: 'MT',
        zipMin: 59001,
        zipMax: 59937,
    },
    {
        id: 38,
        statename: 'North Carolina',
        shortcode: 'NC',
        zipMin: 27006,
        zipMax: 28909,
    },
    {
        id: 39,
        statename: 'North Dakota',
        shortcode: 'ND',
        zipMin: 58001,
        zipMax: 58856,
    },
    {
        id: 67,
        statename: 'Nebraska',
        shortcode: 'NE',
        zipMin: 68001,
        zipMax: 68118,
    },
    {
        id: 32,
        statename: 'Nebraska',
        shortcode: 'NE',
        zipMin: 68122,
        zipMax: 69367,
    },
    {
        id: 34,
        statename: 'New Hampshire',
        shortcode: 'NH',
        zipMin: 3031,
        zipMax: 3897,
    },
    {
        id: 35,
        statename: 'New Jersey',
        shortcode: 'NJ',
        zipMin: 7001,
        zipMax: 8989,
    },
    {
        id: 36,
        statename: 'New Mexico',
        shortcode: 'NM',
        zipMin: 87001,
        zipMax: 88441,
    },
    {
        id: 33,
        statename: 'Nevada',
        shortcode: 'NV',
        zipMin: 88901,
        zipMax: 89883,
    },
    {
        id: 60,
        statename: 'New York (Fishers Is)',
        shortcode: 'NY',
        zipMin: 6390,
        zipMax: 6390,
    },
    {
        id: 37,
        statename: 'New York',
        shortcode: 'NY',
        zipMin: 10001,
        zipMax: 14975,
    },
    {
        id: 40,
        statename: 'Ohio',
        shortcode: 'OH',
        zipMin: 43001,
        zipMax: 45999,
    },
    {
        id: 70,
        statename: 'Oklahoma',
        shortcode: 'OK',
        zipMin: 73001,
        zipMax: 73199,
    },
    {
        id: 41,
        statename: 'Oklahoma',
        shortcode: 'OK',
        zipMin: 73401,
        zipMax: 74966,
    },
    {
        id: 42,
        statename: 'Oregon',
        shortcode: 'OR',
        zipMin: 97001,
        zipMax: 97920,
    },
    {
        id: 43,
        statename: 'Pennsylvania',
        shortcode: 'PA',
        zipMin: 15001,
        zipMax: 19640,
    },
    {
        id: 44,
        statename: 'Puerto Rico',
        shortcode: 'PR',
        zipMin: 0,
        zipMax: 0,
    },
    {
        id: 45,
        statename: 'Rhode Island',
        shortcode: 'RI',
        zipMin: 2801,
        zipMax: 2940,
    },
    {
        id: 46,
        statename: 'South Carolina',
        shortcode: 'SC',
        zipMin: 29001,
        zipMax: 29948,
    },
    {
        id: 47,
        statename: 'South Dakota',
        shortcode: 'SD',
        zipMin: 57001,
        zipMax: 57799,
    },
    {
        id: 48,
        statename: 'Tennessee',
        shortcode: 'TN',
        zipMin: 37010,
        zipMax: 38589,
    },
    {
        id: 49,
        statename: 'Texas (Austin)',
        shortcode: 'TX',
        zipMin: 73301,
        zipMax: 73301,
    },
    {
        id: 71,
        statename: 'Texas',
        shortcode: 'TX',
        zipMin: 75001,
        zipMax: 75501,
    },
    {
        id: 72,
        statename: 'Texas',
        shortcode: 'TX',
        zipMin: 75503,
        zipMax: 79999,
    },
    {
        id: 61,
        statename: 'Texas (El Paso)',
        shortcode: 'TX',
        zipMin: 88510,
        zipMax: 88589,
    },
    {
        id: 50,
        statename: 'Utah',
        shortcode: 'UT',
        zipMin: 84001,
        zipMax: 84784,
    },
    {
        id: 64,
        statename: 'Virginia',
        shortcode: 'VA',
        zipMin: 20040,
        zipMax: 20041,
    },
    {
        id: 75,
        statename: 'Virginia',
        shortcode: 'VA',
        zipMin: 20040,
        zipMax: 20167,
    },
    {
        id: 75,
        statename: 'Virginia',
        shortcode: 'VA',
        zipMin: 20040,
        zipMax: 20167,
    },
    {
        id: 76,
        statename: 'Virginia',
        shortcode: 'VA',
        zipMin: 20042,
        zipMax: 20042,
    },
    {
        id: 52,
        statename: 'Virginia',
        shortcode: 'VA',
        zipMin: 22001,
        zipMax: 24658,
    },
    {
        id: 51,
        statename: 'Vermont',
        shortcode: 'VT',
        zipMin: 5001,
        zipMax: 5495,
    },
    {
        id: 62,
        statename: 'Vermont',
        shortcode: 'VT',
        zipMin: 5601,
        zipMax: 5907,
    },
    {
        id: 53,
        statename: 'Washington',
        shortcode: 'WA',
        zipMin: 98001,
        zipMax: 99403,
    },
    {
        id: 55,
        statename: 'Wisconsin',
        shortcode: 'WI',
        zipMin: 53001,
        zipMax: 54990,
    },
    {
        id: 54,
        statename: 'West Virginia',
        shortcode: 'WV',
        zipMin: 24701,
        zipMax: 26886,
    },
    {
        id: 56,
        statename: 'Wyoming',
        shortcode: 'WY',
        zipMin: 82001,
        zipMax: 83128,
    },
    {
        id: 56,
        statename: 'Wyoming',
        shortcode: 'WY',
        zipMin: 82001,
        zipMax: 83128,
    },
]
