<template>
    <section class="mt-42 mb-160">
        <div class="container">
            <h1 class="text-center header-text title">Resources</h1>

            <div>
                <div class="videos-card">
                    <div class="videos-card-title d-flex justify-content-between">
                        <h2>Useful videos</h2>
                      <div>
                        <button type="button" class="btn btn-link" @click="moveToPrevSlide"><img src="../../../assets/images/icons/arrow-circle-left.svg" alt="" /></button>
                        <button type="button" class="btn btn-link" @click="moveToNextSlide"><img src="../../../assets/images/icons/arrow-circle-right.svg" alt="" /></button>
                      </div>
                    </div>
                    <Flicking ref="flicking" :options="{ gap: '1', circular: true, align: 'prev' }">
                        <div key="slide1" class="panel">
                            <div class="video-card-item">
                                <div class="d-flex justify-content-around">
                                    <div class="video-title">Placeholder text for video title</div>
                                    <div class="export-icon"></div>
                                </div>
                                <div class="text-center">
                                    <img class="video-image" :src="videoImage" alt="" />
                                </div>
                            </div>
                        </div>
                        <div key="slide2" class="panel">
                            <div class="video-card-item">
                                <div class="d-flex justify-content-around">
                                    <div class="video-title">Placeholder text for video title</div>
                                    <div class="export-icon"></div>
                                </div>
                                <div class="text-center">
                                    <img class="video-image" :src="videoImage" alt="" />
                                </div>
                            </div>
                        </div>
                        <div key="slide3" class="panel">
                            <div class="video-card-item">
                                <div class="d-flex justify-content-around">
                                    <div class="video-title">Placeholder text for video title</div>
                                    <div class="export-icon"></div>
                                </div>
                                <div class="text-center">
                                    <img class="video-image" :src="videoImage" alt="" />
                                </div>
                            </div>
                        </div>
                      <div key="slide4" class="panel">
                        <div class="video-card-item">
                          <div class="d-flex justify-content-around">
                            <div class="video-title">Placeholder text for video title</div>
                            <div class="export-icon"></div>
                          </div>
                          <div class="text-center">
                            <img class="video-image" :src="videoImage" alt="" />
                          </div>
                        </div>
                      </div>
                      <div key="slide5" class="panel">
                        <div class="video-card-item">
                          <div class="d-flex justify-content-around">
                            <div class="video-title">Placeholder text for video title</div>
                            <div class="export-icon"></div>
                          </div>
                          <div class="text-center">
                            <img class="video-image" :src="videoImage" alt="" />
                          </div>
                        </div>
                      </div>
                    </Flicking>
                </div>
            </div>

            <div class="mt-160">
                <h2 class="mb-32 resources-title">Useful documents</h2>
                <div class="resourcesSubtitle">
                    Organizations that support ACP consumers may use these resources to educate consumers about the
                    program and how to apply
                </div>
                <ul class="download_list">
                    <li>ACP Outreach Toolkit</li>
                    <li>ACP Outreach Toolkit</li>
                    <li>ACP Brochure (digital)</li>
                    <li>ACP Brochure (digital)</li>
                    <li>ACP Brochure (print)</li>
                    <li>ACP Brochure (print)</li>
                    <li>ACP Brochure (print)</li>
                    <li>ACP Flyer 1</li>
                    <li>ACP Flyer 1</li>
                    <li>Consumer Toolkit</li>
                    <li>Consumer Toolkit</li>
                </ul>
                <div class="resourcesSubtitle mb-17">
                    You can find additional toolkit materials and partner resources, including materials in many
                    languages:
                </div>
                <div class="row">
                    <div class="col-6">
                        <ul class="download_list_horizontal" style="padding-left: 0 !important;">
                            <li>Language name</li>
                            <li>Language name</li>
                            <li>Language name</li>
                            <li>Language name</li>
                            <li>Language name</li>
                            <li>Language name</li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <ul class="download_list_horizontal" style="padding-left: 0 !important;">
                            <li>Language name</li>
                            <li>Language name</li>
                            <li>Language name</li>
                            <li>Language name</li>
                            <li>Language name</li>
                            <li>Language name</li>
                        </ul>
                    </div>
                </div>


            </div>
        </div>
    </section>
</template>
<script>
import Flicking from '@egjs/vue3-flicking'

export default {
  name: 'ResourcesPage',
    components: {
        Flicking,
    },
    methods: {
      moveToPrevSlide() {
        const currentIndex = this.$refs.flicking.getStatus();

        if (currentIndex.index > 0) {
          this.$refs.flicking.prev();
        }
      },
      moveToNextSlide() {
        const currentIndex = this.$refs.flicking.getStatus();
        if (currentIndex.index < currentIndex.panels.length -1) {
          this.$refs.flicking.next();
        }
      },
    },
    data() {
        return {
            videoImage: require('../../../assets/images/icons/video.svg'),
        }
    },
}
</script>
<style>
.download_list {
    margin-top: 17px;
    margin-bottom: 33px;
}

.download_list li {
    position: relative;
    margin-bottom: 20px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22.4px;
    text-decoration-line: underline;
    font-feature-settings: 'liga' off;
    color: #003875;
    min-width: 222px;
    margin-right: 15px;
}

.download_list li:after {
    position: absolute;
    content: '';
    background: url('../../../assets/images/icons/download.svg');
    width: 24px;
    height: 24px;
}

.download_list_horizontal li {
    margin-top: 14px;
    position: relative;
    min-width: 178px;
    margin-left: 19px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-decoration-line: underline;
    font-feature-settings: 'liga' off;
    color: #003875;
    display: inline-block;
}

.download_list_horizontal li:before {
    position: absolute;
    content: '\25CF';
    width: 6px;
    height: 6px;
    left: -18px;
}

.download_list_horizontal li:after {
    position: absolute;
    content: '';
    background: url('../../../assets/images/icons/download.svg');
    width: 24px;
    height: 24px;
}

.resourcesSubtitle {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    font-feature-settings: 'liga' off;
    color: #0f172a;
}

.videos-card {
    background: #ffffff;
    box-shadow: 0px 8px 16px -4px rgba(0, 12, 32, 0.12);
    border-radius: 32px;
    padding: 32px 42px;
    gap: 32px;
}

.video-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    color: #0f172a;
    padding-bottom: 22px;
    width: 243px;
    height: 58px;
}

.video-image {
    margin-top: 22px;;
    width: 315px;
    height: 251px;
}

.video-card-item {
    background: #ffffff;
    box-shadow: 0px 8px 16px -4px rgba(0, 12, 32, 0.12);
    border-radius: 32px;
    padding: 32px 24px;
    margin-right: 30px;
}

.export-icon {
    width: 24px;
    height: 24px;
    background: url('../../../assets/images/icons/export.svg');
}

@media (max-width: 768px) {
    .video-card-item {
        padding: 32px 24px;
        width: 363px;
        height: 395px;
    }
    .flicking-camera {
        transform: none !important;
    }
}

@media (max-width: 376px) {
    .videos-card-title > h2 {
        font-size: 30px;
        font-weight: 500;
        line-height: 35.22px;
    }
    .videos-card {
        padding: 32px 16px;
    }
    .resources-title {
        font-size: 30px;
        line-height: 35.22px;
        font-weight: 500;
    }
    .resourcesSubtitle {
        font-size: 20px;
        line-height: 23.48px;
    }
    .flicking-camera {
        transform: none !important;
    }
    .video-card-item {
        padding: 20px 16px;
        width: 300px;
        height: 365px;
    }
    .video-title {
        font-size: 20px;
        line-height: 23.48px;
        width: 162px;
    }
    .video-image {
        width: 268px;
        height: 251px;
    }
}
</style>
<style scoped>
    .header-text{
        width: 272px;
        margin: 0 auto;
    }
    @media (max-width: 375px) {
        .header-text{
            font-size: 35px;
            line-height: 41px;

        }
    }
</style>
