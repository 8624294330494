<template>
  <section class="section section-profileTitle">
    <div class="container container-1170">
      <h2>Check <span class="main-green-color">Eligibility</span></h2>
    </div>
  </section>

  <section class="section section-profile pb-80">
    <div class="container container-1170">
      <div class="row">
        <div class="col-md-3">
          <ProflieBreadcrumbs :stepNum="profileStep"/>
        </div>
        <div class="col-md-8 col">
          <h3>Qualification</h3>
          <div class="general-card">
            <div class="mb-32">
              <label
              >What government Program do you Participate in?
                <span class="label-required">*</span></label
              >
              <div
                  v-for="(program, index) in eligibilityPrograms"
                  :key="index"
                  class="option-border"
                  @click="this.setOptionItemId(program)"
              >
                <input
                    type="radio"
                    name="optionProfile"
                    :value="program.code"
                    :checked="eligibilityProgramCode == program.code"
                />
                <span>{{ program.name }}</span>
                <template v-if="program.code === eligibilityProgramCode">
                  <div
                      v-if="eligibilityProgramCode === 'E4'"
                      class="ml-26"
                      @click.stop
                  >
                    <div>
                      <input type="radio" id="federal"
                             :checked="publicHousingCode === '1'"
                             @change="setFederalValue('1')"
                             name="optionProfileFederal" value="1"/><span
                    >Housing choice voucher program</span
                    >
                    </div>
                    <div>
                      <input type="radio" id="federal"
                             :checked="publicHousingCode === '2'"
                             @change="setFederalValue('2')"
                             name="optionProfileFederal" value="2"/><span
                    >Project based rental assistance</span
                    >
                    </div>
                    <div>
                      <input type="radio" id="federal"
                             :checked="publicHousingCode === '3'"
                             @change="setFederalValue('3')"
                             name="optionProfileFederal" value="3"/><span
                    >Public housing</span
                    >
                    </div>
                    <div>
                      <input type="radio" id="federal"
                             :checked="publicHousingCode === '4'"
                             @change="setFederalValue('4')"
                             name="optionProfileFederal" value="4"/><span
                    >Affordable Housing Programs for American Indians, Alaska, Natives or Native
                                                Hawaiians</span
                    >
                    </div>
                  </div>
                  <div
                      v-else-if="eligibilityProgramCode === 'E13'"
                      class="flex optionProfile-card--no-border"
                      @click.stop
                  >
                    <div>
                      <div class="mb-16 font-family">
                        How many adults (18 and over) currently reside
                      </div>
                      <div class="mb-16">
                        <select
                            @change="onChangeAdultNumber"
                            v-model="selectedAdultNumber"
                            class="form-select"
                        >
                          <option value="0" disabled selected>Select for adult</option>
                          <option v-for="index in maxAdults" :key="index + 'adults'" :value="index">{{ numbers[index - 1] }}</option>
                        </select>
                      </div>
                      <div class="mb-16 font-family">
                        How many children (under 18) currently reside
                      </div>
                      <div class="mb-16">
                        <select
                            v-model="selectedChildNumber"
                            class="form-select"
                        >
                          <option value="0">Select for children</option>
                          <option v-for="index in maxChildren" :key="index + 'adults'" :value="index">{{ numbers[index - 1] }}</option>
                        </select>
                      </div>
                      <div class="mb-16">
                        <label class="font-family">Select income period</label>
                        <select class="form-select" aria-label="Select income period" v-model="incomePeriod">
                          <option value="1" selected>Monthly</option>
                          <option value="2">Yearly</option>
                        </select>
                      </div>
                      <div>
                        <label class="font-family"
                        >Total annual gross income (round to whole dollars, check the income
                          Calculator in the Eligibility Guidelines)
                          <span class="label-required">*</span>
                        </label>
                        <input placeholder="Enter your income" type="text" v-model="income" class="w-95"/>
                      </div>
                    </div>
                    <div class="optionProfile-card">
                      <div class="d-flex mb-16">
                        <div class="font-family">People living in your household (including yourself)</div>
                        <div class="fw-600">{{ count }}</div>
                      </div>
                      <div class="d-flex justify-content-between mb-16">
                        <div class="font-family">Federal Poverty guideline</div>
                        <div class="fw-600 main-green-color">${{ amount }}</div>
                      </div>
                      <div>
                        <p class="small-txt">if more than 20 add ${{ extraCost }} for each extra person</p>
                      </div>
                      <div v-if="lowIncome">
                        <p class="small-txt">You are likely not qualified based on Low Income</p>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="eligibilityProgramCode === 'E50' || eligibilityProgramCode === 'E51'" class="ml-26">
                    <div>
                      <label
                      >School Name
                        <span class="label-required">*</span>
                      </label>
                      <input v-model="schoolName" placeholder="Enter your school" type="text" class="w-95"/>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- HIDE FOR NOW -->
            <!-- <div class="success-alert">
                Click Continue and you will be redirected to the National Verilier where you will need to
                submit proofs for verification. You will then be transfered back to automatically to
                compleyr your application.
            </div> -->
            <!-- HIDE FOR NOW -->
            <div class="d-flex justify-content-between mt-32">
              <router-link to="/HouseholdInformationPage?profileStep=3" class="btn-round btn-black-ghost"
              >Back
              </router-link
              >
              <button @click="finilizeStep" class="btn-round btn-green">Continue</button>
            </div>
            <div class="text-error text-right">{{ error }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <InternalServerError/>
</template>

<script>
import ProflieBreadcrumbs from '@/components/helpers/ProfileBreadcrumbs.vue'
import InternalServerError from '@/components/helpers/Modals/InternalServerError.vue'
import { mapGetters } from 'vuex'

export default {
  mounted() {
    if (this.$store.state.PersonalStore?.personalData?.firstName.length < 1) {
      this.$router.push('/ZipCodePage')
    }
  },
  components: {
    InternalServerError,
    ProflieBreadcrumbs
  },
  computed: {
    ...mapGetters(['eligibilityProgramCode']),
    profileStep() {
      return this.$route.query.profileStep
    },
    publicHousingCode() {
      return this.$store.state.PersonalStore?.personalData?.publicHousingCode
    },
    maxChildren () {
      return this.maxChildrenCount
    },
    count () {
      return +this.selectedAdultNumber + +this.selectedChildNumber
    },
    amount () {
      switch (this.count) {
        case 1: return 29160
        case 2: return 39440
        case 3: return 49720
        case 4: return 60000
        case 5: return 70280
        case 6: return 80560
        case 7: return 90840
        case 8: return 101120
        default: return 101120 + ((this.count - 8) * this.extraCost)
      }
    },
    lowIncome () {
      return this.income ? this.amount > ((this.incomePeriod === "1" ? 12 : 1) * this.income) : false
    }
  },
  methods: {
    finilizeStep() {
      if (!this.eligibilityProgramCode) {
        this.error = 'Selecting an option is mandatory'
        return
      }
      if ((this.eligibilityProgramCode === 'E50' || this.eligibilityProgramCode === 'E51') && !this.schoolName) {
        this.error = 'School name is mandatory'
        return
      }
      this.goNext()
    },
    goNext() {
      this.$router.push('/TribalStatusPage?profileStep=5')
    },
    setOptionItemId(program) {
      this.$store.dispatch('addToPersonal', ['eligibilityProgramCode', program.code])
      if (program.code !== 'E4') {
        this.setFederalValue()
      }
    },
    setFederalValue(value = '') {
      this.$store.dispatch('addToPersonal', ['publicHousingCode', value])
    },
    onChangeAdultNumber() {
      if (this.selectedChildNumber > this.maxChildren) this.selectedChildNumber = this.maxChildren
    }
  },
  data() {
    return {
      selectedAdultNumber: 1,
      selectedChildNumber: 0,
      error: '',
      schoolName: '',
      selectedValue: '',
      eligibilityPrograms: [
        { code: 'E1', name: 'Medicaid' },
        { code: 'E2', name: 'Supplemental Nutrition Assistance Program (SNAP) ' },
        { code: 'E3', name: 'Supplemental Security' },
        { code: 'E4', name: 'Federal Public Housing Assistance' },
        { code: 'E8', name: 'Bureau of Indian Affairs General Assistance' },
        { code: 'E9', name: 'Tribal Temporary Assistance for Needy Families (Tribal TANF)' },
        { code: 'E10', name: 'Food Distribution Program on Indian Reservations (FDPIR) ' },
        { code: 'E11', name: 'Head Start' },
        { code: 'E13', name: 'Eligibility Based on Income' },
        { code: 'E15', name: 'Veterans Pension or Survivors Pension' },
        { code: 'E50', name: 'School Lunch/Breakfast Program' },
        { code: 'E51', name: 'Federal Pell Grant' },
        // { code: 'E53', name: 'Existing low income program' },
        { code: 'E54', name: 'Special Supplemental Nutrition Program for Women, Infants, and Children (WIC)' }
      ],
      numbers: [
        'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'
      ],
      maxAdults: 10,
      maxChildrenCount: 10,
      extraCost: 5910,
      incomePeriod: '1',
      income: ''
    }
  }
}
</script>

<style scoped>
.optionProfile-card {
  padding: 12px;
  gap: 16px;
  border: 1px solid #e8eaf0;
  border-radius: 16px;
}

.optionProfile-card--no-border {
  padding: 12px;
  gap: 16px;
}

.optionProfile-card .d-flex {
  border-bottom: 1px solid #d4d6db;
  padding-bottom: 16px;
}

.option-border span {
  font-family: Roboto;
}

.font-family {
  font-family: Roboto;
}

@media (min-width: 1200px) {
  .general-card {
    min-width: 840px;
  }
}

@media (min-width: 768px) and (max-width: 770px) {
  .general-card {
    min-width: 690px;
  }
}

@media (min-width: 768px) {
  h3 {
    font-size: 40px;
    line-height: 46px;
  }
}

@media (min-width: 375px) and (max-width: 667px) {
  h3 {
    font-size: 24px;
    line-height: 33px;
  }
}

@media (max-width: 469px) {
  .option-border--unemployment .optionProfile-card--no-border {
    display: block !important;
  }
}
</style>
