<template>
    <section class="mt-42 mb-80">
        <div class="container">
            <div class="text-center">
                <h2 class="w-752">
                    Enter Verification <span class="main-blue-color">code</span> to complete enrollment process
                </h2>
            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="register-card">
                <div class="pin-code">
                    <div>
                        <div ref="ref" v-bind="api.rootProps">
                            <input ref="ref1" v-bind="api.getInputProps({ index: 0 })" />
                            <input ref="ref2" v-bind="api.getInputProps({ index: 1 })" />
                            <input ref="ref3" v-bind="api.getInputProps({ index: 2 })" />
                            <input ref="ref4" v-bind="api.getInputProps({ index: 3 })" />
                            <input ref="ref5" v-bind="api.getInputProps({ index: 4 })" />
                            <input ref="ref6" v-bind="api.getInputProps({ index: 5 })" />
                        </div>
                    </div>
                </div>
                <div class="mt-60 text-center">
                    <router-link to="/EnrollmentResultPage" class="blue-btn w-100 mb-30">Continue</router-link>
                    <router-link to="" class="mt-32 text-blue-btn"><img :src="refreshIcon" /> Send again</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import * as pinInput from '@zag-js/pin-input'
import { normalizeProps, useMachine } from '@zag-js/vue'
import { computed } from 'vue'

const [state, send] = useMachine(pinInput.machine({ id: '1', type: 'alphanumeric', placeholder: '' }))
const api = computed(() => pinInput.connect(state.value, send, normalizeProps))
</script>

<script>
export default {
    components: {},
    methods: {},
    data() {
        return {
            refreshIcon: require('../../../assets/images/icons/refresh.svg'),
        }
    },
}
</script>
<style>
.pin-code {
    padding: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.pin-code input {
    width: 70px;
    height: 110px;
    background: #f1f5f9;
    border: 1px solid #e2e8f0;
    box-shadow: 0px 8px 16px -4px rgba(0, 12, 32, 0.12);
    border-radius: 15px;
    text-align: center;
    margin-right: 16px;
}

.pin-code input:focus {
    border: 1px solid #489651;
    outline: none;
}

@media (max-width: 468px) {
    .pin-code input {
        font-size: 28px !important;
        width: 43px !important;
        height: 47px !important;
    }
}
</style>
