<template>
    <section class="mt-42 mb-160">
        <div class="container">
            <div class="home-card">
                <div class="text-center">
                    <h2 class="main-blue-color">Confirm your program participation</h2>
                    <h3 class="mt-32 mb-32 text-left">
                        Do you participate in Medicaid or the Supplemental Nutrition Assistance Program (SNAP)?
                    </h3>
                </div>
                <div class="mb-32">
                    <div class="mb-16">
                        <input type="radio" class="radio-size" @change="changeRadio(1)"  name="data" />
                        <span class=" radio-button-text">Yes, I think I participate in Medicaid or SNAP
                        </span>
                    </div>
                    <div>
                        <input type="radio" class="radio-size" @change="changeRadio(2)" name="data" />
                        <span class=" radio-button-text">No, I dont think I participate
                            </span>
                    </div>
                    <div class="mt-3" v-if="radio === 2">
                        <h3 >Which of the following programs do you participate in?</h3>
                        <label class="mb-16" for="">Check all that apply.</label>
                        <div>
                            <div class="mb-16">
                                <input type="checkbox" class="radio-size"  name="data" />
                                <span class="font-weight-bold checkbox-button-text">
                                    Which of the following programs do you participate in?
                                </span>
                            </div>
                            <div class="mb-16">
                                <input type="checkbox" class="radio-size"  name="data" />
                                <span class="font-weight-bold checkbox-button-text">
                                    Which of the following programs do you participate in?
                                </span>
                            </div>
                            <div class="mb-16">
                                <input type="checkbox" class="radio-size"  name="data" />
                                <span class="font-weight-bold checkbox-button-text">
                                    Which of the following programs do you participate in?
                                </span>
                            </div>
                            <div class="mb-16">
                                <input type="checkbox" class="radio-size"  name="data" />
                                <span class="font-weight-bold checkbox-button-text">
                                   Which of the following programs do you participate in?
                                </span>
                            </div>
                        </div>

                    </div>
                    <div v-else>
                        <div class="mt-32">Last 4 digits of your Social Security Number</div>
                        <div class="mt-16">XXXX-XX- <input type="text" placeholder="XXXX" /></div>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-32">
                    <div>
                        <router-link to="/PersonalInformationPage" class="text-blue-btn">Back</router-link>
                    </div>
                    <div>
                        <router-link to="/ReviewPage" class="blue-btn">Next</router-link>
                    </div>
                </div>
                <div class="text-error text-right">{{ error }}</div>
            </div>
        </div>
    </section>
</template>

<script>
    import checkValidation from '../../validation/checkValidation'

    export default {
        components: {},
        methods: {
            success() {
                // if (this.showHide === true) {
                //     const contactState = this.findShortCodeState(this.state)?.shortcode
                //     this.$store.dispatch('addToPersonal', ['contactState', contactState])
                //     this.$store.dispatch('addToPersonal', ['contactAddress', this.address])
                //     this.$store.dispatch('addToPersonal', ['contactZipCode', this.zipCode])
                // }

                this.$router.push('/ReviewPage')
            },
            goNext() {
                const validation = {}

                if (this.radio === null) {
                    this.error = 'Option is mandatory'
                    return
                }

                checkValidation(validation) === true ? this.success() : (this.error = 'Zip code is mandatory')
            },
            changeRadio(value){
                this.radio = value
            }
        },
        data() {
            return {
                radio: null,
                // No, I Want to provide an official document (Driver License, Taxpayer Identification Number, Passport, etc.) or use my Tribal ID Number
                selectGovernmentOptions:[
                    {text:'Driver License',value:'driver_license'},
                    {text:'Taxpayer Identification Number',value:'taxpayer_identification_number'},
                    {text:'Passport',value:'passport'},
                ],
                valueSelected:'',
                error: '',
            }
        },
    }
</script>
<style>
    .home-card {
        background: #ffffff;
        box-shadow: 0px 8px 16px -4px rgba(0, 12, 32, 0.12);
        border-radius: 32px;
        padding: 44px 84px;
        gap: 32px;
    }

    .home-card input {
        padding: 16px 12px;
        gap: 8px;
        background: #ffffff;
        border: 1px solid #e5e7eb;
        border-radius: 8px;
    }
</style>
<style scoped>
    .container{
        box-shadow: unset;
    }
    .radio-button-text{
        font-size: 16px;
        line-height: 22.4px;
        margin-left: 1rem;
        top: -4px;
        position: relative;
    }
    .checkbox-button-text{
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.03em;
        text-align: left;


    }
    .radio-size{
        width: 20px;
        height: 20px;
        padding: 0 !important;
        border-radius: 50%;
    }
    .radio-size:checked {
        width: 20px;
        height: 20px;
        background-size: cover !important;
        background-repeat: no-repeat;
        background: url('../../../../src/assets/images/general/Radio.svg');
    }
    label{
        font-family: Raleway;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;

    }
    .radio2span{
        font-weight: 400;
        font-size:12px ;
        line-height: 22px;
    }
</style>
