<template>
  <section class="section section--top--space section--bottom--space section-activeSimCard">
    <div class="container container-1170">
      <div class="text-center">
        <div class="section-title main-green-color mb-12">Congratulations!</div>
        <p class="sorry-letter sorry-letter-header mb-32">
          We provide service in your area.
        </p>
      </div>
      <div class="section-activeSimCard--block section-activeSimCard--sorry-block mb-10 position-relative">
        <div class="section-activeSimCard--block-title">
          <h3>Your state where you are located is able to be provided service by Peeringhub</h3>
        </div>
        <p class="sorry-letter sorry-letter-footer mb-32">
          Please go ahead and fill in the fields to participate in the program
        </p>

       <!-- <div class="d-flex justify-content-between align-items-center mt-24">
          <button
              @click="goBack"
              class="back-btn"
          >
            Back
          </button>
          <button
              @click="success"
              class="next-btn"
          >
            Next
          </button>
        </div>-->

        <div class="d-flex justify-content-between align-items-center mt-32 px-sm-54 px-0 ">
          <div>
            <router-link to="/ZipCodePage"  class="text-blue-btn">Back</router-link>
          </div>
          <div>
            <button @click="success" class="blue-btn position-relative" >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="section-activeSimCard-small-background">
      <img :src="peering" alt="peering" class="w-100">
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      disableBtn: true,
      email: '',
      phone: '',
      peering: require('../../../assets/images/general/Peering.png')
    }
  },
  created() {
    const zipCode = this.$route.query?.zipCode
    if (!zipCode) {
      this.$router.push('/ZipCodePage')
      return
    }
    this.$store.dispatch('addToPersonal', ['zipCode', zipCode])
  },
  methods: {
    goBack() {
      this.$router.push('/ZipCodePage')
    },
    success() {
      this.$router.push('/PersonalInfoPage?profileStep=1')
    }
  }
}
</script>

<style scoped>

.container > div {
  max-width: 690px;
  margin: 0 auto;
}

.section {
  padding: 42px;
}

.section-title {
  font-size: 45px;
  font-weight: 600;
  color: #003875;
}

.section-activeSimCard-small-background {
  max-width: 690px;
  height: 254px;
  margin: 0 auto;
  position: relative;
  top: 20px
}

.section-activeSimCard-small-background img {
  height: inherit;
}

.section-activeSimCard--block p {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}

.section-activeSimCard--block-title {
  font-weight: 500;
  font-size: 30px;
  line-height: 35.22px;
}

.sorry-letter, .section-activeSimCard--block-title {
  color: #39404D;
  font-family: --bs-font-sans-serif;

}

.sorry-letter-header {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.03em;
  text-align: center;
  color: #39404D;


}

.container {
  width: 690px;
  height: 428px;
  padding: 44px 84px;
  border-radius: 32px;
  box-shadow: 0 8px 16px -4px #000C201F;
}

.section-activeSimCard--sorry-block {
  min-height: 172px;
  font-weight: 600;
}

.input-title {
  font-size: 14px;
  color: #39404D;
}

.letter-input input {
  border-radius: 8px;
  width: 100%;
  height: 54px;
  border: 1px solid #E5E7EB;
  outline: none;
  padding: 16px 12px;
}

.next-btn {
  border-radius: 8px;
  background: #003875;
  color: #F1F5F9;
  width: 84px;
  padding: 12px 24px;
}

.back-btn {
  border: none;
  outline: none;
  color: #003875;
  background: transparent;
}

.back-btn, .next-btn {
  font-size: 16px;
  font-weight: 600;
}
@media (min-width: 375px) and (max-width: 667px) {
  .main-green-color{
    font-family: Raleway;
    font-size: 30px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: center;

  }
  h3{
    font-size: 25px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;

  }
  .section-activeSimCard--block-title, .sorry-letter-footer{
    width: 300px;
  }
  .container{
    width: 343px;
    height: 443px;
    padding: 44px 20px;
  }

  .sorry-letter-header{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.03em;
    text-align: center;
    white-space: nowrap;

  }
  .sorry-letter-footer{
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

  }
  .section{
    padding: 0;
  }
}

@media (max-width: 469px) {
  .section-activeSimCard--sorry-block {
    min-height: 234px;
  }

  .section-activeSimCard--sorry-block input {
    width: 100% !important;
    margin-bottom: 8px;
  }

  .section-activeSimCard--sorry-block .section-activeSimCard--block-title {
    font-size: 14px;
  }

  .section-activeSimCard--sorry-block .mr-16 {
    margin-right: 0;
  }

  .section-activeSimCard--sorry-block .btn-round {
    width: 100% !important;
  }
}
</style>
