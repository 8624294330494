import i18n from '@/i18n'
import { nextTick } from 'vue'

const Tr = {
    get defaultLocale() {
        return 'en-US'
    },
    get supportedLocales() {
        return ['en', 'es']
    },
    set currentLocale(newLocale) {
        i18n.global.locale.value = newLocale
    },
    async switchLanguage(newLocale) {
        await Tr.loadLocaleMessages(newLocale)
        Tr.currentLocale = newLocale
        document.querySelector('html').setAttribute('lang', newLocale)
        localStorage.setItem('user-locale', newLocale)
    },
    async loadLocaleMessages(locale) {
        if (!i18n.global.availableLocales.includes(locale)) {
            const messages = await import(`@/i18n/locales/${locale}.json`)
            i18n.global.setLocaleMessage(locale, messages.default)
        }
        return nextTick()
    },
    isLocaleSupported(locale) {
        return Tr.supportedLocales.includes(locale)
    },
    getUserLocale() {
        const locale = window.navigator.language || window.navigator.userLanguage || Tr.defaultLocale
        return {
            locale: locale,
            localeNoRegion: locale.split('-')[0],
        }
    },
    getPersistedLocale() {
        const persistedLocale = localStorage.getItem('user-locale') || ''
        if (Tr.isLocaleSupported(persistedLocale)) {
            return persistedLocale
        } else {
            return null
        }
    },
    guessDefaultLocale() {
        const userPersistedLocale = Tr.getPersistedLocale()
        if (userPersistedLocale) {
            return userPersistedLocale
        }
        const userPreferredLocale = Tr.getUserLocale()
        if (Tr.isLocaleSupported(userPreferredLocale.locale)) {
            return userPreferredLocale.locale
        }
        if (Tr.isLocaleSupported(userPreferredLocale.localeNoRegion)) {
            return userPreferredLocale.localeNoRegion
        }

        return Tr.defaultLocale
    },
}

export default Tr
