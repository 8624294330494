<template>
    <section class="section section-contact">
        <div class="container container-1170">
            <p><b>Need Help?</b> Use this information and contact Peeringhub Mobi Support Center</p>
            <div class="footer-contact-block gray-color my-2">
                <img :src="footerIconEmail" alt="" />
                <span style="font-family: Roboto">
                support@peeringhub.io
                </span>
            </div>
            <div class="footer-contact-block gray-color my-2">
                <img :src="footerIconPhone" alt="" />
                <span style="font-family: Roboto">
                    +1 (838) 217-8999
                </span>

            </div>
            <div class="mt-8">
                <p class="main-gray-color">Support Portal: http://support.peeringhub.mobi</p>
            </div>
            <div class="mt-8">
                <p class="main-gray-color">Monday - Friday: 8:00 a.m. - 8:00 p.m.</p>
            </div>
            <div class="mt-8">
                <p class="main-gray-color">Saturday: 10:00 a.m. - 7:00 p.m.</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            footerIconEmail: require('../../../../assets/images/Footer-Icon-Email.svg'),
            footerIconPhone: require('../../../../assets/images/Footer-Icon-Phone.svg'),
        }
    },
}
</script>

<style scoped>
.section-contact {
  border-top: 1px solid #d4d6db;
  padding-top: 32px;
}

</style>
