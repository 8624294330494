<template>
    <section class="section section--top--space section--bottom--space section-activeSimCard">
        <div class="container">
            <div class="text-center">
                <div class="section-title main-blue-color mb-12">Sorry</div>
                <p class="sorry-letter mb-32">
                    Sorry, we don’t cover the state of
                    {{ this.$route.query.state ? this.$route.query.state : this.$route.query.zipCode ? this.$route.query.zipCode : 'N/A' }} yet.
                </p>
            </div>
            <div class="section-activeSimCard--block section-activeSimCard--sorry-block mb-10 position-relative">
                <div class="section-activeSimCard--block-title">
                    Please leave us your email so we can contact you when we start to provide service to the state of
                    {{ this.$route.query.state ? this.$route.query.state : this.$route.query.zipCode ? this.$route.query.zipCode : 'N/A' }}.
                </div>
                <div class="d-flex flex-column mt-24">
                    <div class="mb-24">
                        <span class="mb-8 input-title">Email</span>
                        <div class="letter-input">
                            <input @keyup="checkForm('email')" type="text" v-model="email" placeholder="Email" />
                            <span class="small-error" v-if="email_validate_error">
                                {{email_validate_error }}
                            </span>
                        </div>

                    </div>

                    <div>
                        <span class="mb-8 input-title">Phone number</span>
                        <div class="letter-input">
                            <input @keydown="keyDownEventHandler" @keyup="checkForm('phone')" style="font-family: Roboto" type="text" v-model="phone" placeholder="0(000)-000-00" />
                            <span class="small-error" v-if="phone_validate_error">
                                {{phone_validate_error }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-24">
                    <button
                            @click="goBack"
                            class="back-btn"
                    >
                        Back
                    </button>
                    <button
                            @click="success"
                            :class="[disableBtn ? 'btn-round btn-green btn-disable' : 'btn-round btn-green', 'next-btn']"
                    >
                        Next
                    </button>
                </div>

            </div>

        </div>
    </section>
</template>

<script>
    export default {
        components: {
        },
        methods: {
            isValidEmail(value,type) {
                if(type === 'email') {
                    let validate_email = /^[^@]+@\w+(\.\w+)+\w$/.test(value) ? true : false
                    if (validate_email) {
                        this.email_validate_error = ''
                    }
                    return validate_email
                }
                if(type === 'phone'){
                    let cphn = this.phone.toString().length
                    let phoneRe = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s/0-9]*$/g;
                    let digits = this.phone.replace(/\D/g, "");
                    let validate_phone =   phoneRe.test(digits) && cphn > 10 ? true : false;
                    if (validate_phone) {
                        this.phone_validate_error = ''

                    }
                    return validate_phone
                }


            },
            keyDownEventHandler(event) {
                let validateResult = true;
                if (!/^[0-9)(-+]+$/i.test(event.key) && event.key != 'Backspace') {
                    validateResult = false;
                }
                if (!validateResult) {
                    event.preventDefault();
                }
            },
            checkForm(type) {

                if (type === 'email' && this.isValidEmail(this.email,type) === false) {
                    this.email_validate_error = 'Email invalid.'
                    this.disableBtn = true
                    return false
                }
                if (type === 'phone' && this.isValidEmail(this.phone,type) === false) {
                    this.phone_validate_error = 'Phone Number Invalid.'
                    this.disableBtn = true
                    return false
                }

                this.disableBtn = false
            },
            goBack() {
                this.$router.push('/ZipCodePage')
            },
            success() {
                this.$router.push('/')
            },
        },
        data() {
            return {
                disableBtn: true,
                email: '',
                email_validate_error: '',
                phone: '',
                phone_validate_error:''
            }
        },
    }
</script>

<style scoped>
    .small-error{
        color: red !important;
    }
    .container{
        width: 850px;
        height: 537px;
    }

    .section {
        padding: 42px;
    }

    .section-title {
        font-size: 45px;
        font-weight: 600;
        color: #003875;
    }

    .sorry-letter, .section-activeSimCard--block-title {
        color: #39404D;
    }

    .container {
        padding: 44px 84px;
        border-radius: 32px;
        box-shadow: 0 8px 16px -4px #000C201F;
    }

    .section-activeSimCard--sorry-block {
        min-height: 172px;
        font-weight: 600;
    }

    .input-title {
        font-size: 14px;
        font-family: Inter;
        color: #39404D;
    }

    .letter-input input {
        border-radius: 8px;
        width: 100%;
        height: 54px;
        border: 1px solid #E5E7EB;
        outline: none;
        padding: 16px 12px;
    }
    .next-btn {
        border-radius: 8px;
        background: #003875;
        color: #F1F5F9;
        width: 84px;
        height: 46px;
        padding: 0px 24px;
    }

    .back-btn {
        border: none;
        outline: none;
        color: #003875;
        background: transparent;
    }

    .back-btn, .next-btn {
        font-size: 16px;
        font-weight: 600;
    }
    .sorry-letter{
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.03em;
        text-align: center;
    }
    .section-activeSimCard--block-title {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
    }

    .flex-column span{
        font-weight: 400;
        color: #39404D;

    }
    @media (max-width: 800px) {
        .container{
            width: 100%;
        }
    }
    @media (max-width: 375px) {
        .container{
            min-width: 343px;
            min-height: 563px;
        }
        .section{
            padding: 16px;
        }
        .main-blue-color{
            font-size: 30px;
            font-weight: 500;
            line-height: 35px;
            margin-top: 30px;
        }
        .letter-input input{
            max-width: 311px;
        }
    }

    @media (max-width: 469px) {
        .section-activeSimCard--sorry-block {
            min-height: 234px;
        }

        .container{
            padding: 12px 10px;
        }

        .section-activeSimCard--sorry-block input {
            width: 100% !important;
            margin-bottom: 8px;
        }

        .section-activeSimCard--sorry-block .section-activeSimCard--block-title {
            font-size: 14px;
        }

        .section-activeSimCard--sorry-block .mr-16 {
            margin-right: 0;
        }

    }
</style>
