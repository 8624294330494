import { errorInterceptor } from '@/utilities/errorInterseptor'
import {
    saveErrorStatus,
    destroyEligibilityCheckId,
    destroyApplicationId,
    getApplicationId, cookieRemovePersonalData
} from '@/common/eligibility.service'
import router from '@/routers'
import store from '@/store'

const USER_DATA = '_d'
const ERROR_TIMEOUT = "Endpoint request timed out"
const ERROR_UNEXPECTED = "Something went wrong, please try again later"

const saveUserData = (data) => {
    sessionStorage.setItem(USER_DATA, btoa(JSON.stringify(data)))
}
const getUserData = () => {
    const d = sessionStorage.getItem(USER_DATA)
    if (d) {
        return JSON.parse(atob(d))
    }
}
const removeUserData = () => {
    store.commit("CLEAR_personalData")
    store.commit('SET_checkTribal', false)
    sessionStorage.removeItem(USER_DATA)
    destroyEligibilityCheckId()
    destroyApplicationId()
    cookieRemovePersonalData()
}
const appIdIsExist = () => {
    const _appIdIsExist = getApplicationId()
    if (!_appIdIsExist) {
        router.push('/Rejected')
    }
    return _appIdIsExist
}

const errorNlad = (response, payload) => {
    if (response.status > 300) {
        return errorInterceptor(response.data)
    }
    const data = response.data
    if (typeof data === 'string') {
        return "HTTP Status 403 - Access is denied"
    }
    if (data.data?.errors?.length > 0) {
        return data.data.errors.map(item => item.description).join('. ')
    }
    if (data.body) {
        const errors = data.body.map(item => `${item[1]}: ${item[2]}`).join("; ")
        if (errors.includes("APPLICATION_NOT_FOUND")) {
            const d = data.body.find(item => item[2] === 'APPLICATION_NOT_FOUND')
            saveErrorStatus(JSON.stringify(d))
            removeUserData()
            router.push('/EnrolmentFailure')
            return
        }
        if (errors.includes('INELIGIBLE_FOR_TRANSFER')) {
            const d = data.body.find(item => item[2] === 'INELIGIBLE_FOR_TRANSFER')
            removeUserData()
            saveErrorStatus(JSON.stringify(d))
            router.push('/Failure')
            return
        }
        if (errors.includes('CANNOT_UPDATE_DEVICE')) {
            router.push('/EnrollStep?code=CANNOT_UPDATE_DEVICE')
            return errors
        }
        const DUPLICATE = data.body.filter(item => item[2].includes("DUPLICATE"))
        if (DUPLICATE.length !== 0) {
            const e = DUPLICATE.map(item => `${item[1]}: ${item[2]}`).join("; ")
            saveUserData(payload)
            router.push("/TransferAcpPlan")
            return e
        }
        if (errors.includes("INVALID_APPLICATION_ID")) {
            const d = data.body.find(item => item[2] === 'INVALID_APPLICATION_ID')
            saveErrorStatus(JSON.stringify(d))
            removeUserData()
            router.push('/EnrolmentFailure')
            return
        }
        return errors
    }
    if (data.url) {
        removeUserData()
        window.open(data.url, "_self")
        return
    }
    if (data.order) {
        removeUserData()
        router.push(`/CompletedACP?orderId=${data.order?.id}&nladSubscriberId=${data?.nladSubscriberId}&activationCode=${data?.activationCode?.activationCode}`)
        return
    }
    if (data) {
        return data.message || JSON.stringify(data)
    }
    return ERROR_UNEXPECTED
}

export {
    errorNlad, getUserData, removeUserData, saveUserData, ERROR_TIMEOUT,
    appIdIsExist
}