import ApiService from './api.service'
import { getResellerCode } from '@/common/storageHelper'

const utility = {
    async isValidResellerCode() {
        const resellerCode = getResellerCode()
        if (!resellerCode) {
            return
        }
        const { data } = await ApiService.post(`${process.env.VUE_APP_API_LINK}/public/utility/is_valid_reseller_code`, {
            resellerCode
        })
        if (data.isValid) {
            return resellerCode
        }
    },
}

export default utility
