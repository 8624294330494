<template>
    <section class="mt-42 mb-80">
        <div class="container">
            <div class="text-center">
                <h2 class="w-752 benefit-header-title">
                    You qualify for the
                    <span class="main-blue-color">Affordable Connectivity Program</span>
                    benefit!
                </h2>
            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="benefit-card">
                <div class="text-center">
                    <h2 class="mb-24 benefit-card-subtitle">You are eligible for the following benefits:</h2>
                    <div class="mb-24 special-plan">
                        <img :src="okIcon" alt="" /> Free Special plan and Android tablet
                    </div>
                    <div class="mb-24">
                        It is very important to take care of the patient, and the patient will be followed, but it will
                        happen at such a time that he will have to work with some great pain
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-32">
                        <div class="section-plan-card m-auto">
                            <div class="section-plan-card-header">
                                <div class="section-plan-card-title">5G LTE DATA</div>
                                <div class="section-plan-card-size">15GB<span>/mo</span></div>
                            </div>
                            <div>
                                <div class="section-plan-card-price black-color">$25<span>/mo</span></div>
                                <div>
                                    <ul class="section-plan-card-list">
                                        <li>Unlimited talk & text</li>
                                        <li>Nationwide coverage</li>
                                        <li>Uses 5G or 4G LTE</li>
                                        <li>whichever is strongest</li>
                                    </ul>
                                </div>
                                <div class="text-center mt-24 mb-10">
                                    <router-link class="ghost-blue-btn" to="/">Get this plan</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center mb-64">
                    <div class="blue-btn">Enroll now</div>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="quality-banner">
                <div class="quality-title">
                    This service is provided by Peeringhub Inc., an approved ACP service provider:
                </div>
                <div>
                    <img class="quality-banner-image" :src="logo" alt="" />
                </div>
            </div>
        </div>
    </section>

    <section class="mb-42 mb-66">
        <div class="container text-center">
            <div class="check-card">
                <h2 class="check-card-text mb-24">
                    Check if you are eligible for additional
                    <span class="main-blue-color">Tribal Benefit</span>
                </h2>
                <div class="mb-32 d-inline-block">
                    It is very important to take care of the patient, and the patient will be followed, but it will
                    happen at such a time that he will have to work with some great pain
                </div>
                <div>
                    <div class="blue-btn">Check now</div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    components: {},
    methods: {},
    data() {
        return {
            logo: require('../../../assets/images/icons/logo.svg'),
            tabletImg: require('../../../assets/images/icons/tablet.svg'),
            okIcon: require('../../../assets/images/icons/okIcon.svg'),
            miniTablet: require('../../../assets/images/icons/miniTablet.svg'),
        }
    },
}
</script>
<style>
@import '../../../assets/styles/components/pages/RegisterFlowPage/Quality.css';
</style>
<style scoped>
.benefit-card-subtitle,
.check-card-text {
    font-size: 40px;
    font-weight: 500;
    line-height: 46px;
}
.check-card-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.benefit-card-subtitle {
    width: 637px;
}
.section-plan-card-size,
.section-plan-card-title {
    font-family: Montserrat;
}
.quality-banner {
    background: url('../../../assets/images/pages/NotQualifedPage/mainscreen.png');
    height: 254px;
    background-repeat: no-repeat;
    width: 100%;
    background-position-y: 34%;
    background-size: cover;
}
@media (min-width: 768px) and (max-width: 1024px) {
    .check-card-text {
        display: block;
    }
}
@media (max-width: 376px) {
    .benefit-card-subtitle,
    .check-card-text,
    .benefit-header-title {
        font-size: 30px;
        line-height: 35.22px;
        font-weight: 500;
    }
    .benefit-card-subtitle,
    .check-card-text {
        width: 311px;
    }
    .benefit-header-title {
        width: 351px;
    }
}
</style>
