<template>
  <section class="section section-profileTitle">
    <div class="container container-1170">
      <h2>Check <span class="main-green-color">Eligibility</span></h2>
    </div>
  </section>

  <section class="section section-profile pb-80">
    <div class="container container-1170">
      <div class="row">
        <div class="col-md-3">
          <ProflieBreadcrumbs :stepNum="profileStep" />
        </div>
        <div class="col-md-8 col">
          <h3>Tribal Status</h3>
          <div class="general-card">
            <div class="justify-content-center align-content-center d-flex flex-column gap-24px">
              <div class="w-full d-flex justify-content-center">
                <div>
                  <input :checked="checkTribal" @change="changeCheckTribal" class="mr-8 checkbox" type="checkbox" name="scales" />
                </div>
                <div>
                  <label>Check if you are eligible for additional <span class="color-green">Tribal Benefit</span>.  When qualified for Tribal benefits, you can enjoy Unlimited Data, Text, and Call plan.</label>
                </div>
              </div>
              <div v-if="checkTribal" class="tribal-card">
                  <div class="flex-grow-1">
                    <label>Tribal number</label>
                    <input
                        required
                        placeholder="Tribal number"
                        v-model="tribalId"
                        maxlength="24"
                        type="number"
                        class="tribal-card-number-block-input"
                    />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between mt-32">
              <router-link to="/QualificationPage?profileStep=4" class="btn-round btn-black-ghost"
              >Back</router-link
              >
              <button @click="finilizeStep" class="btn-round btn-green">Continue</button>
            </div>
            <div class="text-error text-right">{{ error }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <InternalServerError />
</template>

<script>
import ProflieBreadcrumbs from '@/components/helpers/ProfileBreadcrumbs.vue'
import InternalServerError from '@/components/helpers/Modals/InternalServerError.vue'

export default {
  mounted() {
    if (this.$store.state.PersonalStore?.personalData?.firstName.length < 1) {
      this.$router.push('/ZipCodePage')
    }
  },
  components: {
    InternalServerError,
    ProflieBreadcrumbs,
  },
  created() {
    this.tribalId = this.$store.state.PersonalStore?.personalData?.tribalId
  },
  computed: {
    profileStep() {
      return this.$route.query.profileStep
    },
    tribalId: {
      get () {
        return this.$store.state.PersonalStore?.personalData?.tribalId
      },
      set (value) {
        this.changeTribalId(value.toString())
      }
    },
    checkTribal() {
      return this.$store.state.PersonalStore?.checkTribal
    }
  },
  methods: {
    changeTribalId(value="") {
      this.$store.dispatch('addToPersonal', ['tribalId', value])
    },
    finilizeStep() {
      if (this.checkTribal && this.tribalId.length < 1) {
        this.error = 'Tribal number is mandatory'
        return
      }
      if (!this.checkTribal) {
        this.changeTribalId()
      }
      this.goNext()
    },
    goNext() {
      this.$router.push('/VerifyPage?profileStep=6')
    },
    changeCheckTribal(event) {
      this.$store.commit('SET_checkTribal', event.target.checked)
    }
  },
  watch: {
    checkTribal(value) {
      if (!value) {
        this.changeTribalId()
      }
    }
  },
  data() {
    return {
      failIcon: require('../../../../assets/images/fail.svg'),
      successIcon: require('../../../../assets/images/success.svg'),
      checkStatus: true,
      isSuccess: true,
      isFail: false,
      error: ''
    }
  },
}
</script>

<style scoped>
.tribal-card {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: #FFF;
}
.tribal-card-shadow {
  box-shadow: 0px 5px 16px -4px rgba(0, 12, 32, 0.08);
}
.tribal-card-title {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 33.6px */
}
.tribal-card-number-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 24px;
}
.tribal-card-number-block-button {
  display: flex;
  height: 36px;
  padding: 12px 24px;
  border-radius: 8px;
  border: 1px solid #489651;
  color: #489651;
  /* Button */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  justify-content: center;
  align-items: center;
  background: none;
}
.tribal-card-number-block-input {
  height: 36px !important;
  width: 303px;
}
.tribal-card-success {
  color: #0C5A15;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
}
.tribal-card-fail {
  color: #AB0000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
}


@media screen and (max-width: 498px) {
  .tribal-card {
    padding: 0;
    box-shadow: none;
  }
  .tribal-card-number-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 16px;
  }
  .tribal-card-number-block-button {
    width: 303px;
  }
}

</style>
