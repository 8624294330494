export const getDaysInMonth = (year, month) => {
    let day_count = new Date(year, month, 0).getDate()
    let days = []
    for (let i = 1; i <= day_count; i++) {
        days.push({text:i,value:i })
    }
    return days
}

export const generatYear = (startYear) => {
    const currentYear = new Date().getFullYear() - 18,
        years = []
    startYear = startYear || 1900
    for (let i = currentYear; i >= startYear ; i--) {
        years.push({text:i,value:i })
    }
    return years
}
