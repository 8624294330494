<template>
    <section class="mt-42 mb-160">
        <div class="container">
            <div class="zip-card">
                <div class="text-center">
                    <h2 class="main-blue-color">Fill out your information</h2>
                    <h3 class="mt-32 mb-32 text-left">
                        Contact information
                    </h3>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <label>Email Address</label>
                        <input
                                v-model="email"
                                type="email"
                                class="w-100"
                                placeholder="email@email.com"
                        />
                    </div>
                    <div class="col-12 col-md-6">
                        <label>Phone (optional)</label>
                        <input
                                v-model="phone_number"
                                type="text"
                                class="w-100"
                                placeholder="(000) 000-00000"
                        />
                    </div>

                </div>
                <div class="mt-2">
                    <span>
                        By providing a phone number, you consent to letting USAC contact you at that phone number
                        via artificial or prerecorded voice message or text for important reminders and updates
                        about your Affordable Connectivity Program benefit. For text messages, message and data
                        rates may apply. Text STOP to end messages.
                    </span>
                </div>
                <div class="d-flex justify-content-between mt-32">
                    <div>
                        <router-link to="/HomeAdressPage" class="text-blue-btn">Back</router-link>
                    </div>
                    <div>
                        <button @click="goNext" class="blue-btn">Next</button>
                    </div>
                </div>
                <div class="text-error text-right">{{ error }}</div>
                <div v-if="showSuccess" class="text-green text-right">Zip code is supported</div>
            </div>
        </div>
    </section>
</template>

<script>


    export default {
        components: {},
        data() {
            return {
                email: '',
                phone_number: '',
                error: '',
                showSuccess: false
            }
        },
        computed: {
           /* currentState() {
                return zipCodes.find(e => e.zipMin <= +this.zipCode && e.zipMax >= +this.zipCode)
            }*/
        },
        methods: {
           /* ...mapActions(['checkZipCode']),
            onlyNumber(event) {
                if (!/\d/.test(event.key)) return event.preventDefault()
            },*/
            async goNext() {
                this.$router.push('/QuestionPage')
            },
        },
    }
</script>
<style scoped>
    .zip-card {
        margin: auto;
        padding: 44px 84px;
        gap: 32px;
        background: #ffffff;
        box-shadow: 0px 8px 16px -4px rgba(0, 12, 32, 0.12);
        border-radius: 32px;
    }

    .zip-card input {
        font-family: Roboto;
        padding: 16px 12px;
        gap: 8px;
        background: #ffffff;
        border: 1px solid #e5e7eb;
        border-radius: 8px;
    }

    .text-green {
        color: green;
    }
    .container{
        box-shadow: unset;
    }
    span{
        font-weight: 400;
        font-size: 12px ;
        line-height: 16.8px;
        color: #64748B
    }
    label{
        font-weight: 400;
        font-size: 14px ;
        line-height: 22px;
    }

    @media (min-width: 1400px) {
        .zip-card input{
            width: 553px !important;
        }
    }
</style>
