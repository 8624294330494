import Cookie from 'js-cookie'

export const getEligibility = () => {
    return localStorage.getItem('Eligibility')
}

export const saveEligibility = (eligibility) => {
    localStorage.setItem('Eligibility', eligibility)
}

export const destroyEligibility = () => {
    localStorage.removeItem('Eligibility')
}

export const checkEligibility = () => {
    return getEligibility() == null ? false : true
}

export const saveApplicationId = (applicationId) => {
    localStorage.setItem('ApplicationId', applicationId)
}

export const getApplicationId = () => {
    return localStorage.getItem('ApplicationId')
}

export const destroyApplicationId = () => {
    localStorage.removeItem('ApplicationId')
}
export const saveEligibilityCheckId = (applicationId) => {
    localStorage.setItem('eligibilityCheckId', applicationId)
}

export const getEligibilityCheckId = () => {
    return localStorage.getItem('eligibilityCheckId')
}

export const destroyEligibilityCheckId = () => {
    localStorage.removeItem('eligibilityCheckId')
}
export const saveTribalStatus = (applicationId) => {
    localStorage.setItem('tribalStatusConfirmed', applicationId)
}

export const getTribalStatus = () => {
    return localStorage.getItem('tribalStatusConfirmed')
}

export const destroyTribalStatus = () => {
    localStorage.removeItem('tribalStatusConfirmed')
}

export const saveErrorStatus = (error) => {
    localStorage.setItem('nlad-error', error)
}
export const getErrorStatus = () => {
    return localStorage.getItem('nlad-error')
}
export const destroyErrorStatus = () => {
    localStorage.removeItem('nlad-error')
}

export const cookieSetPersonalData = (payload) => {
    Cookie.set('_pd', btoa(JSON.stringify(payload)), { expires: 0.05 }) // ~1 hour
}
export const cookieGetPersonalData = () => {
    const data = Cookie.get('_pd')
    if (data) {
        return JSON.parse(atob(data))
    }
    return undefined
}
export const cookieRemovePersonalData = () => {
    Cookie.remove('_pd')
}

export default {
    getEligibility,
    saveEligibility,
    destroyEligibility,
    checkEligibility,
    saveApplicationId,
    getApplicationId,
    destroyApplicationId,
    saveEligibilityCheckId,
    getEligibilityCheckId,
    destroyEligibilityCheckId,
    saveTribalStatus,
    getTribalStatus,
    destroyTribalStatus,
    saveErrorStatus,
    getErrorStatus,
    destroyErrorStatus,
    cookieSetPersonalData,
    cookieGetPersonalData,
    cookieRemovePersonalData
}
