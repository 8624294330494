<template>
    <AboutUs />
    <Afordable />
    <Qualify />
    <TribalBenefit />
</template>

<script>
import AboutUs from '../../sections/SinglePages/AboutACPPage/01_AboutUs.vue'
import Afordable from '../../sections/SinglePages/AboutACPPage/02_Afordable.vue'
import Qualify from '../../sections/SinglePages/AboutACPPage/03_Qualify.vue'
import TribalBenefit from '../../sections/SinglePages/AboutACPPage/04_TribalBenefit.vue'

export default {
    components: {
        AboutUs,
        Afordable,
        Qualify,
        TribalBenefit,
    },
    methods: {},
    data() {
        return {}
    },
}
</script>
<style></style>
