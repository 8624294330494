<template>
    <section>
        <div class="container">

             <h2 class="d-block justify-content-center mb-32">Or we can recommend you</h2>
            <div class="d-md-flex justify-content-center" style="margin: 0 auto">

                <div>
                    <div class="header-w right-box">
                        <h3>
                            This service is provided by 1PSim Inc., an approved ACP service provider:
                        </h3>
                        <img :src="logo" alt="" class="logo" />
                    </div>
                </div>
                <div class=" left-box">
                     <div class="left-parent-box">
                                  <p class="font-family-Robot">5G LTE DATA</p>
                         <div class="font-family-Inter">15GB
                             <span>/mo</span></div>
                     </div>
                    <div class="left-child-box font-family-Robot">$5
                        <span>/MO</span>
                    </div>
                    <div class="div-ul">
                        <ul >
                            <li>Unlimited talk & text</li>
                            <li>Nationwide coverage</li>
                            <li> Uses 5G or 4G LTE,</li>
                            <li> whichever is strongest</li>
                        </ul>
                    </div>

                    <div class="d-flex justify-content-center mb-41 ml-30">
                        <router-link to="/ZipCodePage" class="ghost-blue-btn mr-30">Get this Plan</router-link>
                    </div>

                </div>

            </div>
        </div>
    </section>
</template>
<script>
    export default {
        components: {},
        methods: {},
        data() {
            return {
                logo: require('../../../../assets/images/icons/P-logo.svg'),
            }
        },
    }
</script>
<style>
    @import '../../../../assets/styles/components/sections/SinglePages/AboutACPPage/02_Afordable.css';
</style>
<style scoped>
    .left-box{
        border-radius: 24px;
        background: #f8f1f1;
        width: 280px;
        height: 390px;
        margin-left: 30px;
    }
    .left-parent-box{
        background-color: #003875;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        width: inherit;
        height: 100px;
    }
    .left-parent-box p{
        color: white;
        text-align: center;
        padding-top:10px;
        padding-bottom: 0
    }
    .left-parent-box div{
        text-align: center;
        color: white;
        font-family: Montserrat;
        font-size: 40px;
        font-weight: 600;
        line-height: 48px
    }
    .left-parent-box span{
        color: white;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: left;
    }
    .left-child-box{
        text-align: center;
        color: #003875;
        font-family: Montserrat;
        font-size: 40px;
        font-weight: 600;
        line-height: 48px ;
        padding-top:20px;
    }
    .left-child-box span{
        color: #003875;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: left;
    }
    .right-box{
        background-repeat: no-repeat;
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        background-image: url('../../../../assets/images/icons/sorry_box_background.png');
        border-radius: 32px;
        width: 550px;
        height: 390px
    }
    .ghost-blue-btn{
        padding: 12px 20px;
        background: white;
    }
    h2{
        font-family: Raleway;
        font-size: 40px;
        font-weight: 500;
        line-height: 47px;
        letter-spacing: 0em;
        text-align: center;

    }
    h3{
        font-family: Roboto;
        width: 469px;
        font-size: 30px;
        font-weight: 500;
        line-height: 35px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0 auto;
        color: white;
        padding-top: 80px;
    }
    .logo{
        width: 127px;
        height: 127px;
        margin-left: 40px;
        margin-top: 30px;

    }
    .div-ul{
        align-items: center;
        padding-top: 20px;
        margin-left: 20px
    }
    li{
        font-family: Inter;
    }
    .font-family-Robot{
        font-weight: 400;
        font-family: Roboto;
    }
    .font-family-Inter{
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        font-family: Inter;
    }
    @media (max-width: 768px) {
        .left-box{
            margin-top: 20px !important;
            margin: auto;
        }
    }
    @media (min-width: 375px) and (max-width: 600px) {

        .right-box{
            margin-left: 5px;
            width: 343px;
        }
        h3{
            margin-left: 15px;
            width: auto;
        }
    }
</style>
