const onlyAlhpabet = (e) => {
    var regex = new RegExp('^[a-zA-Z.-]+$')
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode)
    if (regex.test(str)) {
        return true;
    }
    return e.preventDefault();
}

export default onlyAlhpabet;
