<template>
    <div :class="[showModalTablet == true ? 'd-block modal' : 'd-none']" id="TabletModal" class="invisible-scrollbar">
        <div
            :class="[showModalTablet == true ? 'd-block modal-dialog modal-tablet-content' : 'd-none']"
            role="document"
        >
            <div class="modal-content">
                <div class="position-relative">
                    <div class="btn-close" @click="closeWindow()"></div>
                    <div class="row section-tablet-black">
                        <div class="col-md-6 col-sm-12 col-xs-12">
                            <div class="section-tablet-h1 mb-32">
                                {{ $t('helpers.modals.tablet.getTablet') }}
                                <span class="main-green-color">$10.01</span>
                            </div>
                            <div class="section-tablet-h2 mb-24">{{ $t('helpers.modals.tablet.characterirstics') }}</div>
                            <table class="section-tablet-table">
                                <tr>
                                    <td>{{ $t('helpers.modals.tablet.platform') }}</td>
                                    <td>{{ $props.tabletData?.name }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('helpers.modals.tablet.os') }}</td>
                                    <td>Android 10</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('helpers.modals.tablet.size') }}</td>
                                    <td>213 x 125 x 9 mm</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('helpers.modals.tablet.display') }}</td>
                                    <td>Rear: 5 MP; Front: 2MP</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('helpers.modals.tablet.camera') }}</td>
                                    <td>4500 mAh (4630 mAh Typical)</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('helpers.modals.tablet.battery') }}</td>
                                    <td>2GB RAM + 32GB ROM</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('helpers.modals.tablet.wifi') }}</td>
                                    <td>802.11 b/g/n 2.4G</td>
                                </tr>
<!--                                <tr>-->
<!--                                    <td>Bands</td>-->
<!--                                    -->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>Certification</td>-->
<!--                                    -->
<!--                                </tr>-->
                            </table>
                        </div>
                        <div class="col-md-6 col-sm-12 col-xs-12">
                            <img :src="tabletImg" class="img-fluid mt-80" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div :class="[showModalTablet == true ? 'modal-bg' : 'd-none']" @click="this.closeWindow()"></div>
</template>

<script>
export default {
    props: ['showModalTablet', 'tabletData'],
    methods: {
        closeWindow() {
            this.$parent.$data.showModalTablet = false
        },
    },
    data() {
        return {
            tabletImg: require('../../../../assets/images/icons/tablet.svg'),
        }
    },
}
</script>

<style>
.modal-tablet-content .modal-content,
.modal-tablet-content {
    max-width: 1170px !important;
    background: none;
}
.modal-tablet-content .modal-content {
    width: 1170px !important;
}
.btn-close:hover {
    cursor: pointer;
}
.btn-close {
    z-index: 9999;
    position: absolute;
    filter: brightness(0) invert(1);
    width: 32px !important;
    height: 32px !important;
}

@media only screen and (max-width: 1171px) {
    .modal-tablet-content .modal-content {
        width: 100% !important;
    }
    .modal-tablet-content .modal-content,
    .modal-tablet-content {
        max-width: 100% !important;
    }
}

</style>
