<template>
    <section>
        <div class="container text-center blog-page-header mt-42 mb-62">
            <div class="main-blog-title mb-32">Latest from our Blog</div>
            <div class="blog-page-subtitle">
                It is very important for the customer to follow the coaching process, but I will give it this time
            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-sm-6 cursor-pointer d-flex justify-content-center" v-for="(item, index) in block_lists" :key="index">
                    <div class="blog-card" @click="$router.push('/BlogPage')">
                        <div>
                            <img class="blog-image" :src="item.img" alt="" />
                        </div>
                        <div class="mb-12">Growth {{item.date}}</div>
                        <div class="blog-title mb-12">{{item.title}}</div>
                        <div class="blog-description">
                          {{item.text}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    components: {},
    methods: {},
    data() {
        return {
          block_lists: [
            {
              img : require('../../assets/images/icons/blog.svg'),
              date: 'April 09, 2022',
              title: 'How a visual artist redefines success in graphic design',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'
            },
            {
              img : require('../../assets/images/icons/Frame7.png'),
              date: 'April 09, 2022',
              title: 'How a visual artist redefines success in graphic design',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'
            },
            {
              img : require('../../assets/images/icons/blog.svg'),
              date: 'April 09, 2022',
              title: 'How a visual artist redefines success in graphic design',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'
            },
            {
              img : require('../../assets/images/icons/Frame6.png'),
              date: 'April 09, 2022',
              title: 'How a visual artist redefines success in graphic design',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'
            },
            {
              img : require('../../assets/images/icons/blog.svg'),
              date: 'April 09, 2022',
              title: 'How a visual artist redefines success in graphic design',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'
            },
            {
              img : require('../../assets/images/icons/Frame5.png'),
              date: 'April 09, 2022',
              title: 'How a visual artist redefines success in graphic design',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'
            },
            {
              img : require('../../assets/images/icons/Frame4.png'),
              date: 'April 09, 2022',
              title: 'How a visual artist redefines success in graphic design',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'
            },
            {
              img : require('../../assets/images/icons/Frame2.png'),
              date: 'April 09, 2022',
              title: 'How a visual artist redefines success in graphic design',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'
            },
            {
              img : require('../../assets/images/icons/Frame3.png'),
              date: 'April 09, 2022',
              title: 'How a visual artist redefines success in graphic design',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'
            },
          ],
        }
    },
}
</script>
<style scoped>
.container {
  box-shadow: unset !important;
  padding: unset !important;
}
</style>
<style>
.blog-card {
    width: 100% !important;
    margin-bottom: 32px;
  box-shadow: 0 8px 16px -4px #000C201F;
}

.blog-image {
    width: 100%;
}

.blog-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}

.blog-page-subtitle {
    font-weight: 500;
    font-size: 25px;
    line-height: 29.35px;
    color: #475569;
}

.blog-description {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.03em;
    font-feature-settings: 'liga' off;
    color: #475569;
}

.main-blog-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 55px;
    line-height: 65px;
    color: #0f172a;
}

.main-blog-paragraph {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    text-align: center;
    font-feature-settings: 'liga' off;
    color: #475569;
}
@media (max-width: 768px) {
    .blog-card {
        width: 329px;
    }
    .blog-image {
        height: 250px;
        width: 329px;
    }
}
@media (max-width: 376px) {
    .blog-page-subtitle {
        font-size: 20px;
        font-weight: 500;
        line-height: 23.48px;
    }
    .blog-image {
        width: 329px !important;
        border-radius: 24px;
        object-fit: none;
        height: 250px;
    }
    .main-blog-title {
        font-size: 35px;
        line-height: 41px;
    }
}
</style>
