<template>
    <section class="mt-42 mb-80">
        <div class="container">
            <div class="text-center">
                <h2 class="w-752">
                    <span class="main-blue-color mb-24">Congratulations!</span>
                </h2>
                <div class="enrollment__title">You completed enrollment process</div>
            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="register-card">
                <div class="enrollment__blue_title mb-32">Your enrollment is successful</div>
                <div class="enrollment__card">
                    <div class="d-flex">
                        <div>
                            <div class="enrollment__blue_subtitle mb-32">You will receive your SIM card on XXXXX</div>
                            <div>Your Phone Number is</div>
                            <div class="font-weight-bold">(000) 00 000000</div>
                        </div>
                        <div>
                            <div class="enrollment__blue_subtitle mb-32">You will receive your Tablet on</div>
                            <div>Tracking number</div>
                            <div class="font-weight-bold">11111111111111111111</div>
                        </div>
                    </div>
                </div>
                <div class="mt-64 text-right">
                    <router-link to="/" class="blue-btn">Go to Home</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    components: {},
    methods: {},
    data() {
        return {}
    },
}
</script>
<style>
.enrollment__card {
    background: #f1f5f9;
    border-top: 1px solid #003875;
    border-radius: 0px 0px 16px 16px;
    padding: 32px 16px;
    gap: 32px;
}

.enrollment__title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    font-feature-settings: 'liga' off;
    color: #0f172a;
}

.enrollment__blue_title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    font-feature-settings: 'liga' off;
    color: #003875;
}

.enrollment__blue_subtitle {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    font-feature-settings: 'liga' off;
    color: #0f172a;
}
</style>
