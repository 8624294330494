<template>
    <section class="mb-160">
        <div class="container container-1170">
            <h2>Enhanced Tribal Benefit</h2>
            <div>
                <div class="acptitle main-blue-color">
                    Households who qualify for the Affordable Connectivity Program (ACP) and are located on qualifying
                    Tribal lands can receive:
                </div>
                <ul class="informer_list">
                    <li>Up to a $ <span class="span-font-family-Robot">75</span>/month discount on your internet
                        service, and</li>
                    <li>
                        A one-time discount of up to $<span class="span-font-family-Robot">100</span> for a laptop,
                        tablet, or desktop computer (with a co-payment
                        of more than $10 but less than $<span class="span-font-family-Robot">50</span>).
                    </li>
                </ul>
                <div class="paragraph_medium">
                    Only one monthly service discount and one device discount is allowed per household. Program rules
                    acknowledge there may be more than one eligible household residing at the same address.
                </div>
            </div>
            <div>
                <div class="acptitle main-blue-color">Do I Qualify for the Enhanced Tribal Benefit?</div>
                <div class="paragraph_medium mb-29">Your household can receive the enhanced benefit if:</div>
                <ol class="tips_list">
                    <li>Your household is located on qualifying Tribal lands, AND</li>
                    <li>
                        Your household income is at or below <span class="span-font-family-Robot">200</span>% of the
                        federal poverty guidelines OR any member of
                        your household:
                    </li>
                    <ul class="informer_list">
                        <li>Participates in the Lifeline program, or</li>
                        <li>
                            Participates in the Supplemental Nutrition Assistance Program (SNAP), Medicaid, Supplemental
                            Security Income, Federal Public Housing Assistance (FPHA) (including Housing Choice Voucher
                            (HCV) Program (Section 8 Vouchers), Project-Based Rental Assistance (PBRA)/
                            <span class="span-font-family-Robot">202</span>/
                            <span class="span-font-family-Robot">811</span>, Public
                            Housing, and Affordable Housing Programs for American Indians, Alaska Natives or Native
                            Hawaiians), or Veterans and Survivors Pension Benefit, or
                        </li>
                        <li>Participates in one of several Tribal specific programs:</li>
                        <ul>
                            <li>Bureau of Indian Affairs General Assistance,</li>
                            <li>Tribal Head Start (only households meeting the relevant income qualifying standard</li>
                            <li>Tribal Temporary Assistance for Needy Families (Tribal TANF), or</li>
                            <li>Food Distribution Program on Indian Reservations,</li>
                        </ul>
                        <li>
                            Receives Special Supplemental Nutrition Program for Women, Infants, and Children (WIC)
                            assistance, or
                        </li>
                        <li>Received a federal Pell Grant in the current award year, or</li>
                        <li>
                            Received approval for benefits under the free and reduced-price school lunch program or the
                            school breakfast program, including through the USDA Community Eligibility Provision, or
                        </li>
                        <li>
                            Meets the eligibility criteria for a participating company’s existing low-income program,
                            and that provider received FCC approval for its eligibility verification process.
                        </li>
                    </ul>
                </ol>
            </div>
            <div class="paragraph_medium">
                Note: If you already participate in the Lifeline program, you are automatically qualified for the ACP
                and you do not need to apply and prove your eligibility a second time. Talk to your internet company
                about how to receive ACP discounts. If you qualify for both programs, you can choose to receive the
                Lifeline benefit from one company and the ACP benefit from a different company. Find a
                <span class="emphasis">participating company near you</span>.
            </div>
            <div>
                <div class="acptitle main-blue-color">What are Qualifying Tribal Lands?</div>
                <div class="paragraph_medium">
                    Your internet company can help determine whether your address is on Tribal lands. For informational
                    purposes, a nationwide map is available showing Tribal lands eligible for enhanced support. The ACP
                    uses the same definition for Tribal lands as the FCC’s Lifeline program (as defined in
                    <span class="span-font-family-Robot">47</span>  CFR


                    §<span class="span-font-family-Robot">54.400</span> (e)). Tribal lands shown on the
                    <span class="emphasis">nationwide map</span> include:
                </div>
                <ul class="informer_list">
                    <li>Any federally recognized Indian tribe’s reservation, pueblo, or colony,</li>
                    <li>Former reservations in Oklahoma,</li>
                    <li>
                        Alaska Native regions established pursuant to the Alaska Native Claims Settlement Act (view
                        maps: <span class="span-font-family-Robot">2010</span> U.S. Census American Indians and Alaska
                        Natives Map – Alaska, State of Alaska ANCSA
                        Boundaries Map),
                    </li>
                    <li>Indian allotments,</li>
                    <li>Hawaiian Home Lands (view map: <span class="span-font-family-Robot">2010</span> U.S. Census
                        Hawaiian Homelands), and</li>
                    <li>
                        Any land approved as Tribal for the purposes of the Lifeline program or the ACP by the FCC’s
                        Office of Native Affairs and Policy and Wireline Competition Bureau.
                    </li>
                </ul>
                <div class="paragraph_medium">For additional file formats, visit Lifeline’s Tribal Lands page.</div>
            </div>
            <div>
                <div class="acptitle main-blue-color">Tips for Applicants</div>
                <ol class="tips_list">
                    <li>Tips for Applicants</li>
                    <li><span class="emphasis">On the paper application</span>, check the ‘Tribal Lands’ box.</li>
                    <li>
                        Prove your identity by providing the last four digits of your Social Security Number, your
                        Tribal ID, or identity documents. For examples of acceptable identity documents, look at the
                        “Identity Verification” section of our
                        <span class="emphasis">Show You Qualify</span> page.
                    </li>
                    <li>
                        Check the
                        <span class="emphasis">program’s definition of a household</span>, as multiple households could
                        reside at the same address.
                    </li>
                    <li>
                        If you do not have or know your street address, our online application has a pin-drop system.
                        You can also provide an address description.
                    </li>
                    <li>
                        If you qualify for the ACP but your household is not on qualifying Tribal lands, you can still
                        receive the non-Tribal discount of up to $<span class="span-font-family-Robot">30</span>
                        per month on internet service and the one-time $<span class="span-font-family-Robot">100</span>
                        device discount.
                    </li>
                </ol>
            </div>
            <div class="validateSectionCard text-center">
                <div class="subtitle text-font">
                    Are you ready to get started with the Affordable Connectivity Program?
                </div>
                <div class="mt-24 text-font-1">
                    You can apply online or with the assistance of your internet company.
                </div>
                <div class="mt-24 text-center">
                    <router-link to="/ZipCodePage" class="blue-btn w-129 m-auto">Apply now</router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    components: {},
    methods: {},
    data() {
        return {}
    },
}
</script>
<style>
@import '../../../../assets/styles/components/sections/SinglePages/AboutACPPage/04_TribalBenefit.css';
</style>
<style scoped>
@media (max-width: 667px) {
    .acptitle {
        font-size: 25px;
        line-height: 29.35px;
    }
    h2 {
        font-weight: 500;
        font-size: 30px;
        line-height: 35.22px;
    }
}
.text-font {
    font-weight: 600;
    font-size: 25px;
    line-height: 29.35px;
}
.text-font-1 {
    font-size: 16px;
    line-height: 22.4px;
}
.validateSectionCard a {
    color: white;
}.container{
     padding: 32px;
     max-width: 1070px !important;
 }

.blue-btn:hover{
    background: white !important;
    color: #003875 !important;
}
.span-font-family-Robot{
    font-weight: 400;
    font-family: Roboto;
}
@media (min-width: 667px) and (max-width: 768px) {
    .validateSectionCard {
        max-width: 626px;
        height: 238px;
    }
    .text-font-1 {
        margin-top: 24px;
    }
}
@media (max-width: 375px) and (max-width: 667px) {
    .validateSectionCard {
        max-width: 311px;
        height: 271px;
    }
    .text-font {
        font-size: 20px;
        line-height: 23px;
    }
    .text-font-1 {
        font-size: 16px;
        line-height: 22.4px;
        margin-top: 10px;
    }
}
@media (min-width: 1024px) {
    .validateSectionCard {
        margin: 0 auto;
        max-width: 1006px;
        height: 209px;
    }
}
</style>
