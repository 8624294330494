import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './routers'
import i18n from '@/i18n/index'

import Toast, { POSITION, TYPE } from 'vue-toastification'
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'
const options = {
    hideProgressBar: true,
    icon: false,
    position: POSITION.TOP_CENTER,
    draggable: false,
    closeOnClick: false,
    toastDefaults: {
        // ToastOptions object for each type of toast
        [TYPE.ERROR]: {
            timeout: 3000,
        },
        [TYPE.WARNING]: {
            timeout: 3000,
        },
        [TYPE.SUCCESS]: {
            timeout: 3000,
        },
        [TYPE.DEFAULT]: {
            timeout: 3000,
        },
    },
}

const app = createApp(App)
app.use(i18n)
app.use(router)
app.use(store)
app.use(Toast, options)
app.mount('#app')
