<template>
    <section class="sliderSection">
        <div class="container">
            <div class="sliderContainer">
                <div class="sliderTitle">About ACP</div>
                <div class="sliderParargaph">
                    The customer is very important, the customer will be followed by the customer</div>
                <div class="d-flex justify-content-center mb-41">
                    <router-link to="/ZipCodePage" class="link text-color white-btn mr-30">Apply for ACP</router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    components: {},
    methods: {},
    data() {
        return {}
    },
}
</script>
<style>
@import '../../../../assets/styles/components/sections/SinglePages/AboutACPPage/01_AboutUs.css';
</style>
<style scoped>
.sliderContainer {
    background-size: cover;
    border-radius: 32px;
    max-width: 1290px;
    height: 400px;
    background-repeat: no-repeat;
}
.text-color{
    color: #0a58ca;
}
.text-color:hover{
    color: black;
}
.container{
    box-shadow: unset;
    padding: 0;
    max-width: 1290px;
}
@media (min-width: 768px) and (max-width: 1024px) {
    .sliderContainer {
        width: 690px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 32px;
    }
}
</style>
