<template>
    <!-- REJECTED -->
    <section class="section section--top--space section--bottom--space">
        <div class="container container-1170">
            <div>
                <div class="text-center">
                    <img :src="reviewImg" alt="" />
                    <h2>{{ $t('enroll.reviewing') }}</h2>
                    <div class="mb-16">{{ $t('enroll.body2') }}</div>
                    <div>{{ $t('enroll.body') }}</div>
                </div>

                <div>
                    <div class="position-relative general-card general-card-970 mb-32 d-flex justify-content-between">
                        <div class="flex-item">
                            <label>{{ $t('enroll.appId') }}:</label>
                            <input
                                @keyup="checkForActivaite()"
                                placeholder="ID"
                                type="text"
                                class="w-100"
                                v-model="applicationId"
                            />
                        </div>
                        <div class="flex-item">
                            <label>{{ $t('enroll.eligibilityCheckId') }}:</label>
                            <input
                                @keyup="checkForActivaite()"
                                placeholder="ID"
                                type="text"
                                class="w-100"
                                v-model="eligibilityCheckId"
                            />
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <button
                        @click="redirect"
                        :disabled="loading"
                        :class="[
                            checkActivation
                                ? 'btnBackgroundGray btn-round btn-green btnBackgroundZero'
                                : 'btn-round btn-green btnBackgroundZero',
                        ]"
                    >
                      <Loader v-if="loading" color="white" borderRadius="20" />
                        {{ $t('enroll.check') }}
                    </button>
                </div>
                <div class="text-center">
                    <div class="text-error">{{ error }}</div>
                </div>
            </div>
            <div class="section-activeSimCard-small-background"></div>
            <div class="section-activeSimCard-middle-background"></div>
        </div>
    </section>
</template>

<script>
import eligibilityService from '../../../common/services/checkEligibility'

import { checkString } from '../../validation/value'
import checkValidation from '../../validation/checkValidation'

import {
  saveApplicationId,
  getApplicationId,
  saveEligibilityCheckId,
  getEligibilityCheckId
} from '@/common/eligibility.service'
import { removeUserData } from '@/eligibility-enroll/nlad'
import { PENDING_RESOLUTION_HREF, WAITING_RESULT } from '@/eligibility-enroll/status'
import Loader from '@/components/helpers/Loader.vue'

export default {
    components: { Loader },
    methods: {
        checkForActivaite() {
          this.error = ""
          const validation = checkString(this.applicationId) || checkString(this.eligibilityCheckId)
          checkValidation(validation) === true ? (this.checkActivation = false) : (this.checkActivation = true)
        },
        redirect() {
          this.error = ""
          const validation = checkString(this.applicationId) || checkString(this.eligibilityCheckId)
          validation ? this.success() : (this.error = 'One of the field should not be empty')
        },
        async success() {
          this.loading = true
          const resultData = await eligibilityService.postEligibilityCheck(this.applicationId, this.eligibilityCheckId)
          this.loading = false

          const appId = resultData?.data?.applicationId
          appId && saveApplicationId(appId)

          if (PENDING_RESOLUTION_HREF.includes(resultData.data.status)) {
            window.location.href = String(resultData.data._links.resolution.href)
            return
          }
          if (resultData.data.status === 'PENDING_CERT') {
            window.location.href = String(resultData.data._links.certification.href)
            return
          }
          if (WAITING_RESULT.includes(resultData.data.status)) {
            const eligibilityCheckId = resultData?.data?.eligibilityCheckId
            eligibilityCheckId && saveEligibilityCheckId(eligibilityCheckId)
            this.$router.push('/Reviewing')
            return
          }
          if (resultData.data.status === 'COMPLETE') {
            this.$router.push('/EnrollStep')
            return
          }
          if (resultData.data?.errors?.length > 0) {
            this.error = resultData.data.errors.map(item => (item.message || item.description)).join('. ')
            return
          }
          this.error = resultData.data.message
        },
    },
    created() {
      removeUserData()
    },
    data() {
        return {
            error: "",
            checkActivation: true,
            eligibilityCheckId: getEligibilityCheckId(),
            applicationId: getApplicationId(),
            reviewImg: require('../../../assets/images/Reviewing.svg'),
            closeImg: require('../../../assets/images/Close.svg'),
            loading: false
        }
    },
}
</script>

<style scoped>
.tablet-table {
  margin-top: 40px;
}

.tablet-table tr {
  text-align: left;
}

.tablet-table td {
  padding: 20px;
}

.section-tablet-table {
  color: #fff;
  width: 100%;
}

.section-tablet-table td {
  border-bottom: 1px solid #475569;
  padding-top: 16px;
  padding-bottom: 16px;
}

.section-tablet-black {
  background: #000;
  padding-top: 38px;
  padding-left: 57px;
  padding-bottom: 53px;
  padding-right: 40px;
  margin-bottom: 144px;
}

.section-tablet-h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-transform: capitalize;
  color: #ffffff;
}

.section-tablet-h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -1.1px;
  color: #ffffff;
}

</style>
