<template>
  <section class="section section-profileTitle">
    <div class="container container-1170">
      <h2>Check <span class="main-green-color">Eligibility</span></h2>
    </div>
  </section>
  <section class="section section-profile pb-80">
    <div class="container container-1170">
      <div class="row">
        <div class="col-md-3">
          <ProflieBreadcrumbs :stepNum="profileStep"/>
        </div>
        <div class="col-md-8 col general-card-wrapper">
          <div class="general-card">
            <div class="mb-24">
              <h4>Contact Info</h4>
            </div>
            <div class="row  mb-32">
              <div class=" col-lg-6 col-sm-6 col-12">
                <label>Phone Number <span class="label-required">*</span></label>
                <input
                    placeholder="Enter Phone Number"
                    type="text"
                    @keypress="onlyNumberKey($event)"
                    class="w-95 mr-16 max-w-297"
                    maxlength="10"
                    v-model="contactPhoneNumber"
                    @input="validate('contactPhoneNumber')"
                />
                <div class="small-error">
                  {{ errorGlobal.contactPhoneNumber }}
                </div>
              </div>
              <div class="col-lg-6 col-sm-6 col-12 mt-sm-0 mt-lg-0 mt-32">
                <label>Email <span class="label-required">*</span></label>
                <input
                    v-model="contactEmail"
                    :class="{ 'error-border': errorGlobal.contactEmail }"
                    placeholder="Enter Email Address"
                    type="email"
                    style="height: 36px"
                    class="w-95 max-w-297"
                    @input="validate('contactEmail')"
                />
                <div class="small-error">
                  {{ errorGlobal.contactEmail }}
                </div>
              </div>
            </div>
            <div>
              <p>
                To receive important notifications regarding your application, we encourage you to use
                a phone number that can receive text messages. This should not be a landline number.
              </p>
            </div>
            <div class="d-flex mb-32">
              <div>
                <input v-model="scales" class="mr-8" type="checkbox" name="scales"/>
              </div>
              <div>
                <label>I consent to being contacted via email, phone, text message, and mail.</label>
              </div>
            </div>
            <div class="mb-32 ">
              <label>Address <span class="label-required">*</span></label>
              <input
                  @input="validate('address')"
                  v-model="address"
                  placeholder="Address"
                  type="text"
                  class="w-95 max-w-297"
              />
              <div class="small-error">
                {{ errorGlobal.address }}
              </div>
            </div>

            <div class="row mb-32">
              <div class="flex-grow-1 mr-16 col-lg-6 col-sm-6 col-12 ">
                <CustomDropdown
                    class="w-95 contact-info-dropdown max-w-297"
                    :required="true"
                    label="State"
                    placeholder="State"
                    :list="states"
                    :showAll="true"
                    v-model="state"
                />
              </div>
              <div class="flex-grow-1 col-lg-6 col-sm-6 col-12   mt-32">
                <label>City <span class="label-required">*</span></label>
                <input
                    @input="validate('city')"
                    placeholder="City"
                    type="text"
                    class="w-95 max-w-297"
                    v-model="city"
                />
                <div class="small-error">
                  {{ errorGlobal.city }}
                </div>
              </div>
            </div>

            <div class="mb-32">
              <label>Zip Code <span class="label-required">*</span></label>
              <input
                  @keypress="onlyNumberKey($event)"
                  placeholder="Zip Code"
                  type="text"
                  class="w-95 max-w-297"
                  maxlength="5"
                  v-model="zipCode"
                  @input="validate('zipCode')"
              />
              <div class="small-error">
                {{ errorGlobal.zipCode }}
              </div>
            </div>

            <div class="mb-16">
              <p>
                To verify your application, your provided address must match the address listed on your ID.
                If your current address does not match the address on your ID, additional address proof documents are
                required.
              </p>
            </div>

            <div class="d-flex">
              <div>
                <input class="mr-8" type="checkbox" name="showHide" v-model="showHide" @change="clearError"/>
              </div>
              <div>
                <label>Mailing address is the same as the above address.</label>
              </div>
            </div>
            <div class="d-flex mb-32">
              <div>
                <input class="mr-8" type="checkbox" name="scales"/>
                <div class="small-error">
                  {{ errorGlobal.scales }}
                </div>
              </div>
              <div>
                <label>Check the box if this is a temporary address.</label>
              </div>
            </div>
            <div v-if="!showHide">
              <h2 claas="mb-15" style="font-weight:600;font-size:24px;line-height:32px">Contact information</h2>
              <div class="row mb-16">
                <div class="flex-grow-1 col-lg-6 col-sm-6 col-12">
                  <label>Address <span class="label-required">*</span></label>
                  <input
                      @input="validate('contactAddress')"
                      placeholder="Address"
                      type="text"
                      class="w-95 max-w-297"
                      v-model="contactAddress"
                  />
                  <div class="small-error">
                    {{ errorGlobal.contactAddress }}
                  </div>
                </div>
                <div class="flex-grow-1 mt-16 mt-sm-0 col-lg-6 col-sm-6 col-12">
                  <label>City <span class="label-required">*</span></label>
                  <input
                      @input="validate('contactCity')"
                      placeholder="City"
                      type="text"
                      class="w-95 max-w-297"
                      v-model="contactCity"
                  />
                  <div class="small-error">
                    {{ errorGlobal.contactCity }}
                  </div>
                </div>
              </div>
              <div class=" d-sm-flex mb-16">
                <div class="flex-grow-1 mr-16 col-lg-6 col-sm-6 col-12">
                  <CustomDropdown
                      :required="true"
                      label="State"
                      placeholder="State"
                      :list="states"
                      v-model="contactState"
                      :modelValue="contactState"
                      class="w-95 max-w-297"
                  />
                  <div class="small-error">
                    {{ errorGlobal.contactState }}
                  </div>
                </div>
                <div class="flex-grow-1 mt-16 mt-sm-0 col-lg-6 col-sm-6 col-12">
                  <label>Zip Code <span class="label-required">*</span></label>
                  <input
                      @keypress="onlyNumberKey($event)"
                      placeholder="Zip Code"
                      type="text"
                      class="w-95 max-w-297"
                      maxlength="5"
                      v-model="contactZipCode"
                      @input="validate('contactZipCode')"
                  />
                  <div class="small-error">
                    {{ errorGlobal.contactZipCode }}
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between mt-32">
              <router-link to="/PersonalInfoPage?profileStep=1" class="btn-round btn-black-ghost"
              >Back
              </router-link
              >
              <button
                  :class="[disableBtn === true ? 'btn-round btn-green btn-disable' : 'btn-round btn-green']"
                  @click="finilizeStep()"
              >
                Continue
              </button>
            </div>
            <div class="text-error text-right">{{ error }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CustomDropdown from '@/components/helpers/CustomDropdown.vue'
import ProflieBreadcrumbs from '@/components/helpers/ProfileBreadcrumbs.vue'
import { checkString } from '@/components/validation/value'
import checkValidation from '@/components/validation/checkValidation'
import checkCheckbox from '@/components/validation/checkCheckbBox'
import checkEmail from '@/components/validation/checkEmail'
import { shortCodeStatesList, statesList } from '@/common/constants/states'

export default {
  created() {
    checkEmail(this.contactEmail) === false ? (this.disableBtn = true) : (this.disableBtn = false)
    if (this.$store.state.PersonalStore?.personalData?.firstName.length < 1) {
      this.$router.push('/ZipCodePage')
    }
  },
  methods: {
    clearError() {
      this.error = ''
      this.errorGlobal.contactEmail = ''
      this.errorGlobal.scales = ''
      this.errorGlobal.address = ''
      this.errorGlobal.state = ''
      this.errorGlobal.city = ''
      this.errorGlobal.zipCode = ''
      this.errorGlobal.contactAddress = ''
      this.errorGlobal.contactCity = ''
      this.errorGlobal.contactState = ''
      this.errorGlobal.contactZipCode = ''
    },
    formatPhoneNumber(event) {
      this.rawPhoneNumber = event.target.value
      this.contactPhoneNumber = this.formatAsPhoneNumber(this.rawPhoneNumber)
    },
    formatAsPhoneNumber(value) {
      value = value.replace(/\D/g, '')
      if (value.length > 10) {
        value = value.slice(0, 10)
      }
      value = value.slice(0, 3) + '-' + value.slice(3, 6) + '-' + value.slice(6)
      return value
    },
    savePhone(evt) {
      this.contactPhoneNumber = evt.target.value
    },
    onlyNumberKey(evt) {
      evt = evt ? evt : window.event
      let charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    findShortCodeState(inputState) {
      return shortCodeStatesList.find((item) => item.state === inputState)
    },
    findCodeState(inputState) {
      return shortCodeStatesList.find((item) => item.shortcode === inputState)
    },
    setRegisterData() {
      this.$store.dispatch('addToPersonal', ['address', this.address])

      this.$store.dispatch('addToPersonal', ['city', this.city])
      this.$store.dispatch('addToPersonal', ['contactPhoneNumber', this.contactPhoneNumber])
      this.$store.dispatch('addToPersonal', ['state', this.findShortCodeState(this.state)?.shortcode])
      this.$store.dispatch('addToPersonal', ['zipCode', this.zipCode])
      this.$store.dispatch('addToPersonal', ['contactEmail', this.contactEmail])

      if (this.showHide === true) {
        this.$store.dispatch('addToPersonal', ['contactState', this.findShortCodeState(this.state)?.shortcode])
        this.$store.dispatch('addToPersonal', ['contactAddress', this.address])
        this.$store.dispatch('addToPersonal', ['contactZipCode', this.zipCode])
        this.$store.dispatch('addToPersonal', ['contactCity', this.city])
      } else {
        this.$store.dispatch('addToPersonal', ['contactState', this.findShortCodeState(this.contactState)?.shortcode])
        this.$store.dispatch('addToPersonal', ['contactZipCode', this.contactZipCode])
        this.$store.dispatch('addToPersonal', ['contactAddress', this.contactAddress])
        this.$store.dispatch('addToPersonal', ['contactCity', this.contactCity])
      }

      this.$router.push('/HouseholdInformationPage?profileStep=3')
    },
    validate(param) {
      const validation = {
        address: checkString(this.address),
        contactEmail: checkEmail(this.contactEmail),
        contactPhoneNumber: checkString(this.contactPhoneNumber),
        city: checkString(this.city),
        scales: checkCheckbox(this.scales),
        state: checkCheckbox(this.state),
        contactState: this.showHide === false ? checkString(this.contactState) : true,
        contactAddress: this.showHide === false ? checkString(this.contactAddress) : true,
        contactZipCode: this.showHide === false ? checkString(this.contactZipCode) : true
      }

      this.contactPhoneNumber.length < 10 && param == 'contactPhoneNumber'
          ? (this.errorGlobal.contactPhoneNumber = 'Contact Phone Number Invalid')
          : (this.errorGlobal.contactPhoneNumber = '')

      checkEmail(this.contactEmail) === false && param == 'contactEmail'
          ? (this.errorGlobal.contactEmail = 'Email Invalid')
          : (this.errorGlobal.contactEmail = '')

      checkEmail(this.contactEmail) === false ? (this.disableBtn = true) : (this.disableBtn = false)

      !this.scales && param == 'scales'
          ? (this.errorGlobal.scales = 'Checkbox Invalid')
          : (this.errorGlobal.scales = '')

      this.address.length < 2 && param == 'address'
          ? (this.errorGlobal.address = 'Address Invalid')
          : (this.errorGlobal.address = '')

      !this.state && param == 'state'
          ? (this.errorGlobal.state = 'State Invalid')
          : (this.errorGlobal.state = '')

      this.city.length < 1 && param == 'city'
          ? (this.errorGlobal.city = 'City is required')
          : (this.errorGlobal.city = '')

      this.zipCode.length < 5 && param == 'zipCode'
          ? (this.errorGlobal.zipCode = 'Zip Code Invalid')
          : (this.errorGlobal.zipCode = '')

      if (!this.showHide) {
        !this.contactAddress && param == 'contactAddress'
            ? (this.errorGlobal.contactAddress = 'Address Invalid')
            : (this.errorGlobal.contactAddress = '')

        !this.contactCity && param == 'contactCity'
            ? (this.errorGlobal.contactCity = 'City is required')
            : (this.errorGlobal.contactCity = '')

        !this.contactState && param == 'contactState'
            ? (this.errorGlobal.contactState = 'State Invalid')
            : (this.errorGlobal.contactState = '')

        if (param == 'contactZipCode') {
          if (!this.contactZipCode) {
            this.errorGlobal.contactZipCode = 'Zip Code is required'
          } else if (this.contactZipCode.length < 5) {
            this.errorGlobal.contactZipCode = 'Zip Code Invalid'
          } else {
            this.errorGlobal.contactZipCode = ''
          }
        }
      }

      checkValidation(validation)
    },
    finilizeStep() {
      const validation = {
        address: checkString(this.address),
        contactEmail: checkEmail(this.contactEmail),
        contactPhoneNumber: checkString(this.contactPhoneNumber),
        city: checkString(this.city),
        scales: checkCheckbox(this.scales),
        state: checkCheckbox(this.state),
        contactState: this.showHide === false ? checkString(this.contactState) : true,
        contactAddress: this.showHide === false ? checkString(this.contactAddress) : true,
        contactZipCode: this.showHide === false ? checkString(this.contactZipCode) : true
      }

      if (this.contactPhoneNumber.length < 10) {
        this.error = 'Contact Phone Number is required'
        return
      }

      if (checkEmail(this.contactEmail) === false) {
        this.error = 'Contact Email format is wrong'
        return
      }

      if (!this.scales) {
        this.error = 'Checkbox is required'
        return
      }

      if (this.address.length < 2) {
        this.error = 'Address is required'
        return
      }
      if (this.state === undefined) {
        this.error = 'State is required'
        return
      }

      if (this.city.length < 2) {
        this.error = 'City is required'
        return
      }

      if (this.zipCode.length < 5) {
        this.error = 'Zipcode format is wrong'
        return
      }

      checkValidation(validation) === true ? this.setRegisterData() : (this.error = 'All fields are required')
    }
  },
  components: {
    ProflieBreadcrumbs,
    CustomDropdown
  },
  computed: {
    profileStep() {
      return this.$route.query.profileStep
    }
  },
  data() {
    return {
      rawPhoneNumber: '',

      address: this.$store.state.PersonalStore?.personalData?.address,
      contactEmail: this.$store.state.PersonalStore?.personalData?.contactEmail,
      contactPhoneNumber: this.$store.state.PersonalStore?.personalData?.contactPhoneNumber || '',
      contactAddress: this.$store.state.PersonalStore?.personalData?.contactAddress,
      contactCity: this.$store.state.PersonalStore?.personalData?.contactCity,
      city: this.$store.state.PersonalStore?.personalData?.city,
      contactZipCode: this.$store.state.PersonalStore?.personalData?.contactZipCode,
      zipCode: this.$store.state.PersonalStore?.personalData?.zipCode,
      state: this.findCodeState(this.$store.state.PersonalStore?.personalData?.state)?.state,
      contactState: this.findCodeState(this.$store.state.PersonalStore?.personalData?.contactState)?.state || '',
      states: statesList,

      disableBtn: true,

      scales: false,
      showHide: false,
      error: '',
      errorGlobal: {}
    }
  },
  watch: {
    state() {
    }
  }
}
</script>

<style>
.small-error {
  color: red;
  font-size: 11px;
}

.error-border {
  border-color: red !important;
}

.input-red-line-bottom {
  border-bottom: 1px solid red;
}

.input-gray-line-bottom {
  border: 1px solid #e5e7eb;
}

.contact-info-dropdown input {
  height: 36px !important;
}
</style>
<style scoped>
  @media (min-width: 768px) {
    h4 {
      font-size: 40px;
      line-height: 46px;
    }
  }
  @media (max-width: 1024px) {
    .general-card-wrapper{
      max-width: 696px;
      margin: 0 auto;
      padding: 0;
    }
    .general-card{
      max-width: inherit !important;
    }
  }
  @media (max-width: 667px) {
    h4 {
      font-size: 24px;
      line-height: 33px;
    }
  }
  @media (max-width: 767px) {
    .general-card {
      min-width: 100% !important;
      height: unset !important;
    }

    .general-card-wrapper {
      max-width: 100% !important;
      margin: 0 auto;
      padding: 10px;
    }
  }
  @media (max-width: 375px) {
    .max-w-297 {
     max-width: 297px !important;
    }
  }

</style>
