<template>
  <section class="section section-summaryPlan pb-160 mt-80">
    <div class="position-relative container container-600">
      <div class="">
        <router-link to="/EnrollStep" @click="savePersonalData" class="btn-round btn-left-arrow"> Back </router-link>
      </div>
      <h3 class="mb-32 mt-16">My Shipping Address</h3>
      <p>{{ isConfirmOrEnter ? 'Confirm' : 'Enter' }} Your Enrollment Details:</p>

      <div class="form-section-444 mb-12">
        <div class="w-full">
          <label>First Name <span class="label-required">*</span></label>
          <input
              @keypress="onlyAlhpafit($event)"
              @input="validate('firstName')"
              required
              placeholder="John"
              v-model.trim="firstName"
              maxlength="24"
              type="text"
              class="w-full"
          />
          <div class="small-error">
            {{ this.errorGlobal.firstName }}
          </div>
        </div>
        <div class="w-full">
          <label>Last Name <span class="label-required">*</span></label>
          <input
              @keypress="onlyAlhpafit($event)"
              @input="validate('lastName')"
              required
              placeholder="Doe"
              v-model.trim="lastName"
              maxlength="24"
              type="text"
              class="w-full"
          />
          <div class="small-error">
            {{ this.errorGlobal.lastName }}
          </div>
        </div>
      </div>

      <label>Date of birth <span class="label-required">*</span></label>
      <div class="form-section-444">
        <div class="w-full">
          <CustomDropdown
              placeholder="Month"
              @change="validate('monthStr')"
              :modelValue="monthStr"
              :list="[
                                          'January',
                                          'February',
                                          'March',
                                          'April',
                                          'May',
                                          'June',
                                          'July',
                                          'August',
                                          'September',
                                          'October',
                                          'November',
                                          'December',
                                      ]"
              v-model="monthStr"
          />
        </div>
        <div class="w-full">
            <CustomDropdown
                @change="validate('day')"
                placeholder="Day"
                :modelValue="day"
                :list="generateDayData()"
                v-model="day"
                class=""
            />
        </div>
        <div class="w-full">
            <CustomDropdown
                @change="validate('year')"
                placeholder="Year"
                :modelValue="year"
                :list="generatYearData()"
                v-model="year"
            />
        </div>
      </div>
      <div class="small-error mb-16">
        {{ this.errorGlobal.dob }}
      </div>
      <div class="form-section-444 gap-4 mb-12">
        <div class="w-full">
          <label>Email <span class="label-required">*</span></label>
          <input v-model.trim="email" class="w-full" type="text" placeholder="Enter email address" @input="validate('email')" />
          <div class="small-error">
            {{ this.errorGlobal.email }}
          </div>
        </div>
        <div class="w-full">
          <label>Phone Number <span class="label-required">*</span></label>
          <input
              placeholder="Enter Phone Number"
              type="number"
              class="w-full"
              v-model.trim="contactPhoneNumber"
              @input="validate('contactPhoneNumber')"
          />
          <div class="small-error">
            {{ this.errorGlobal.contactPhoneNumber }}
          </div>
        </div>
      </div>
      <div class="my-4"></div>
      <p>{{ isConfirmOrEnter ? 'Confirm' : 'Enter' }} Your Delivery Address:</p>
      <div class="form-section-444">
        <div class="w-full">
          <CustomDropdown
              :required="true"
              label="State"
              @change="validate('state')"
              placeholder="Enter your state"
              :list="states"
              v-model="state"
          />
          <div class="small-error">
            {{ this.errorGlobal.state }}
          </div>
        </div>
        <div class="w-full">
          <label>City <span class="label-required">*</span></label>
          <input v-model.trim="city" class="w-100" type="text" placeholder="Enter your city" @input="validate('city')" />
          <div class="small-error">
            {{ this.errorGlobal.city }}
          </div>
        </div>
      </div>
      <div class="mb-16 mt-12">
        <label>Address 1 <span class="label-required">*</span></label>
        <input v-model.trim="shippingAddress1" class="w-100" type="text" placeholder="Enter address 1" @input="validate('shippingAddress1')" />
        <div class="small-error">
          {{ this.errorGlobal.shippingAddress1 }}
        </div>
      </div>
      <div class="mb-16">
        <label>Address 2 </label>
        <input v-model.trim="shippingAddress2" class="w-100" type="text" placeholder="Enter address 2" />
      </div>
      <div class="form-section-444 mb-16">
        <div class="w-full">
          <label>Zip-code <span class="label-required">*</span></label>
          <input v-model.trim="zipcode" class="w-100" type="number" placeholder="Zip Code" @input="validate('zipcode')" />
          <div class="small-error">
            {{ this.errorGlobal.zipcode }}
          </div>
        </div>
        <div class="w-full"></div>
      </div>
      <div class="mt-32">
        <div v-if="error" class="text-error mb-18">
          {{ error }}
        </div>
      </div>

      <div class="mt-32">
        <button @click="confirm" class="btn-round btn-green w-276px" :disabled="loading">
          <Loader v-if="loading" color="white" borderRadius="20" />
          {{ isResubmit ? 'Resubmit' : 'Confirm' }}
        </button>
      </div>

    </div>
  </section>
</template>

<script>
import { shortCodeStatesList, statesList } from '@/common/constants/states'
import eligibilityService from '@/common/services/checkEligibility'
import { cookieGetPersonalData, getApplicationId, getTribalStatus } from '@/common/eligibility.service'
import { errorInterceptor } from '@/utilities/errorInterseptor'
import Loader from '@/components/helpers/Loader.vue'
import utility from '@/common/services/utility'
import CustomDropdown from '@/components/helpers/CustomDropdown.vue'
import { generatYear, getDaysInMonth } from '@/components/generation/date'
import checkEmail from '@/components/validation/checkEmail'
import { appIdIsExist, ERROR_TIMEOUT, errorNlad } from '@/eligibility-enroll/nlad'
import enrollConfig from '@/eligibility-enroll/enroll.config'
import { format } from 'date-fns'
import { useToast } from 'vue-toastification'

const monthList = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const monthNumber = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
}

export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  components: {
    CustomDropdown,
    Loader,
  },
  mounted() {

  },
  methods: {
    validate(param) {
      if (param === 'firstName' || param === 'all') {
        if (!this.firstName) {
          this.errorGlobal.firstName = 'First name is required'
          return false
        } else {
          this.errorGlobal.firstName = ''
        }
      }
      if (param === 'lastName' || param === 'all') {
        if (!this.lastName) {
          this.errorGlobal.lastName = 'Last name is required'
          return false
        } else {
          this.errorGlobal.lastName = ''
        }
      }
      if (['day', 'monthStr', 'year'].includes(param) || param === 'all') {
        if (!this.day || !this.monthStr || !this.year) {
          this.errorGlobal.dob = 'DOB is required'
          return false
        } else {
          this.errorGlobal.dob = ''
        }
      }
      if (param === 'email' || param === 'all') {
        if (!this.email) {
          this.errorGlobal.email = 'Email is required'
          return false
        } else if (checkEmail(this.email) === false) {
          this.errorGlobal.email = 'Email Invalid'
          return false
        } else {
          this.errorGlobal.email = ''
        }
      }
      if (param === 'contactPhoneNumber' || param === 'all') {
        const cphn = this.contactPhoneNumber.toString()
        const l = cphn.length
        if (l < 1) {
          this.errorGlobal.contactPhoneNumber = 'Phone Number is required'
          return false
        } else if (cphn[0] === '0' || l !== 10) {
          this.errorGlobal.contactPhoneNumber = 'Phone Number Invalid'
          return false
        } else {
          this.errorGlobal.contactPhoneNumber = ''
        }
      }
      if (param === 'state' || param === 'all') {
        if (!this.state) {
          this.errorGlobal.state = 'State is required'
          return false
        } else {
          this.errorGlobal.state = ''
        }
      }
      if (param === 'city' || param === 'all') {
        if (!this.city) {
          this.errorGlobal.city = 'City is required'
          return false
        } else {
          this.errorGlobal.city = ''
        }
      }
      if (param === 'shippingAddress1' || param === 'all') {
        if (!this.shippingAddress1) {
          this.errorGlobal.shippingAddress1 = 'Shipping Address is required'
          return false
        } else if (this.shippingAddress1.length < 2) {
          this.errorGlobal.shippingAddress1 = 'Shipping Address Invalid'
          return false
        } else {
          this.errorGlobal.shippingAddress1 = ''
        }
      }
      if (param === 'zipcode' || param === 'all') {
        if (!this.zipcode) {
          this.errorGlobal.zipcode = 'ZipCode is required'
          return false
        } else if (this.zipcode.toString().length !== 5) {
          this.errorGlobal.zipcode = 'ZipCode should be 5 digits'
          return false
        } else {
          this.errorGlobal.zipcode = ''
        }
      }
      this.error = ""
      return true
    },
    zeroFill(i) {
      return (i < 10 ? '0' : '') + i
    },
    zeroRemoveFill(i) {
      return i[0] === 0 ? i[1] : i
    },
    parseBOD(period, value) {
      if (value.length < 2 && value === undefined) return

      const splitValue = value.split('/')

      if (period === 'year') return splitValue[0]
      if (period === 'month') return splitValue[1]
      if (period === 'day') return splitValue[2]
    },
    getCurrrentMonthNumber() {
      const numberMonth =
          +(String(this.parseBOD('month', this.$store.state.PersonalStore?.personalDataSave?.dob))[0] == '0'
              ? +this.parseBOD('month', this.$store.state.PersonalStore?.personalDataSave?.dob)[1] - 1
              : +this.parseBOD('month', this.$store.state.PersonalStore?.personalDataSave?.dob)) - 1 || ''
      return numberMonth < 0 ? 0 : numberMonth
    },
    generatYearData() {
      return generatYear()
    },
    generateDayData() {
      return getDaysInMonth(this.month, this.year)
    },
    onlyAlhpafit(e) {
      var regex = new RegExp('^[a-zA-Z.-]+$')
      var str = String.fromCharCode(!e.charCode ? e.which : e.charCode)
      if (regex.test(str)) {
        return true
      }

      e.preventDefault()
      return false
    },
    findShortCodeState(inputState) {
      return shortCodeStatesList.find((item) => item.state === inputState)
    },
    findCodeState(inputState) {
      return shortCodeStatesList.find((item) => item.shortcode === inputState)
    },
    savePersonalData() {
      const month = monthNumber[this.monthStr]
      const dob = this.zeroFill(month) + '/' + this.zeroFill(this.day) + '/' + this.year
      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        emailAddress: this.email,
        contactPhoneNumber: String(this.contactPhoneNumber),
        shippingAddress1: this.shippingAddress1,
        shippingAddress2: this.shippingAddress2 || "",
        shippingCity: this.city,
        shippingState: this.findShortCodeState(this.state)?.shortcode,
        state: this.findShortCodeState(this.state)?.shortcode,
        shippingZipcode: String(this.zipcode),
        dob,
        shippingCountry: enrollConfig?.shippingCountry || "US",
        applicationId: getApplicationId(),
        ebbpTribalBenefitFlag: getTribalStatus() === 'Y' ? "1" : "0"
      }
      this.$store.commit('SET_personalDataSave', payload)
      return payload
    },
    async confirm() {
      this.error = ""
      const check = this.validate('all')
      if (!check) {
        this.error = 'All field with * are required'
        return
      }
      const payload = this.savePersonalData()
      this.loading = true
      const resellerCode = await utility.isValidResellerCode()
      if (resellerCode) {
        payload["resellerCode"] = resellerCode
      }
      this.loading = false
      const enrollData = {}
      if (!this.isTransffer) {
        enrollData['acpCertInd'] = "1"
      }
      const deviceId = localStorage.getItem("tabletId")
      if (deviceId && !this.isTransffer) {
        enrollData['deviceId'] = deviceId
        enrollData['paymentGateway'] = enrollConfig?.paymentGateway || "stripe"
        enrollData['consumerFee'] = enrollConfig?.consumerFee
        enrollData['deviceCopay'] = enrollConfig?.deviceCopay
        enrollData['marketValue'] = enrollConfig?.marketValue
        enrollData['deviceDeliveryMethod'] = enrollConfig?.deviceDeliveryMethod
        const _date = new Date(new Date().getTime() + (1000 * 60 * 60 * 24) * (+enrollConfig?.deviceReimbursementDate || 15))
        enrollData['deviceReimbursementDate'] = format(_date, 'MM/dd/yyyy')
      }
      await this.submit({ ...enrollData, ...payload }, payload)
    },
    async submit(enrollData, enrollDataWithoutDevice) {
      this.loading = true
      try {
        const response = await eligibilityService[!this.isTransffer ? 'postSubscriberAcpEnroll' : 'acpTransferPlan'](enrollData)
        const e = errorNlad(response, enrollDataWithoutDevice)
        this.isResubmit = (e === ERROR_TIMEOUT)
        e && this.toast.error(e)
      } catch (error) {
        const e = errorInterceptor(error)
        this.toast.error(e)
      } finally {
        this.loading = false
      }
    }
  },
  computed: {
    personalData() {
      return this.$store.state.PersonalStore.personalDataSave
    }
  },
  created() {
    if (!appIdIsExist()) {
      return
    }
    const _pd = cookieGetPersonalData()
    const _personalData = {
      ...this.personalData,
      ..._pd
    }
    this.isConfirmOrEnter = Object.keys(this.personalData).every(key => !!this.personalData[key] || key === 'shippingAddress2')
    this.isTransffer = this.$route.query?.transfer === 'yes'
    const dob = _personalData.dob.split("/")
    this.email = _personalData.emailAddress
    this.contactPhoneNumber = _personalData.contactPhoneNumber
    this.shippingAddress1 = _personalData.shippingAddress1
    this.shippingAddress2 = _personalData.shippingAddress2
    this.city = _personalData.shippingCity
    this.zipcode = _personalData.shippingZipcode
    this.state = this.findCodeState(_personalData.shippingState)?.state
    this.firstName = _personalData.firstName
    this.lastName = _personalData.lastName
    this.day = dob.length === 3 ? +dob[1] : ""
    this.monthStr = dob.length === 3 ? monthList[+dob[0]] : ""
    this.year = dob.length === 3 ? +dob[2] : ""
  },
  data() {
    return {
      email: '',
      contactPhoneNumber: '',
      shippingAddress1: '',
      shippingAddress2: '',
      city: '',
      zipcode: '',
      state: '',
      errorType: '',
      states: statesList,
      error: "",
      loading: false,
      firstName: "",
      lastName: "",
      day: "",
      monthStr: "",
      month: "",
      year: "",
      errorGlobal: {},
      isTransffer: false,
      isResubmit: false,
      isConfirmOrEnter: false
    }
  },
}
</script>

<style>
.form-section-444 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
}
@media screen and (max-width: 444px) {
  .form-section-444 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px
  }
}
.leftSliderLogin h3 {
  font-size: 24px;
}

.leftSliderLogin input {
  height: 36px;
}
</style>
