<template>
    <section>
        <div class="container">
            <div class="row">
                <div class=" col-md-6">
                    <div class="header-w">
                        <h2 class="mb-32">What is the Affordable Connectivity Program?</h2>
                        <p class="header-p-font-family">
                            The Affordable Connectivity Program (ACP) is a U.S. government program run by the
                            <span class="emphasis">Federal Communications Commission (FCC)</span>
                            to help low-income households pay for internet service and connected devices like a laptop
                            or tablet.
                        </p>
                        <p >
                            You are likely eligible if your household’s income is below 200% of the Federal Poverty
                            Line, or if you or someone you live with currently receives a government benefit like SNAP,
                            Medicaid, SSI, WIC, Pell Grant, or Free and Reduced-Price Lunch.
                        </p>
                        <p>If your household is eligible, you could receive:</p>
                        <ul>
                            <li>Up to a $30/month discount on your internet service</li>
                            <li>Up to a $75/month discount if your household is on qualifying Tribal lands</li>
                            <li>
                                A one-time discount of up to $100 for a laptop, tablet, or desktop computer (with a
                                co-payment of more than $10 but less than $50)
                            </li>
                            <li>A low cost service plan that may be fully covered through the ACP*</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img class="img-size" :src="afordableImage" alt="" />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    components: {},
    methods: {},
    data() {
        return {
            afordableImage: require('../../../../assets/images/pages/AfordablePage/afordableImage.svg'),
        }
    },
}
</script>
<style>
@import '../../../../assets/styles/components/sections/SinglePages/AboutACPPage/02_Afordable.css';
</style>
<style scoped>
.img-size {
    width: 630px;
    height: 520px;
}
.container{
    box-shadow: unset;
}
/*.header-p-font-family{
    font-family: Raleway;
}*/
p , li{
    line-height: 22.4px;
    color: #0f172a;
    font-family: Roboto;
}
@media (min-width: 768px) {
    .img-size {
        width: 690px;
    }
    .header-w{
        max-width: 630px;
        
    }
}
@media (min-width: 1200px) {
    .container {
       padding: 0;
    }
}
@media (max-width: 576px) {
    h2 {
        font-weight: 500;
        font-size: 30px;
        line-height: 35.22px;
    }
    .container{
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
    }
}
@media (max-width: 375px) {
    .img-size {
        width: 343px !important;
        object-fit: none;
        border-radius: 32px;
        height: 400px !important;
    }
}
</style>
