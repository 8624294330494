footer<template>
    <footer class="footer">
        <div class="container">
            <div class="row px-sm-3" style="width: unset !important">
                <div class="col-md-1 col-sm-2">
                    <router-link to="/">
                        <img :src="footerLogo" class="footerLogo" alt="" />
                    </router-link>
                </div>
                <div class="col-md-8 col-sm-5">
                    <ul class="footer-menu display-flex">
                        <li><router-link class="text-line-height" to="/">Home</router-link></li>
                        <li>
                            <router-link class="text-line-height white-space" to="/AboutACPPage">About ACP</router-link>
                        </li>
                        <li>
                            <router-link class="text-line-height white-space" to="/ContactUsPage"
                                >Contact us</router-link
                            >
                        </li>
                        <li><router-link class="text-line-height" to="/ResourcesPage">Resources</router-link></li>
                      <li class="text-line-width-check"><router-link class=" text-line-height white-space" to="/CheckEnrollStatusPage">Check Application Status</router-link></li>
                    </ul>
                </div>
                <div class="col-md-3 col-sm-5  mobile-class">
                    <div class="white-color mobile-class margin-left ghost-btn h-46 mt-57 w-208 float-right">
                        <router-link class="link white-space white-color" to="/ZipCodePage"
                            >Validate your eligibility</router-link
                        >
                    </div>
                </div>
            </div>
            <div class="copyright">
                <div class="d-flex justify-content-between">
                    <div class="footer-menu-footer-text">© Copyright <span class="span-font-family-Robot">2023</span> ,
                        All Rights Reserved by Affordable Connectivity Program</div>
                    <router-link to="/" active-class="" class=" text-line-height-policy text-line-height white-space"
                    >Privacy policy</router-link>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    data() {
        return {
            footerLogo: require('../../assets/images/components/header/logo.svg'),
        }
    },
}
</script>
<style>
@import '../../assets/styles/components/layouts/Footer.css';
</style>
<style scoped>
    .justify-content-between  a {
        color: white;
        text-decoration: blink
    }
    .footer-menu li a {
      padding-left: 80px;
    }
    .container{
        max-width: 100% !important;
    }
    .span-font-family-Robot{
        font-weight: 400;
        font-family: Roboto;
    }
@media (min-width: 1231px) {
    .footer{
        height: 228px !important;
    }
}
@media (min-width: 768px) {
    .display-flex {
        display: flex;
    }
    .copyright {
        margin-top: 32px;
    }
}
@media (max-width: 1400px) {
    .footer-menu li a {
        padding-left: 40px;
    }
}
@media (max-width: 1230px) {
    .footer-menu {
        display: block;
    }
    .footer-menu li {
        margin-bottom: 32px;
    }
    .footer-menu li a {
        padding-left: 106px;
    }
}
    @media (max-width: 1200px){
        .footer-menu li {
            margin-bottom: 10px;
        }
    }
    @media (max-width: 1200px){
        .footer-menu li {
            margin-bottom: 10px;
        }
    }
    @media (min-width: 768px) and (max-width: 800px) {
        .footer-menu li{
            margin-bottom: 10px;
        }
    }
@media (max-width: 768px) {
    .copyright {
        margin-top: 0;
        padding-top: 20px;
    }
    .container {
        height: 372px;
    }
}
.text-line-height {
    font-size: 16px;
    line-height: 22px;
}
.footer {
    height: 372px;
}

@media (max-width: 570px) {
    .footer {
        height: 460px;
    }
    .copyright {
        margin-top: 32px;
        padding-top: 32px;
    }
    .margin-left{
        margin-left: 20px !important;
    }
}
@media (max-width: 576px) {
    .row > * {
        width: unset !important;
    }
    .mobile-class {
        position: relative;
        margin-top: -61px;
    }
}
@media (min-width: 375px) and (max-width:500px ) {
    .footer {
        height: 460px;
    }
    .footer-menu{
        display: unset !important;
    }
    .row > *{
        padding-left: unset;
        padding-right: unset;
        margin-left: -15px;
    }
    .footerLogo{
        margin-left: 40px;
    }
    .ml-20{
        margin-left: 20px;
    }
    .row{
        max-width: 375px;
    }
    .mobile-class{
        width: 180px;
        padding: 12px 5px;
        margin-left: 5px;
        margin-top: -40px;
    }
    .footer-menu-footer-text{
        margin-left: 30px;
        font-family: Roboto;
    }
    .text-line-height-policy{
        margin-right: 40px;
        margin-left: 20px;
    }
    .container{
        display: contents;
    }
    .text-line-width-check a{
        max-width: 260px;
        display: block;
        white-space: normal;
    }
    ul{
        position: relative;
        left: 20px;
        text-align: end;
    }
}    
.white-space {
    white-space: nowrap;
}
</style>
