<template>
    <section class="afordableSection">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="text-left mb-32">
                        <h2>What is the Affordable Connectivity Program?</h2>
                    </div>
                    <p>
                        The Affordable Connectivity Program (ACP) is a U.S. government program run by the
                        <span class="emphasis">Federal Communications Commission (FCC)</span>
                        to help low-income households pay for internet service and connected devices like a laptop or
                        tablet.
                    </p>
                    <p>
                        You are likely eligible if your household’s income is below 200% of the Federal Poverty Line, or
                        if you or someone you live with currently receives a government benefit like SNAP, Medicaid,
                        SSI, WIC, Pell Grant, or Free and Reduced-Price Lunch.
                    </p>
                    <p class="top-paragraf">If your household is eligible, you could receive:</p>
                    <ul class="afordableSection--list">
                        <li>Up to a $30/month discount on your internet service</li>
                        <li>Up to a $75/month discount if your household is on qualifying Tribal lands</li>
                        <li>
                            A one-time discount of up to $100 for a laptop, tablet, or desktop computer (with a
                            co-payment of more than $10 but less than $50)
                        </li>
                        <li>A low cost service plan that may be fully covered through the ACP*</li>
                    </ul>
                </div>
                <div class="col-md-6 col-sm-12">
                    <img :src="videoImage" class="img-fluid" alt="" />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    components: {},
    methods: {},
    data() {
        return {
            videoImage: require('../../../assets/images/sections/HomePage/02_Afordable/videoImage.svg'),
        }
    },
}
</script>
<style>
@import '../../../assets/styles/components/sections/HomePage/02_Afordable.css';
</style>
<style scoped>
    .afordableSection--list li ,top-paragraf{
        font-family: Roboto;
        font-weight: 400;
        line-height: 22.4px;
        font-size: 16px;
    }
    .container{
        box-shadow: unset;
    }
    @media (min-width: 1024px) {
        .img-fluid{
            max-width: unset;
            width: 630px;
            height: 520px;
        }
    }
    @media (min-width: 768px) and (max-width: 800px) {
        .img-fluid{
            max-width: unset;
            width: 690px;
            height: 502px;
        }
    }
    @media (max-width: 400px) {
        .img-fluid{
            width: 343px;
            height: 400px;
            object-fit: none;
            border-radius: 36px;
        }
        h2{
            font-weight: 500;
            font-size: 30px;
            line-height: 35px;
        }
    }

</style>
