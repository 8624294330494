<template>
  <div class="font-inter">
    <label v-if="$props.label"> {{ label }} <span v-if="required" class="label-required">*</span></label>
    <div class="position-relatve">
      <div :class="[IsDropdownListActive == false ? 'dropdown-text' : 'dropdown-text dropdown-input-text--open']">
        <div class="position-relatve">
          <input
              autoComplete="new-password"
              @keyup="inputText($event)"
              @click="showDropdownList()"
              :type="$props.type"
              v-model="itemValueSelected"
              :value="inputValue || itemValueSelected || modelValue"
              class="input-dropdown-text"
              :placeholder="placeholder"
          />
          <div :style="{height: !computedList.length ? '36px' : '253px' }" :class="[IsDropdownListActive == false ? 'd-none' : 'dropdown-list']" style="z-index: 100">
            <template v-if="!computedList.length">
              <div style="text-align: center"> No Data Found </div>
            </template>
            <template v-else v-for="(name, index) in computedList" :key="index">
              <div style="height: auto" @click="clickDropdown(name.text ? name.text : name)">
                {{  name.text ? name.text : name }}
              </div>
            </template>

          </div>
          <div v-if="IsDropdownListActive" class="dropdown-list-background" @click="showDropdownList"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    'list':{},
    'placeholder':{},
    'label':{},
    'required':{},
    'modelValue':{},
    'showAll':{},
    'type': {
      type: String,
      default: 'text'
    }
  },
  emits: ['update:modelValue', 'change'],
  methods: {
    clickDropdown(name) {
      this.selectItem(name)
      this.$emit('update:modelValue', this.itemValueSelected)
      this.$emit('change')
    },
    selectItem(itemValueSelected) {
      this.itemValueSelected = itemValueSelected
      this.inputValue = itemValueSelected
      this.IsDropdownListActive = false
    },
    showDropdownList() {
      this.IsDropdownListActive = !this.IsDropdownListActive
      if (this.IsDropdownListActive) return
      this.itemValueSelected = this.modelValue
      this.inputValue = this.modelValue
    },
    inputText(e) {
      this.inputValue = e.target.value
    },
  },
  data() {
    return {
      IsDropdownListActive: false,
      itemValueSelected: '',
      inputValue: '',
    }
  },
  watch: {
    inputValue(val) {
      if (val) return
      this.itemValueSelected = ''
      this.$emit('update:modelValue', '')
      this.$emit('change')
    },
  },
  computed: {
    computedList() {
      if (!this.list.length || !this.inputValue || this.showAll) return this.list
      return this.list/*.filter(
          (e) => e && e.toString().toLowerCase().includes(this.inputValue.toString().toLowerCase())
      )*/
    },
  },
}
</script>

<style>
@import './../../assets/styles/helpers/dropdown.css';
</style>
