import { createRouter, createWebHistory } from 'vue-router'
import { saveResellerCode } from '@/common/storageHelper'

import HomePage from './components/pages/HomePage.vue'
import AboutACPPage from './components/pages/SinglePages/AboutACPPage.vue'
import ContactUsPage from './components/pages/SinglePages/ContactUsPage.vue'
import ResourcesPage from './components/pages/SinglePages/ResourcesPage.vue'
import LatestBlogPage from './components/pages/LatestBlogPage.vue'
import BlogPage from './components/pages/BlogPage.vue'
import RegistrationPage from './components/pages/RegisterFlow/RegistrationPage.vue'

import VerificationPage from './components/pages/RegisterFlow/VerificationPage.vue'
import QualityPlanPage from './components/pages/RegisterFlow/QualityPlanPage.vue'
import QualityACPPage from './components/pages/RegisterFlow/QualityACPPage.vue'
import NotQualifedPage from './components/pages/RegisterFlow/NotQualifedPage.vue'
import ShippigDetailsPage from './components/pages/RegisterFlow/ShippigDetailsPage.vue'
import EnrollmentResultPage from './components/pages/RegisterFlow/EnrollmentResultPage.vue'
import ConfirmProgrammPage from './components/pages/EligibilityFlow/ConfirmProgrammPage.vue'
import HomeAdressPage from './components/pages/EligibilityFlow/HomeAdressPage.vue'
import PersonalInformationPage from './components/pages/EligibilityFlow/PersonalInformationPage.vue'
import QuestionPage from './components/pages/EligibilityFlow/QuestionPage.vue'
import ConfirmYourProgram from './components/pages/EligibilityFlow/ConfirmYourProgram.vue'
import ReviewPage from './components/pages/EligibilityFlow/ReviewPage.vue'
import ContactInformation from './components/pages/EligibilityFlow/ContactInformation.vue'

// eligibility-enroll parts
// import EnterZipCodePage from './components/pages/EligibilityFlow/EnterZipCodePage.vue'
import EnterZipCodePage from './components/pages/eligibility-enroll/EnterZipCodePage.vue'
import SorryZipCodePage from './components/pages/eligibility-enroll/SorryZipCodePage.vue'
import SuccessZipCodePage from './components/pages/eligibility-enroll/SuccessZipCodePage.vue'
import PersonalInfoPage from './components/pages/eligibility-enroll/profile-steps/01_PersonalnfoPage.vue'
import ContactInfoPage from './components/pages/eligibility-enroll/profile-steps/02_ContactInfoPage.vue'
import HouseholdInformationPage from './components/pages/eligibility-enroll/profile-steps/03_HouseholdInformationPage.vue'
import QualificationPage from './components/pages/eligibility-enroll/profile-steps/04_EligibilityPage.vue'
import TribalStatusPage from './components/pages/eligibility-enroll/profile-steps/04_1_TribalStatus.vue'
import VerifyPage from './components/pages/eligibility-enroll/profile-steps/05_VerifyPage.vue'
import AgreementPage from './components/pages/eligibility-enroll/profile-steps/06_AgreementPage.vue'
import Reviewing from './components/pages/eligibility-enroll/Reviewing.vue'
import CheckEnrollStatusPage from './components/pages/eligibility-enroll/CheckEnrollStatusPage.vue'
import EnrollStep from '@/components/pages/eligibility-enroll/enroll/EnrollStep.vue'
import ConfirmAddress from '@/components/pages/eligibility-enroll/enroll/ConfirmAddressPage.vue'
import Rejected from './components/pages/eligibility-enroll/Rejected.vue'
import ExpiredClosedPage from '@/components/pages/eligibility-enroll/ExpiredClosedPage.vue'
import EnrolmentFailure from './components/pages/eligibility-enroll/EnrolmentFailure.vue'
import TransferAcpPlan from '@/components/pages/eligibility-enroll/enroll/TransferAcpPlan.vue'
import CompletedACP from './components/pages/eligibility-enroll/CompletedACPPage.vue'
import ACPEligibilityCheck from '@/components/pages/eligibility-enroll/ACPEligibilityCheck.vue'

const eligibility = [
    {
        path: '/ZipCodePage',
        component: EnterZipCodePage,
        meta: { hideNavigation: false },
    },
    {
        path: '/SorryZipCodePage',
        component: SorryZipCodePage,
        meta: { hideNavigation: false },
    },
    {
        path: '/SuccessZipCodePage',
        component: SuccessZipCodePage,
        meta: { hideNavigation: false },
    },
    //profile steps
    {
        path: '/PersonalInfoPage',
        component: PersonalInfoPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/ContactInfoPage',
        component: ContactInfoPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/HouseholdInformationPage',
        component: HouseholdInformationPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/QualificationPage',
        component: QualificationPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/TribalStatusPage',
        component: TribalStatusPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/VerifyPage',
        component: VerifyPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/AgreementPage',
        component: AgreementPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/Reviewing',
        component: Reviewing,
        meta: { hideNavigation: false },
    },
    {
        path: '/CheckEnrollStatusPage',
        component: CheckEnrollStatusPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/EnrollStep',
        component: EnrollStep,
        meta: { hideNavigation: false },
    },
    {
        path: '/ConfirmAddress',
        component: ConfirmAddress,
        meta: { hideNavigation: false },
    },
    {
        path: '/Rejected',
        component: Rejected,
        meta: { hideNavigation: false },
    },
    {
        path: '/Expired',
        component: ExpiredClosedPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/EnrolmentFailure',
        component: EnrolmentFailure,
        meta: { hideNavigation: false },
    },
    {
        path: '/Failure',
        component: EnrolmentFailure,
        meta: { hideNavigation: false },
    },
    {
        path: '/TransferAcpPlan',
        component: TransferAcpPlan,
        meta: { hideNavigation: false },
    },
    {
        path: '/CompletedACP',
        component: CompletedACP,
        meta: { hideNavigation: false },
    },
    {
        path: '/ACPEligibilityCheck',
        component: ACPEligibilityCheck,
        meta: { hideNavigation: false },
    },
]


const routes = [
    ...eligibility,
    {
        path: '/',
        component: HomePage,
        meta: { hideNavigation: false },
    },
    {
        path: '/AboutACPPage',
        component: AboutACPPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/ContactUsPage',
        component: ContactUsPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/ResourcesPage',
        component: ResourcesPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/BlogPage',
        component: BlogPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/BlogPageList',
        component: LatestBlogPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/LatestBlogPage',
        component: LatestBlogPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/RegistrationPage',
        component: RegistrationPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/VerificationPage',
        component: VerificationPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/QualityACPPage',
        component: QualityACPPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/QualityPlanPage',
        component: QualityPlanPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/NotQualifedPage',
        component: NotQualifedPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/ShippigDetailsPage',
        component: ShippigDetailsPage,
        meta: { hideNavigation: false },
    },

    {
        path: '/EnrollmentResultPage',
        component: EnrollmentResultPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/ConfirmProgrammPage',
        component: ConfirmProgrammPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/HomeAdressPage',
        component: HomeAdressPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/PersonalInformationPage',
        component: PersonalInformationPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/QuestionPage',
        component: QuestionPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/ConfirmYourProgram',
        component: ConfirmYourProgram,
        meta: { hideNavigation: false },
    },
    {
        path: '/ReviewPage',
        component: ReviewPage,
        meta: { hideNavigation: false },
    },
    {
        path: '/ContactInformation',
        component: ContactInformation,
        meta: { hideNavigation: false },
    },
]

const router = createRouter({
    history: createWebHistory(),
    base: '/',
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView()
    },
})

router.beforeEach((to, from, next) => {
    const queries = to.fullPath.split('?')
    const rawPath = queries[0].split('/')
    const last = rawPath[rawPath.length - 1]
    if (last && to.matched.length === 0) {
        saveResellerCode(last)
        const q = (queries[1] ? `?${queries[1]}` : '')
        const slicedPath = `${rawPath.slice(0, rawPath.length - 1).join('/')}` + q
        next(slicedPath)
        return
    }
    next()
})

export default router
