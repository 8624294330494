<template>
    <section class="mt-202 mb-80">
        <div class="container container-1170">
            <h2 class="mb-62">Do you <span style="color: #003875">Qualify</span>?</h2>
            <div>
                <div class="acptitle main-blue-color">
                    Here are the ways your household can qualify for the Affordable Connectivity Program (ACP):
                </div>
                <ul class="informer_list">
                    <li>Based on your <span class="emphasis">household</span> income</li>
                    <li>
                        If you or your child or dependent participate in certain government assistance programs such as
                        SNAP, Medicaid, WIC, or
                        <span class="emphasis">other programs</span>
                    </li>
                    <li>
                        If you or anyone in your household already receives a
                        <span class="emphasis">Lifeline benefit</span>
                    </li>
                </ul>
                <div class="paragraph_medium">
                    Note: You may qualify for the ACP through a participating provider’s existing low-income program.
                    Visit our
                    <span class="emphasis">How to Apply</span> page to learn more.
                </div>
            </div>
            <div>
                <div class="acptitle main-blue-color">Based on your income</div>
                <div class="paragraph_medium mb-24 font-family-Robot">
                    You are eligible for the ACP if your income is 200% or less than the Federal Poverty Guidelines (see
                    the table below). The guideline is based on your household size and state.
                </div>
                <div class="paragraph_medium font-family-Robot">
                    The table below reflects the income limit by household size, which is 200% of the 2023 Federal
                    Poverty Guidelines.
                </div>
            </div>
            <div class="table-box">
                <table class="table_price">
                    <thead>
                        <tr>
                            <td>Household Size</td>
                            <td>48 Contiguous States, D.C.</td>
                            <td>Alaska</td>
                            <td>Hawaii</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>$101,120</td>
                            <td>$113,580</td>
                            <td>$116,280</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>$101,120</td>
                            <td>$113,580</td>
                            <td>$116,280</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>$101,120</td>
                            <td>$113,580</td>
                            <td>$116,280</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>$101,120</td>
                            <td>$113,580</td>
                            <td>$116,280</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>$101,120</td>
                            <td>$113,580</td>
                            <td>$116,280</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>$101,120</td>
                            <td>$113,580</td>
                            <td>$116,280</td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>$101,120</td>
                            <td>$113,580</td>
                            <td>$116,280</td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td>$101,120</td>
                            <td>$113,580</td>
                            <td>$113,580</td>
                        </tr>
                        <tr>
                            <td style="white-space: nowrap">For each additional person, add:</td>
                            <td>$10,280</td>
                            <td>$12,860</td>
                            <td>$11,820</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="paragraph_medium">
                You may have to show proof of income, like a tax return or three consecutive pays stubs, when you apply
                for the ACP.
            </div>
            <div>
                <div class="acptitle main-blue-color">
                    If you participate in certain government assistance programs such as SNAP, Medicaid, WIC, or other
                    programs
                </div>
                <div class="acpSubtitle mb-24">Federal Assistance Programs</div>
                <div class="paragraph_medium">
                    You are eligible for the ACP if you (or someone in your household) participate in one of these
                    programs:
                </div>
                <ul class="informer_list">
                    <li>Supplemental Nutrition Assistance Program (SNAP), formerly known as Food Stamps</li>
                    <li>Medicaid</li>
                    <li>Supplemental Security Income (SSI)</li>
                    <li>
                        Federal Public Housing Assistance (FPHA) (including Housing Choice Voucher (HCV) Program
                        (Section 8 Vouchers), Project-Based Rental Assistance
                        (PBRA)/<span class="span-font-family-Robot">202</span>/<span class="span-font-family-Robot">
                        811</span>, Public Housing, and
                        Affordable Housing Programs for American Indians, Alaska Natives or Native Hawaiians)
                    </li>
                    <li>Veterans Pension and Survivors Benefit</li>
                    <li>
                        Free and Reduced-Price School Lunch Program or School Breakfast Program, including at U.S.
                        Department of Agriculture (USDA) Community Eligibility Provision schools
                    </li>
                    <li>
                        Received a Federal Pell Grant in the current award year
                    </li>
                </ul>
                <div class="paragraph_medium">
                    You may need to show a card, letter, or official document as
                    <span class="emphasis">proof that you participate</span> in one of these programs when you apply for
                    the ACP.
                </div>
            </div>
            <div>
                <div class="acptitle main-blue-color">Tribal Assistance Programs</div>
                <div class="paragraph_medium">
                    If you live on qualifying Tribal lands, you are eligible for the ACP if your household income is at
                    or below 200% of the Federal Poverty Guidelines, or if you (or someone in your household)
                    participate in:
                </div>
                <ol class="informer_list">
                    <li>Any of the federal assistance programs listed above</li>
                    <li>Bureau of Indian Affairs General Assistance</li>
                    <li>Head Start (only households meeting the income qualifying standard)</li>
                    <li>Tribal Temporary Assistance for Needy Families (Tribal TANF)</li>
                    <li>Food Distribution Program on Indian Reservations</li>
                </ol>
                <div class="paragraph_medium">
                    You may have to show
                    <span class="emphasis">proof of participation</span>, like a benefit letter or official
                    document, when you apply for the ACP. Learn more about the Tribal Benefit.
                </div>
            </div>
            <div>
                <div class="acptitle main-blue-color">If You Already Receive a Lifeline Benefit</div>
                <div class="paragraph_medium">
                    Lifeline is a
                    <span class="emphasis">Federal Communications Commission program</span>
                    to make communications services more affordable for low-income consumers. If you currently receive
                    a Lifeline benefit, you automatically qualify for the ACP, and you can receive both benefits at the
                    same time. You can apply your ACP benefit and your Lifeline benefit to the same or separate
                    services. Talk to your internet company to learn how to start receiving ACP discounts.
                </div>
            </div>
            <div>
                <div class="acptitle main-blue-color">Qualify Through Your Child or Dependent</div>
                <div class="paragraph_medium mb-62">
                    Any member of your household can make your household eligible if they participate in one of the
                    programs above. For example, if your child or dependent participates in the Free and Reduced-Price
                    School Lunch Program or is enrolled in a USDA Community Eligibility Provision school, your household
                    qualifies for the ACP benefit.
                </div>
            </div>
            <div class="validateSectionCard text-center">
                <div class="subtitle text-font">
                    Are you ready to get started with the Affordable Connectivity Program?
                </div>
                <div class="mt-24 text-font-1">
                    You can apply online or with the assistance of your internet company.
                </div>
                <div class="mt-24 text-center">
                    <router-link to="/ZipCodePage" class="blue-btn w-129 m-auto">Apply now</router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    components: {},
    methods: {},
    data() {
        return {}
    },
}
</script>
<style>
@import '../../../../assets/styles/components/sections/SinglePages/AboutACPPage/03_Qualify.css';
</style>
<style scoped>
table {
    /*width: 700px;*/
    margin-bottom: 0;
    /*max-width: none;*/
}
.container{
    max-width: 1070px !important;
    padding: 32px;
}
.blue-btn:hover{
    background: white !important;
    color: #003875 !important;
}
@media (max-width: 667px) {
    .acptitle {
        font-size: 25px;
        line-height: 29.35px;
    }
    h2 {
        font-weight: 500;
        font-size: 30px;
        line-height: 35.22px;
    }
}
.table_price thead td {
    white-space: nowrap;
    padding-top: 13px;
    padding-bottom: 12.5px;
}
.text-font {
    font-weight: 600;
    font-size: 25px;
    line-height: 29.35px;
}
.text-font-1 {
    font-size: 16px;
    line-height: 22.4px;
}
.span-font-family-Robot{
    font-weight: 400;

}
.font-family-Robot, .span-font-family-Robot{
    font-family: Roboto;
}
.validateSectionCard a {
    color: white;
}
@media (min-width: 667px) and (max-width: 768px) {
    .table-box {
        width: 580px;
    }
    .validateSectionCard {
        max-width: 626px;
        height: 238px;
    }
    .text-font-1 {
        margin-top: 24px;
    }
}
@media (max-width: 375px) and (max-width: 667px) {
    .validateSectionCard {
        max-width: 311px;
        height: 271px;
    }
    .text-font {
        font-size: 20px;
        line-height: 23px;
    }
    .text-font-1 {
        font-size: 16px;
        line-height: 22.4px;
        margin-top: 10px;
    }
}
@media (min-width: 1024px) {
    .validateSectionCard {
        margin: 0 auto;
        max-width: 1006px;
        height: 209px;
    }
    .table-box {
        width: 1006px;
        overflow-x: scroll;
        overflow-y: hidden;
    }
}
@media (min-width: 375px) and (max-width: 667px) {
    .table-box {
        width: calc(100% - 70px);
        overflow-x: scroll;
        overflow-y: hidden;
    }
}
</style>
