<template>
  <div class="section section-profileBreadcrumbs font-raleway">
    <div class="row">
      <div  class="col-md-12 col-sm-4 col-12"
          :class="[
                    stepNum == 1
                        ? 'section-profileBreadcrumbs-item--not-active'
                        : 'section-profileBreadcrumbs-item--active',
                ]"
      >
        {{ $t('helpers.profileBreadcrumbs.contactInformation') }}
      </div>
      <div class="col-md-12 col-sm-4 col-12"
          :class="[
                    stepNum > 1 ? 'section-profileBreadcrumbs-item--not-active' : 'section-profileBreadcrumbs-item',
                    stepNum > 3 && 'section-profileBreadcrumbs-item--active',
                ]"
      >
        {{ $t('helpers.profileBreadcrumbs.householdInformation') }}
      </div>
      <div class="col-md-12 col-sm-4 col-12"
          :class="[
                      stepNum > 3 ? 'section-profileBreadcrumbs-item--not-active' : 'section-profileBreadcrumbs-item',
                      stepNum > 4 && 'section-profileBreadcrumbs-item--active',
                  ]"
      >
        {{ $t('helpers.profileBreadcrumbs.qualification') }}
      </div>
      <div class="col-md-12 col-sm-4 col-12"
          :class="[
                    stepNum > 4 ? 'section-profileBreadcrumbs-item--not-active' : 'section-profileBreadcrumbs-item',
                    stepNum > 5 && 'section-profileBreadcrumbs-item--active',
                ]"
      >
        {{ $t('helpers.profileBreadcrumbs.tribalStatus') }}
      </div>
      <div class="col-md-12 col-sm-4 col-12"
          :class="[
                    stepNum > 5 ? 'section-profileBreadcrumbs-item--not-active' : 'section-profileBreadcrumbs-item',
                    stepNum > 6 && 'section-profileBreadcrumbs-item--active',
                ]"
      >
        {{ $t('helpers.profileBreadcrumbs.verification') }}
      </div>
      <div class="col-md-12 col-sm-4 col-12"
          :class="[
                    stepNum > 6 ? 'section-profileBreadcrumbs-item--not-active' : 'section-profileBreadcrumbs-item',
                ]"
      >
        {{ $t('helpers.profileBreadcrumbs.agreement') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['stepNum'],
}
</script>

<style>
@import './../../assets/styles/helpers/profileBreadcrumbs.css';
</style>
