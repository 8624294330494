import ApiService from './api.service'

const deviceModelService = {
    getDevice(deviceModalName) {
        return ApiService.get(
            `${process.env.VUE_APP_API_LINK}/public/device?name=${deviceModalName}`
        )
    },
    getDeviceByID(deviceModelId) {
        return ApiService.get(`${process.env.VUE_APP_API_LINK}/public/device/${deviceModelId}`)
    },
}

export default deviceModelService
