<template>
    <section class="section section-profileTitle">
        <div class="container container-1170">
            <h2>Check <span class="main-green-color">Eligibility</span></h2>
        </div>
    </section>

    <section class="section section-profile pb-80">
        <div class="container container-1170">
            <div class="row">
                <div class="col-md-2">
                    <ProflieBreadcrumbs :stepNum="profileStep" />
                </div>
                <div class="col-md-8 col agreement-card-wrapper">
                    <h3 class="mb-16 h3-header">Agreement</h3>
                    <div class="mb-32">
                     <p>Please fill out the initials next to each statement and sign this form to finish the process</p>
                    </div>

                    <div class="position-relative general-card mb-32">
                        <h5 class="mb-32">I certify, under penalty of perjury, that:</h5>
                        <div class=" d-sm-flex">
                            <div class="mr-16">
                                <div class="w-88 d-block">
                                    <div>
                                        Initial <span class="label-required">*</span>
                                    </div>
                                    <input type="text" v-model="initial1" required />
                                </div>
                            </div>
                            <div class="text-font-size">
                                I (or my dependent or another person in my household) currently get benefits from the
                                government program listed on this form or my annual household income is 200% or less
                                than the Federal Poverty Guidelines (the amount listed in the Federal Poverty Guidelines
                                table on this form).
                            </div>
                        </div>
                        <hr />
                        <div class="d-sm-flex">
                            <div class="mr-16">
                                <div class="w-88 d-block">
                                    <div>
                                        Initial <span class="label-required">*</span>
                                    </div>
                                    <input type="text" v-model="initial2" required />
                                </div>
                            </div>
                            <div class="text-font-size">
                                I agree that if I move, I will give my internet company my new address within 30 days.
                            </div>
                        </div>
                        <hr />
                        <div class="d-sm-flex">
                            <div class="mr-16">
                                <div class="w-88 d-block">
                                    <div>
                                        Initial <span class="label-required">*</span>
                                    </div>
                                    <input type="text" v-model="initial3" required />
                                </div>
                            </div>
                            <div class="text-font-size">
                                I know that my household can only get one ACP benefit and, to the best of my knowledge,
                                my household is not getting more than one ACP benefit. I understand that I can only
                                receive one connected device (desktop, laptop, or tablet) through the ACP, even if it
                                switches ACP companies.
                            </div>
                        </div>
                        <hr />
                        <div class="d-sm-flex">
                            <div class="mr-16">
                                <div class="w-88 d-block">
                                    <div>
                                        Initial <span class="label-required">*</span>
                                    </div>
                                    <input type="text" v-model="initial4" required />
                                </div>
                            </div>
                            <div class="text-font-size">
                                I agree that all of the information I provide on this form may be collected used,
                                shared, and retained for the purposes of applying for and/or receiving the ACP benefit I
                                understand that if this information is not provided to the program Administrator, I
                                will not be able to get ACP benefits. If the laws of my state or Tribal government
                                require it, I agree.
                            </div>
                        </div>
                        <hr />
                        <div class="d-sm-flex">
                            <div class="mr-16">
                                <div class="w-88 d-block">
                                    <div>
                                        Initial <span class="label-required">*</span>
                                    </div>

                                    <input type="text" v-model="initial5" />
                                </div>
                            </div>
                            <div class="text-font-size">
                                The ACP Administrator or my service provider may have to check whether I am
                                still eligible.
                            </div>
                        </div>
                        <hr />
                        <div class="d-sm-flex">
                            <div class="mr-16">
                                <div class="w-88 d-block">
                                    <div>
                                        Initial <span class="label-required">*</span>
                                    </div>
                                    <input type="text" v-model="initial6" />
                                </div>
                            </div>
                            <div class="text-font-size">
                                I was truthful about whether or not I am a resident of Tribal lands, as defined in the
                                "Your information" section of this form.
                            </div>
                        </div>
                    </div>

                    <h3 class=" h3-header mb-16">Your signature</h3>
                    <div class="mb-16">
                        <label>Type your full legal name <span class="label-required">*</span></label>
                        <input class="footer-input" type="text" v-model="signature" required />
                    </div>

                    <div class="d-flex align-items-center align-items-sm-start">
                        <div>
                            <input class="mr-8 " type="checkbox" style="width: 20px" name="agree" v-model="agree" />
                        </div>
                        <div class="checkbox-text">
                            I understand this is a digital signature, and is the same as if I signed my name with a pen
                        </div>
                    </div>
                    <div v-if="agree" class="success-alert checkbox-text mt-3">
                      Click Continue, and you will be redirected to the National Verifier, where you will need
                        to submit proofs for verification. You will then be transferred back automatically to
                        compile your application.
                    </div>

                    <div class="d-flex justify-content-between mt-32">
                        <router-link to="/VerifyPage?profileStep=6" class="btn-round btn-black-ghost">Back</router-link>
                        <button @click="goNext" class="btn-round btn-green" :disabled="loading">
                          <Loader v-if="loading" color="white" borderRadius="20" />
                          {{ error === ERROR_TIMEOUT ? 'Resubmit' : 'Continue' }}
                        </button>
                    </div>
                    <div class="text-font-size text-error text-right">{{ error }}</div>
                </div>
            </div>
        </div>
    </section>

    <ContactSection />
</template>

<script>
import ContactSection from './ContactSection.vue'
import ProflieBreadcrumbs from '@/components/helpers/ProfileBreadcrumbs.vue'
import eligibilityService from '@/common/services/checkEligibility'
import checkValidation from '@/components/validation/checkValidation'
import checkCheckbox from '@/components/validation/checkCheckbBox'
import { checkString } from '@/components/validation/value'
import {
  destroyApplicationId,
  saveApplicationId,
  saveEligibilityCheckId,
  destroyEligibilityCheckId,
  cookieSetPersonalData, cookieRemovePersonalData
} from '@/common/eligibility.service'
import { PENDING_RESOLUTION_HREF, WAITING_RESULT } from '@/eligibility-enroll/status'
import { ERROR_TIMEOUT } from '@/eligibility-enroll/nlad'
import Loader from '@/components/helpers/Loader.vue'
import utility from '@/common/services/utility'
import { useToast } from 'vue-toastification'


export default {
    setup() {
      const toast = useToast()
      return { toast }
    },
    mounted() {
        if (this.$store.state.PersonalStore?.personalData?.firstName.length < 1) {
            this.$router.push('/ZipCodePage')
        }
    },
    components: {
      Loader,
        ContactSection,
        ProflieBreadcrumbs,
    },
    computed: {
        profileStep() {
            return this.$route.query.profileStep
        },
        personalData() {
          return this.$store.state.PersonalStore.personalData
        },
        checkTribal() {
          return this.$store.state.PersonalStore.checkTribal
        }
    },
    methods: {
        goNext() {
            const validation = {
                agree: checkCheckbox(this.agree),
                signature: checkString(this.signature),
                initial1: checkString(this.initial1),
                initial2: checkString(this.initial2),
                initial3: checkString(this.initial3),
                initial4: checkString(this.initial4),
                initial5: checkString(this.initial5),
                initial6: checkString(this.initial6),
            }
            if (this.signature.length < 2) {
              this.error = 'Signature is mandatory'
              return
            }
            if (!this.agree) {
                this.error = 'Checkbox is mandatory'
                return
            }

            checkValidation(validation) === true ? this.checkEligibility() : (this.error = 'All fields are mandatory')
        },
        showError (data) {
          const error = data.data.message
          this.toast.error(error)
        },
        async checkEligibility() {
            this.error = ""
            this.loading = true
            const payload = {
              ...this.personalData
            }
            if (this.checkTribal) {
              delete payload['ssn4']
            }
            const resellerCode = await utility.isValidResellerCode()
            if (resellerCode) {
              payload['resellerCode'] = resellerCode
            }
            const data = await eligibilityService.postPersonalDataForCheckEligibility(payload)
            this.loading = false

            const appId = data?.data?.applicationId
            appId && saveApplicationId(appId)
            const eligibilityCheckId = data?.data?.eligibilityCheckId
            eligibilityCheckId && saveEligibilityCheckId(eligibilityCheckId)

            const dob = this.personalData.dob.split('-')
            const payloadEnroll = {
              firstName: this.personalData.firstName,
              lastName: this.personalData.lastName,
              emailAddress: this.personalData.contactEmail,
              contactPhoneNumber: this.personalData.contactPhoneNumber,
              shippingAddress1: this.personalData.address,
              shippingCity: this.personalData.city,
              shippingState: this.personalData.state,
              state: this.personalData.state,
              shippingZipcode: this.personalData.zipCode,
              dob: `${dob[1]}/${dob[2]}/${dob[0]}`,
            }
            cookieSetPersonalData(payloadEnroll)

            if (PENDING_RESOLUTION_HREF.includes(data.data.status)) {
                window.location.href = String(data.data._links.resolution.href)
                return
            }
            if (data.data.status === 'PENDING_CERT') {
                window.location.href = String(data.data._links.certification.href)
                return
            }
            if (WAITING_RESULT.includes(data.data.status)) {
                this.$router.push('/Reviewing')
                return
            }
            if (data.data.status === 'COMPLETE') {
              this.$store.commit('SET_personalDataSave', payloadEnroll)
              this.$router.push('/EnrollStep')
                return
            }
            destroyApplicationId()
            destroyEligibilityCheckId()
            cookieRemovePersonalData()
            if (data.data?.errors?.length > 0) {
                this.error = data.data.errors.map(item => (item.message || item.description)).join('. ')
              return
            }
            if (data.data.error === 'INVALID_TRIBAL_ID') {
              this.showError(data)
              this.$router.push('/TribalStatusPage?profileStep=5')
              return
            }
            if (data.data.error === 'STATE_NOT_SUPPORTED') {
              this.showError(data)
              this.$router.push('/ContactInfoPage?profileStep=2')
              return
            }
            this.error = data.data.message
        },
    },
    data() {
        return {
            ERROR_TIMEOUT,
            error: '',
            signature: '',
            initial1: '',
            initial2: '',
            initial3: '',
            initial4: '',
            initial5: '',
            initial6: '',
            loading: false,
            agree: false
        }
    },
}
</script>
<style scoped>
    .text-font-size{
        font-family: Roboto;
    }
    input{
        width: 88px;
    }
    p{
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
    }
    .h3-header {
        font-size: 24px;
        line-height: 33px;
    }
    .footer-input{
        width: 100%;
    }
    .checkbox-text{
        font-family: Inter;
        font-size: 14px;
        color: #003875;

    }
    @media (min-width: 768px) and (max-width: 780px) {
       .general-card{
           min-width: 691px;
       }
        .footer-input{
            width: 691px;
        }
    }
    @media (min-width: 1200px) {
        .agreement-card-wrapper{
            min-width: 906px;
            margin: 0 auto;
            padding: 0;
        }
        .general-card{
            min-width: inherit;
        }
    }
    @media (max-width: 576px) {
        .text-font-size{
            margin-top: 16px;
        }
    }
    @media (min-width: 375px) and (max-width: 380px) {
        .footer-input{
            width: 345px;
        }
    }

</style>