<template>
    <section class="mt-42 mb-80">
        <div class="container">
            <div class="text-center">
                <h2 class="w-752">
                    You qualify for the
                    <span class="main-blue-color">Affordable Connectivity Program</span>
                    benefit!
                </h2>
            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="benefit-card">
                <div class="text-center">
                    <h2 class="mb-24">Your are eligible for the following benefit:</h2>
                    <div class="mb-24 special-plan">
                        <img :src="okIcon" alt="" /> Free Special plan and Android tablet
                    </div>
                    <div class="mb-24">Free Special plan and Android tablet</div>
                    <div class="mb-24">
                        <input type="checkbox" />
                        Check if you are eligible for additional
                        <span class="main-blue-color">Tribal Benefit</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 mb-32">
                        <div class="section-plan-card m-auto">
                            <div class="section-plan-card-header">
                                <div class="section-plan-card-title">5G LTE DATA</div>
                                <div class="section-plan-card-size">15GB<span>/mo</span></div>
                            </div>
                            <div>
                                <div class="section-plan-card-price black-color">$25<span>/mo</span></div>
                                <div>
                                    <ul class="section-plan-card-list">
                                        <li>Unlimited talk & text</li>
                                        <li>Nationwide coverage</li>
                                        <li>Uses 5G or 4G LTE</li>
                                        <li>whichever is strongest</li>
                                    </ul>
                                </div>
                                <div class="text-center mt-24 mb-10">
                                    <router-link class="ghost-blue-btn" to="/">Get this plan</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="d-flex justify-content-center">
                            <div class="mr-32">
                                <img :src="miniTablet" alt="" />
                            </div>
                            <div class="pt-24">
                                <div class="buy_title mb-24">
                                    Android Tablet for
                                    <span class="main-blue-color">$10.01</span> only
                                </div>
                                <div class="mb-148">
                                    <input type="checkbox" /> Include tablet for
                                    <span class="main-blue-color">$10.1</span> only
                                </div>
                                <div>
                                    <router-link class="ghost-blue-btn" to="/">See Characterirstics</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center mb-64">
                    <div class="blue-btn">Enroll now</div>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="quality-banner">
                <div class="quality-title">
                    This service is provided by Peeringhub Inc., an approved ACP service provider:
                </div>
                <div>
                    <img class="quality-banner-image" :src="logo" alt="" />
                </div>
            </div>
        </div>
    </section>

    <section class="mb-42 mb-66">
        <div class="container text-center">
            <div class="check-card">
                <h2 class="mb-24 w-770">Check if you are eligible for additional Tribal Benefit</h2>
                <div class="mb-32 w-770 d-inline-block">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua
                </div>
                <div>
                    <div class="blue-btn">Check now</div>
                </div>
            </div>
        </div>
    </section>

    <section class="section-tablet">
        <div class="container">
            <div class="row section-tablet-black">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <div class="section-tablet-h1 mb-32">
                        Get This Tablet at
                        <span class="sky-color">$10.01</span>
                    </div>
                    <div class="section-tablet-h2 mb-24">Characterirstics</div>
                    <table class="section-tablet-table">
                        <tr>
                            <td>Platform</td>
                            <td>Qualcomm Quad Core</td>
                        </tr>
                        <tr>
                            <td>OS</td>
                            <td>Info</td>
                        </tr>
                        <tr>
                            <td>Size</td>
                            <td>Info</td>
                        </tr>
                        <tr>
                            <td>Display</td>
                            <td>Info</td>
                        </tr>
                        <tr>
                            <td>Camera</td>
                            <td>Info</td>
                        </tr>
                        <tr>
                            <td>Battery</td>
                            <td>Info</td>
                        </tr>
                        <tr>
                            <td>WiFi</td>
                            <td>Info</td>
                        </tr>
                        <tr>
                            <td>Bands</td>
                            <td>Info</td>
                        </tr>
                        <tr>
                            <td>Certification</td>
                            <td>Info</td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <img :src="tabletImg" class="img-fluid mt-80" alt="" />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    components: {},
    methods: {},
    data() {
        return {
            logo: require('../../../assets/images/icons/logo.svg'),
            tabletImg: require('../../../assets/images/icons/tablet.svg'),
            okIcon: require('../../../assets/images/icons/okIcon.svg'),
            miniTablet: require('../../../assets/images/icons/miniTablet.svg'),
        }
    },
}
</script>
<style>
@import '../../../assets/styles/components/pages/RegisterFlowPage/Quality.css';
</style>
