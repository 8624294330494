<template>
    <section class="mt-42 mb-80">
        <div class="container">
            <div class="text-center">
                <h2 class="w-752 header-title">
                    You are not qualified for the
                    <span class="main-blue-color">Affordable Connectivity Program</span>
                    benefit!
                </h2>
            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="benefit-card">
                <div class="text-center benefit-card-sub-title">
                    <h2 class="mb-24">
                        The best choice for a second line to protect your privacy and anonymity while surfing the
                        Internet
                    </h2>
                    <div class="mb-24">
                        It is important to take care of the patient, to follow the patient, but this will happen at a
                        time when there is a lot of work and pain;
                    </div>
                    <div class="text-center mb-32">
                        <div class="blue-btn">Enroll now</div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="mb-160">
        <div class="container">
            <div class="row">
                <div class="bg-img col-md-8">
                    <div>
                        <div class="PSim1-text-size">
                            This service is provided by 1PSim Inc., an approved ACP service provider:
                        </div>
                    </div>
                    <div class="ml-0">
                        <img :src="logo" alt="" />
                    </div>
                </div>
                <div class="mt-20 section-plan-box col-md-4" style="margin-top: 20px">
                    <div class="section-plan-card m-auto">
                        <div class="section-plan-card-header">
                            <div class="section-plan-card-title">5G LTE DATA</div>
                            <div class="section-plan-card-size">15GB<span>/mo</span></div>
                        </div>
                        <div>
                            <div class="section-plan-card-price black-color">$25<span>/mo</span></div>
                            <div>
                                <ul class="section-plan-card-list">
                                    <li>Unlimited talk & text</li>
                                    <li>Nationwide coverage</li>
                                    <li>Uses 5G or 4G LTE</li>
                                    <li>whichever is strongest</li>
                                </ul>
                            </div>
                            <div class="text-center mt-24 mb-10">
                                <router-link class="ghost-blue-btn" to="/">Get this plan</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    components: {},
    methods: {},
    data() {
        return {
            logo: require('../../../assets/images/icons/1psimLogo.svg'),
        }
    },
}
</script>
<style>
@import '../../../assets/styles/components/pages/RegisterFlowPage/Quality.css';
</style>
<style scoped>
.benefit-card {
    width: 850px;
    border-radius: 32px;
    border: 1px solid #e8eaf0;
    padding: 32px 24px;
    margin: auto;
}
.benefit-card-sub-title > h2 {
    font-size: 30px;
    font-weight: 500;
    line-height: 35.22px;
}
.section-plan-card-title,
.section-plan-card-size {
    font-family: Montserrat;
}
.section-plan-card-list > li {
    font-family: Inter;
}
.bg-img {
    margin-top: 20px;
    height: 390px;
    border-radius: 24px;
    width: 100%;
    max-width: 533px;
    background-size: cover !important;
    background: url('../../../assets/images/pages/NotQualifedPage/mainscreen.svg');
}
.PSim1-text-size {
    font-size: 30px;
    font-weight: 500;
    line-height: 35px;
    text-align: center;
    color: white;
    margin-top: 30px;
}
img {
    text-align: left;
    margin-top: 100px;
}
@media (max-width: 667px) {
    .PSim1-text-size {
        font-size: 25px;
        line-height: 29px;
    }
}
@media (min-width: 1024px) {
    .section-plan-box {
        width: 280px;
        height: 390px;
        padding: 0;
        margin-left: 37px;
    }
    .section-plan-box .section-plan-card {
        width: inherit;
        height: inherit;
    }
    .container .row {
        width: 850px;
        margin: auto;
    }
}

@media (min-width: 768px) and (max-width: 800px) {
    .section-plan-box {
        width: 280px;
        height: 390px;
        padding: 0;
        margin-left: 37px;
    }
    .section-plan-box .section-plan-card {
        width: inherit;
        height: inherit;
    }
    .bg-img {
        width: 373px;
        height: 390px;
    }
    .bg-img img {
        margin-top: 80px;
    }
    .container .row {
        width: 690px;
        margin: auto;
    }
    .container .benefit-card {
        width: 690px;
        margin: auto;
    }
}
@media (max-width: 768px) {
    .benefit-card {
        width: auto;
    }
    .header-title {
        width: auto;
    }
}
@media (max-width: 376px) {
    .header-title {
        font-size: 30px;
        font-weight: 500;
        line-height: 35.22px;
    }
}
</style>
