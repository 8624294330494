<template>
  <section class="mt-42 mb-160">
    <div class="container">
      <div class="zip-card" >
        <div class="text-center mobile-w-311">
          <h1 class="main-blue-color">Please Enter your ZIP code</h1>
          <h3 class="mt-32 mb-32 text-left px-sm-54 px-0">
            Please enter your ZIP code to make sure you can participate in the program
          </h3>
        </div>
        <div class="px-sm-54 px-0 mobile-w-311">
          <label>Zip Code</label>
          <input
              v-model="zipCode"
              :maxlength="6"
              type="text"
              class="w-100"
              placeholder="00000"
              @input="validate('zipCode')"
              @keydown="onlyNumber"
          />
          <div class="text-error">{{ error }}</div>
        </div>
        <div class="d-flex justify-content-between mt-32 px-sm-54 px-0 mobile-w-311" :class="{'button-box-error-m-t':error}">
          <div>
            <router-link to="/" class="text-blue-btn">Back</router-link>
          </div>
          <div>
            <button @click="goNext" class="blue-btn position-relative" :disabled="loading">
              <Loader color="white" border-radius="8" v-if="loading" />
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Loader from '@/components/helpers/Loader.vue'
import { checkString } from '../../validation/value'
import checkValidation from '../../validation/checkValidation'
import zipCodes from '../../../_constants/zipcodes'
import { mapActions } from 'vuex'
import { removeUserData } from '@/eligibility-enroll/nlad'

export default {
  components: {
    Loader
  },
  data() {
    return {
      loading: false,
      zipCode: '',
      error: ''
    }
  },
  created() {
    removeUserData()
  },
  computed: {
    currentState() {
      return zipCodes.find(e => e.zipMin <= +this.zipCode && e.zipMax >= +this.zipCode)
    }
  },
  methods: {
    ...mapActions(['checkZipCode']),
    validate(field) {
      this.error = ''
      if ((['all', 'zipCode'].includes(field))) {
        const l = this[field].length
        if (l < 1) {
          this.error = 'Zip code is mandatory'
          return false
        }
        if (l < 5 || l > 6) {
          this.error = 'Zip code is invalid'
          return false
        }
      }
      return true
    },
    onlyNumber(event) {
      if (!/\d/.test(event.key) && event.key !== 'Backspace'&& event.key !== 'ArrowLeft'&& event.key !== 'ArrowRight') return event.preventDefault()
    },
    async goNext() {
      try {
        const validation = {
          zipCode: checkString(this.zipCode)
        }
        if (!checkValidation(validation)) return (this.error = 'Zip code is mandatory')

        if (!this.currentState) return this.$router.push({
          path: '/SorryZipCodePage',
          query: { zipCode: this.zipCode }
        })
        this.loading = true
        const { data } = await this.checkZipCode(this.currentState.shortcode)
        this.loading = false
        if (!data) return

        this.$router.push({ path: '/SuccessZipCodePage', query: { zipCode: this.zipCode } })
      } catch (err) {
        this.$router.push({ path: '/SorryZipCodePage', query: { state: this.currentState.shortcode } })
      }
    }
  }
}
</script>

<style scoped>
.main-blue-color {
  font-family: Raleway;
  font-size: 45px;
  font-weight: 600;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: center;
  color: #003875;
}

h3 {
  font-family: Raleway;
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  color: #0F172A;
}

.zip-card {
  margin: auto;
  max-width: 690px;
  padding: 44px 30px;
  gap: 32px;
  background: #ffffff;
  box-shadow: 0px 8px 16px -4px rgba(0, 12, 32, 0.12);
  border-radius: 32px;
  font-family: Raleway;
}
.text-blue-btn{
  position: relative;
  top: 12px;
}

.zip-card input {
  padding: 16px 12px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
}

.text-green {
  color: green;
}

.px-54 {
  padding-inline: 54px;
}

label {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #39404D;
}

input {
  font-family: Roboto;
}

@media (max-width: 576px) {
  label {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .main-blue-color {
    font-size: 30px;
    font-weight: 500;
    line-height: 35px;
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    text-align: left;

  }

  .container {
    height: 413px;
  }
  .button-box-error-m-t{
    margin-top: 10px!important;
  }
  .zip-card {
    width: 343px;
    height: 413px;
    padding: 3px 19px
  }
}
  @media (max-width: 375px) {
    .mobile-w-311{
      width: 311px;
    }
    .mobile-w-311 input{
      height: 54px;
    }
  }
</style>
