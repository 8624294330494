<template>
  <section class="mt-42 contact-us">
    <div class="container mb-195">
      <div class="text-center title mb-62">Get in touch with us</div>
      <div class="row row-rivers justify-content-center">
        <div class="col-lg-6 col-md-8 col-sm-8 position-relative">
          <div class="slider_middle_dialog d-flex">
            <img class="mass-img-style" :src="logo" alt=""/>
            <span >We are always in touch
            and ready to answer any of your questions</span>

          </div>
          <img class="contactBg" :src="contactBg" alt=""/>
        </div>
        <div class="col-lg-6 col-md-8 col-sm-8">
          <div class="contact-card">
            <h3 class="mb-32" style="white-space: nowrap">Fill in the fields below</h3>
            <div class="d-flex mb-8 filter-child-box">
              <div class="contact-name">
                <label>First name</label>
                <input class="w-100" type="text" placeholder="First Name" v-model.trim="firstName" @input="checkForm('firstName')" />
              </div>
              <div class="contact-last-name">
                <label>Last name</label>
                <input class="w-100" type="text" placeholder="Last name" v-model.trim="secondName" @input="checkForm('secondName')" />
              </div>
            </div>
            <div class="mb-8 filter-child-box">
              <label>Email</label>
              <input class="w-100" type="email" placeholder="Email" v-model.trim="email" @input="checkForm('email')"/>
            </div>
            <div class="mb-8 filter-child-box">
              <label>Contact</label>
              <input class="w-100" type="number" placeholder="Contact" v-model.trim="phone" @input="checkForm('phone')"/>
            </div>
            <div class="mb-8 filter-child-box">
              <CustomDropdownSearch
                      label="Subject"
                      placeholder="Select Subject"
                      :list="subjects"
                      v-model="subject"
                      @change="checkForm('subject')"
              />
            </div>
            <div class="mb-8 filter-child-box">
              <label>Message</label>
              <textarea placeholder="Message" class="w-100 h-117" v-model.trim="message" @input="checkForm('message')"></textarea>
            </div>
            <div v-if="errors" class="text-danger">
              {{ errors }}
            </div>
            <div>
              <button class="blue-btn w-100 text-center mt-32 filter-child-button-box position-relative" :disabled="loading" @click="sendMessage">
                <Loader color="white" v-if="loading" />
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Loader from '@/components/helpers/Loader.vue'
import { useToast } from 'vue-toastification'
import checkEmail from '@/components/validation/checkEmail'
import CustomDropdownSearch from '../../../components/helpers/CustomDropdown.vue'
import axios from 'axios'
import { errorInterceptor } from '@/utilities/errorInterseptor'

export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  components: {
    Loader,
    CustomDropdownSearch
  },
  data() {
    return {
      contactBg: require('../../../assets/images/pages/ContactPage/contactUs.svg'),
      logo: require('../../../assets/images/icons/message-question.svg'),
      loading: false,
      firstName: '',
      secondName: '',
      email: '',
      message: '',
      phone: '',
      subject: '',
      subjects:[],
      errors: '',
    }
  },
  methods: {
    checkForm(field) {
      this.errors = ''
      if ((field === 'all' || field === 'firstName') && this.firstName.length < 1) {
        this.errors = 'First Name required'
        return false
      }
      if ((field === 'all' || field === 'secondName') && this.secondName.length < 1) {
        this.errors = 'Last Name required'
        return false
      }
      if (field === 'all' || field === 'email') {
        if (this.email.length < 1) {
          this.errors = 'Email required'
          return false
        }
        if (!checkEmail(this.email)) {
          this.errors = 'Email invalid'
          return false
        }
      }
      if (field === 'all' || field === 'phone') {
        if (this.phone.toString().length < 1) {
          this.errors = 'Contact phone number required'
          return false
        }
        const _phone = this.phone.toString().length
        if (_phone < 10 || _phone > 11) {
          this.errors = 'Contact phone number invalid'
          return false
        }
      }
      if ((field === 'all' || field === 'subject') && this.subject.length < 1) {
        this.errors = 'Subject required'
        return false
      }
      if ((field === 'all' || field === 'message') && this.message.length < 1) {
        this.errors = 'Message required'
        return false
      }
      return true
    },
    sendMessage() {
      const res = this.checkForm('all')
      if (!res) {
        return
      }
      const headers = { Accept: 'text/plain', 'Content-Type': 'text/plain' }
      const website = 'affordableconnectivity.us'
      this.loading = true
      axios.post('https://mattermost.denovolab.com/hooks/fx3oo6dqsfn3mef8fekd89fb9e', {
                text: `Subject: ${this.subject}\nName: ${this.firstName} ${this.secondName}\nEmail: ${this.email}\nPhone number: ${this.phone}\nMessage: ${this.message}`,
                channel: '#website-contact-form',
                username: `${website} Subscriber`,
              }, headers)
          .then(() => {
            this.firstName = ''
            this.secondName = ''
            this.email = ''
            this.message = ''
            this.subject = ''
            this.phone = ''
            this.toast.success('The message was sent successfully')
          })
          .catch((error) => {
            const e = errorInterceptor(error)
            e && this.toast.error(e)
          })
          .finally(() => {
            this.loading = false
          })
    },
  },
}
</script>
<style>
.contact-card {
  padding: 44px 84px;
  gap: 32px;
  background: #ffffff;
  box-shadow: 0px 8px 16px -4px rgba(0, 12, 32, 0.12);
  border-radius: 32px;
  width: 563px;
  height: 676px;
}

.contact-card label {
  display: block;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #39404d;
}

.contact-card input {
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 8px 12px;
  gap: 8px;
  margin-top: 0;
}

.contact-card textarea {
  padding: 8px 12px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
}

.contact-name {
  margin-right: 16px;
}
</style>
<style scoped>
.contact-us {
  font-family: Inter !important;
}

.slider_middle_dialog {
  padding: 10px 14px;
  gap: 10px;
  position: absolute;
  width: 235px;
  height: 68px;
  left: -70px;
  top: 50%;
  background: #f8fafc;
  box-shadow: 0px 8px 16px -4px rgba(0, 12, 32, 0.12);
  border-radius: 16px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #003875;
}
@media (max-width: 374px) {
  .contactBg {
    width: auto;
    height: 360px;
    object-fit: cover;
    border-radius: 32px;
  }
}
@media (min-width: 375px) and (max-width: 667px) {
  .title {
    font-size: 35px;
    line-height: 41px;
  }

  .contactBg {
    width: 343px;
    height: 360px;
    object-fit: cover;
    border-radius: 32px;
  }

  .contact-name {
    margin-right: 8px !important;
  }

  .contact-card {
    width: auto;
  }
}

@media (max-width: 992px) {
  .row-rivers {
    flex-direction: column-reverse;
    gap: 24px;
  }

  .slider_middle_dialog {
    bottom: -15px;
    top: unset;
    left: -15px;
  }
}
@media (max-width: 800px) {
  .slider_middle_dialog{
    width: 218px;
    height: 99px;
  }
  .mass-img-style{
    margin-top: 13px;
    margin-left: -7px;
  }
  .slider_middle_dialog span{
    margin-top: 12px;
    margin-left: -1px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #1E293B;


  }
}
@media (min-width: 700px) and (max-width: 768px) {
  .contactBg {
    width: 690px;
    height: 360px;
    object-fit: cover;
    border-radius: 32px;
  }

  .contact-card {
    width: 690px;
    padding: 24px 16px !important;
    margin-bottom: 24px;

  }

  .contact-name {
    margin-right: 8px !important;
    width: 100%;
  }

  .contact-last-name {
    width: 100%;
  }

  .row-rivers {
    flex-direction: column-reverse;
  }
}

@media (min-width: 1024px) {
  .filter-child-box {
    width: 395px;
  }

  .filter-child-button-box {
    width: 395px !important;
  }
  .contact-card{
  padding:44px 84px;
  }
  input{
    position: relative;
    bottom: 13px;
  }
  .filter-child-box{
    margin-bottom:-5px
  }
  ::v-deep .position-relatve {
    position: relative;
    bottom: 6px;
  }
  ::v-deep ::after{
    margin-top: -5px;
  }
  textarea{
    position: relative;
    bottom: 13px;
  }
}

@media (max-width: 1024px) {
  .contact-card {
    padding: 20px 20px;
    max-height: 636px;
  }
  .mass-img-style{
    width: 35px !important;
    height: 34px!important;
  }
}

@media (min-width: 1025px) {
  .slider_middle_dialog  {
     width: 300px;
     height: 117px;
  }
  .slider_middle_dialog span{
    margin-left: 12px;
    margin-top: 12px;
    font-family: Inter;
    font-size: 19px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;

  }
  .mass-img-style{
    margin-top: 12px;
    width: 40px !important;
    height: 40px!important;
  }
}

@media (max-width: 375px) {
  .contact-card {
    width: 343px !important;
    margin-bottom: 24px;
  }
  .row-rivers{
    margin: 0 -7px auto;
  }
  .slider_middle_dialog{
    left: 10px;
  }
}
  @media (max-width: 768px) {
    .contact-card {
      height: 100%;
      max-height: 100% !important;
    }
    .slider_middle_dialog {
      left: auto;
    }
  }
</style>
