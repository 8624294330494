<template>
  <section class="section section-summaryPlan pb-160 mt-132">
    <div class="position-relative container container-1170" id="cartTablet">
      <div class="text-center mb-40">
        <div class="section-title main-green-color">Congratulation!</div>
        <div class="mb-72">
          <p>You are qualified to enroll in Accessible Connectivity Program.</p>
        </div>
      </div>
      <div class="congrat-sub-block">
        <div class="text-center">
          <div class="ehnance-title mb-24">Your are eligible for the following benefit:</div>

          <div class="special-title d-flex align-items-center mb-24 gap-2 justify-content-center"><img :src="okIcon" alt="" /><div>Free Special plan and Android tablet</div></div>
        </div>

          <div class="section-card-plan-tablet mb-32">
            <div class="section-plan-card" style="min-height: 390px !important;max-height: 390px !important; width: 280px !important; padding-bottom: 0!important; margin: 0!important;">
              <div class="section-plan-card-header">
                <div class="section-plan-card-title">5G LTE DATA</div>
                <div class="section-plan-card-size" v-if="!tribalStatus">20GB <span>/mo</span></div>
                <div class="section-plan-card-size" v-else>UNLIMITED</div>
              </div>
              <div>
                <div class="section-plan-card-price">FREE</div>
                <div>
                  <ul class="section-plan-card-list">
                    <li>Unlimited talk & text</li>
                    <li>Nationwide coverage</li>
                    <li>Uses 5G or 4G LTE,</li>
                    <li>whichever is strongest</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="summaryPlan-media">
                <div>
                  <img :src="miniTabletImg" class="mr-32" />
                </div>
                <div class="tablet-btn-details">
                  <div>
                    <div class="mb-24 tablet-title" style="max-width: 216px; !important;">
                      <div>
                        Android Tablet for <span class="main-green-color">$10.01</span> only
                      </div>
                    </div>
                    <div class="flex">
                      <div class="mr-8">
                        <input type="checkbox" v-model="includeTablet" :disabled="!tabletId || userAlreadyHadTabletBefore" />
                      </div>
                      <div>Include tablet for <span class="main-green-color"> $10.01 only</span></div>
                    </div>
                  </div>
                  <div class="tablet-btn-details-btn">
                    <button class="tablet-btn" @click="openModal()" :disabled="!tabletId">See Characteristics</button>
                  </div>
                </div>
            </div>
          </div>

        <div class="text-center"><button @click="enrollNow()" class="btn-round btn-green-light">{{ userAlreadyHadTabletBefore ? 'Re-enroll with the Plan only' : 'Enroll now' }}</button></div>
      </div>

    </div>
  </section>

  <GetTabletModal :tabletData="dataModel" :showModalTablet="showModalTablet" />
</template>

<script>
import deviceModelService from '../../../../common/services/deviceModel'
import GetTabletModal from './GetTabletModal.vue'
import { getTribalStatus } from '@/common/eligibility.service'
import { appIdIsExist } from '@/eligibility-enroll/nlad'

export default {
  components: {
    GetTabletModal,
  },
  methods: {
    openModal() {
      this.showModalTablet = true
    },
    enrollNow() {
      if (this.userAlreadyHadTabletBefore || !this.includeTablet || !this.tabletId) {
        localStorage.removeItem('tabletId')
      } else {
        localStorage.setItem('tabletId', this.tabletId)
      }
      this.$router.push('/ConfirmAddress')
    },
  },
  created() {
    if (!appIdIsExist()) {
      return
    }
    this.userAlreadyHadTabletBefore = this.$route.query?.code === 'CANNOT_UPDATE_DEVICE'
    deviceModelService.getDevice('MobiVista10').then((data) => {
      this.dataModel = data?.data[0]
    })
  },
  computed: {
    tabletId () {
      return this.dataModel?.id
    }
  },
  data() {
    return {
      dataModel: '',
      showModalTablet: false,
      tabletImg: require('../../../../assets/images/icons/tablet.svg'),
      failIcon: require('../../../../assets/images/fail.svg'),
      okIcon: require('../../../../assets/images/success.svg'),
      miniTabletImg: require('../../../../assets/images/icons/miniTablet.svg'),
      statusTablet: true,
      statusTribal: true,
      statusEnhance: this.$route.query.statusEnhance == true ? true : false,
      includeTablet: false,
      tribalStatus: getTribalStatus() === 'Y',
      userAlreadyHadTabletBefore: false
    }
  },
}
</script>

<style scoped>
@import '../../../../assets/styles/components/pages/RegisterFlowPage/Quality.css';
.special-title {
  color: var(--text-primary, #272d37);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  text-transform: capitalize;
}
.ehnance-title {
  font-family: Inter;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: center;

}

.tablet-table td {
  border-bottom: 1px solid #fff;
}

.tablet-btn {
  color: var(--text-secondary, #1e293b);
  /* Button Medium (Default) */
  font-family: Inter;
  border-radius: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.42px;
  text-transform: capitalize;
  background: none;
  border: 1px solid var(--slate-300, #cbd5e1);
  padding: 12px 24px;
  gap: 10px;
}

.tablet-title {
  color: #272D37;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  text-transform: capitalize;
}

.section-card-plan-tablet {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
}
.congrat-sub-block {
  display: flex;
  max-width: 1170px;
  padding: 24px 32px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 24px;
  background: #FFF;
  box-shadow: 0px 8px 16px -4px rgba(0, 12, 32, 0.12);
}
.tablet-btn-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.tablet-btn-details-btn {
  margin-top: 155px;
}
.summaryPlan-media {
  height: 390px;
  max-width: 610px !important;
  display: flex;
  padding: 24px 32px;
  align-items: flex-start;
  gap: 32px;
  flex-wrap: no-wrap;
  border-radius: 16px;
  border: 1px solid #F1F5F9;
  background: #FFF;
  box-shadow: 0px 8px 16px -4px rgba(0, 12, 32, 0.12);
}
@media screen and (max-width: 997px) {
  .congrat-sub-block {
    margin: 0 10px 0 10px;
  }
  .tablet-btn-details {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .tablet-btn-details-btn {
    margin-top: 24px;
  }
  .summaryPlan-media {
    height: max-content;
  }
  .section-card-plan-tablet {
    align-items: flex-start;
    gap: 24px;
  }
  .tablet-title {
    margin-top: 48px;
  }
}
@media (min-width: 769px) and (max-width: 897px) {
  .summaryPlan-media {
    flex-wrap: wrap;
  }
}
@media (min-width: 618px) and (max-width: 769px) {
  .summaryPlan-media {
    flex-wrap: no-wrap;
  }
}
@media screen and (max-width: 618px) {
  .summaryPlan-media {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 440px) {
  .summaryPlan-media {
    border: 0!important;
    box-shadow: none!important;
  }
}
@media screen and (max-width: 769px) {
  .tablet-btn-details {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .tablet-btn-details-btn {
    margin-top: 24px;
  }
  .summaryPlan-media {
    height: max-content;
  }
  .section-card-plan-tablet {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .tablet-title {
    margin-top: 48px;
  }
}

.tablet-table tr {
  text-align: left;
}

.tablet-table td {
  padding: 20px;
}

.section-tablet-table td {
  border-bottom: 1px solid #475569;
  padding-top: 16px;
  padding-bottom: 16px;
}

</style>
