<template>
    <headerThatLocalPage />
    <footerThatLocalPage style="padding-bottom: 30px" />
</template>

<script>
    import footerThatLocalPage from '../eligibility-enroll/profile-steps/SorryZipCodeFooter'
    import headerThatLocalPage from '../eligibility-enroll/profile-steps/SorryZipCodeHeader'

    export default {
        components: {
            footerThatLocalPage,
            headerThatLocalPage,

        },
        methods: {},
        data() {
            return {}
        },
    }
</script>
<style></style>