<template>
    <!-- REJECTED -->
    <section class="section section--top--space section--bottom--space section-activeSimCard">
        <div class="container container-1170">
            <div>
                <div class="text-center">
                    <img :src="rejectedImg" alt="" />
                    <h2>{{ $t('general.rejected') }}</h2>
                </div>
                <h3 class="text-center mt-32">{{ error || $t('enroll.error2') }}</h3>
                <div class="text-center mt-32">
                  {{ $t('enroll.body') }}
                </div>
                <div>
                    <div class="position-relative general-card general-card-970 mb-32 d-flex justify-content-between">
                        <div class="flex-item">
                            <label>{{ $t('enroll.appId') }}:</label>
                            <input
                                @keyup="checkForActivaite()"
                                placeholder="ID"
                                type="text"
                                class="w-100"
                                v-model="applicationId"
                            />
                        </div>
                        <div class="flex-item">
                            <label>{{ $t('enroll.eligibilityCheckId') }}:</label>
                            <input
                                @keyup="checkForActivaite()"
                                placeholder="ID"
                                type="text"
                                class="w-100"
                                v-model="eligibilityCheckId"
                            />
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <button
                        @click="redirect"
                        :disabled="loading"
                        :class="[
                            checkActivation
                                ? 'btnBackgroundGray btn-round btn-green btnBackgroundZero'
                                : 'btn-round btn-green btnBackgroundZero',
                        ]"
                    >
                      <Loader v-if="loading" color="white" borderRadius="20" />
                        {{ $t('enroll.check') }}
                    </button>
                </div>
            </div>
            <div class="section-activeSimCard-small-background"></div>
        </div>
    </section>
</template>

<script>
import eligibilityService from '../../../common/services/checkEligibility'
import { checkString } from '../../validation/value'

import {
  destroyApplicationId,
  saveApplicationId, saveEligibilityCheckId
} from '@/common/eligibility.service'
import { PENDING_RESOLUTION_HREF, WAITING_RESULT } from '@/eligibility-enroll/status'
import Loader from '@/components/helpers/Loader.vue'
import { removeUserData } from '@/eligibility-enroll/nlad'

export default {
    components: { Loader },
    methods: {
      checkForActivaite() {
        this.error = ""
        const validation = checkString(this.applicationId) || checkString(this.eligibilityCheckId)
        validation ? (this.checkActivation = false) : (this.checkActivation = true)
      },
      redirect() {
        this.error = ""
        const validation = checkString(this.applicationId) || checkString(this.eligibilityCheckId)
        validation ? this.success() : (this.error = this.$t('error.allMandatory'))
      },
        async success() {
            this.error = ""
            this.loading = true
            const resultData = await eligibilityService.postEligibilityCheck(this.applicationId, this.eligibilityCheckId)
            this.loading = false

            const appId = resultData?.data?.applicationId
            appId && saveApplicationId(appId)

            if (resultData.data.status === 'APP_CLOSED_OR_EXPIRED') {
              this.$router.push(`/Expired?id=${appId || resultData?.data?.eligibilityCheckId}`)
            }

            if (PENDING_RESOLUTION_HREF.includes(resultData.data.status)) {
              window.location.href = String(resultData.data._links.resolution.href)
              return
            }
            if (resultData.data.status === 'PENDING_CERT') {
              window.location.href = String(resultData.data._links.certification.href)
              return
            }
            if (WAITING_RESULT.includes(resultData.data.status)) {
              const eligibilityCheckId = resultData?.data?.eligibilityCheckId
              eligibilityCheckId && saveEligibilityCheckId(eligibilityCheckId)
              this.$router.push('/Reviewing')
              return
            }
            if (resultData.data.status === 'COMPLETE') {
              this.$router.push('/EnrollStep')
              return
            }
            destroyApplicationId()
            if (resultData.data?.errors?.length > 0) {
              this.error = resultData.data.errors.map(item => (item.message || item.description)).join('. ')
              return
            }
            this.error = resultData.data.message
        },
    },
    created() {
      removeUserData()
    },

  data() {
        return {
            error: "",
            checkActivation: true,
            eligibilityCheckId: '',
            applicationId: '',
            rejectedImg: require('../../../assets/images/Rejected.svg'),
            closeImg: require('../../../assets/images/Close.svg'),
            loading: false
        }
    },
}
</script>

<style scoped>

.tablet-table tr {
  text-align: left;
}

.tablet-table td {
  padding: 20px;
}
.section-tablet-table td {
  border-bottom: 1px solid #475569;
  padding-top: 16px;
  padding-bottom: 16px;
}
</style>
