import ApiService from './api.service'

const orderFulfillmentService = {
    getOrder(orderId) {
        return ApiService.get(
            `${process.env.VUE_APP_API_LINK}/public/order/${orderId}`
        )
    },
    getOrder2(isPublic, orderId) {
        return ApiService.get(
            `${process.env.VUE_APP_API_LINK}/${isPublic ? 'public' : 'subscriber'}/order/${orderId}`
        )
    },
    getSubFulfillment(fulfillmentId) {
        return ApiService.get(
            `${process.env.VUE_APP_API_LINK}/subscriber/fulfillment/${fulfillmentId}`
        )
    }
}

export default orderFulfillmentService
