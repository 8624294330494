<template>
    <section>
        <div class="container header-box-size p-0">
            <div class="text-center height-inherit mt-52 mb-42">
                <div class="blog-single-card height-inherit box-size">
                    <div class="blog-single-card--date white-color">April 09, 2022</div>
                    <div class="blog-single-card--title">Have you considered investing in a small business?</div>
                    <div class="ghost-btn white-color">Growth</div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="container">
            <div class="text-center blog-single-card-first-text w-770 mb-42">
                Outdoor living space is a luxury. It invites you to slow down, disconnect from the daily hustle, and
                tune into what matters — plus it adds to the size and value of your home.
            </div>
            <div class="blog-single-card--description w-770">
                <div class="d-inline-flex">
                  <h1 class="m-0">M</h1>any homeowners have already taken the first step and invested in an outdoor living space addition
                    for these reasons. But what comes after that?
                </div>
              <div>
                A deck or patio doesn’t last forever. You have to make upgrades or improvements to your outdoor living area for maintenance. Otherwise, it becomes overrun with cracks, weeds, peeling paint, and more. Often, it’s prudent to completely remodel an outdoor living space; that way you can tend to any foundational issues while sprucing up the cosmetics. At the same time, you can also add new features like a built-in fireplace, grilling station, or bar.
                Know your outdoor living area needs maintenance but having a hard time justifying the work? Here are four reasons to remodel from ASPIRA VISION to make your decision a little easier.
              </div>
              <div class="f-size-20 pt-3">1. EXPAND & IMPROVE LIVING SPACE </div>
              <div>
                The most obvious benefit of outdoor living rooms is more space. Having a second outdoor living area allows you and your household to engage in a wide variety of activities together. You can roast marshmallows, soak up the sunshine, breathe in the fresh air, and do any other number of activities you simply can’t do indoors. However, if your outdoor living area has gotten old and rundown, this valuable space is likely being used less and less. Your investment is wasting away.
                But a remodel can fix that. A remodel gives you the opportunity to do repair or maintenance work, like a decking renovation, while also allowing you to improve your space. One improvement that really pays off is adding a roof if you don’t have one already. A roof can be beneficial for all types of outdoor living areas. That’s particularly true for deck and patio outdoor living spaces, which often don’t come with one. Homeowners with detached outdoor living spaces might consider a covered walkway between the house and outdoor space.
                We also recommend adding amenities that make members of your household more likely to utilize outdoor space. Culinary enthusiasts should invest in an outdoor kitchen or grilling station, while those who love to entertain can install a t.v., bar, and comfortable furniture.
              </div>
              <div class="f-size-20 pt-3">2. CREATE A BETTER LIFESTYLE</div>
              <div>
                As illustrated above, remodeling allows you to create a space specialized for your interests and lifestyle. Don’t let this opportunity pass by! Think about the activities and hobbies your household enjoys. Then talk to a builder who can help you figure out how to remodel a backyard into the perfect outdoor space. You can incorporate a play area for the kids, a yoga zone, a workout area, a reading nook, and anything else you can imagine!
                Creating this space encourages you to engage in your favorite activities — whether you want to relax or get rowdy. Either way, having an area in your home solely dedicated to enjoyment is a good thing. It's like taking a mini-vacation every time you step outside.
                Outdoor living space also vastly improves your entertaining capabilities, especially if you include a cooking and dining area. Adults can relax around the fireplace while keeping an eye on the kids running about in the yard. And you can easily set up a game of volleyball in the yard or prepare snacks nearby.
              </div>
              <div class="f-size-20 pt-3">3. IT’S GOOD FOR YOUR HEALTH</div>
              <div>
                It’s a proven fact: spending time outside is good for your health.
                Being outside means you’re more likely to engage in physical activity; it’s also known to lower your risk for a myriad of diseases, such as diabetes, as well as lower your blood pressure and heart rate.
                Connecting to nature can even help your mental health by reducing stress and tangibly boosting your mood.
              </div>
              <div class="f-size-20 pt-3">4. BOLSTER HOME VALUE</div>
              <div>
                Exterior upgrades do a lot to increase your home value. In fact, they often yield a much higher return on investment than interior remodeling projects. Curb appeal is immensely important to homebuyers because the outside of your home is a representation of who you are — so don’t neglect yours!
                You can start small with exterior improvements like remodeling landscaping and hardscaping. We come across a lot of homeowners who wonder, “Does something as minor as landscaping maintenance really make a difference?” Or, “ Does hardscaping add value to a home?” The answer is definitely yes. These small improvements don’t require too much work and often offer a high return.
                Your exterior shouldn’t just be beautiful, though. It should be functional and livable too. Doesn’t it seem like a bit of waste if there isn’t a place to sit and admire the beautiful landscaping? A remodeled outdoor living area helps you get the most out of your yard and home.
                You can also consider pairing your outdoor area with other backyard features that add value like a pool or fit pit.
              </div>
            </div>
        </div>
    </section>
    <section class="mt-52 mb-160">
        <div class="container">
            <h3 class="mb-32">Related resources</h3>
            <div class="row">
              <div class="col-md-4 col-sm-6 d-flex justify-content-center" v-for="(item, index) in block_lists" :key="index">
                <div class="blog-card">
                  <div>
                    <img class="blog-image" :src="item.img" alt="" />
                  </div>
                  <div class="mb-12">Growth {{item.date}}</div>
                  <div class="blog-title mb-12">{{item.title}}</div>
                  <div class="blog-description">
                    {{item.text}}
                  </div>
                </div>
              </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    components: {},
    methods: {},
    data() {
        return {
            blogImg: require('../../assets/images/icons/blog.svg'),
          block_lists: [
            {
              img : require('../../assets/images/icons/Frame4.png'),
              date: 'April 09, 2022',
              title: 'How a visual artist redefines success in graphic design',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'
            },
            {
              img : require('../../assets/images/icons/Frame2.png'),
              date: 'April 09, 2022',
              title: 'How a visual artist redefines success in graphic design',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'
            },
            {
              img : require('../../assets/images/icons/Frame3.png'),
              date: 'April 09, 2022',
              title: 'How a visual artist redefines success in graphic design',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'
            },
          ],
        }
    },
}
</script>
<style scoped>
.container {
  box-shadow: unset !important;
  padding: unset !important;
}
@media (max-width: 954px) {
  .w-770 {
    width: 100% !important;
  }
}
</style>
<style>
.blog-single-card {
    background: linear-gradient(0deg, rgba(0, 18, 52, 0.5), rgba(0, 18, 52, 0.5)),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    box-shadow: 0px 8px 16px -4px rgba(0, 12, 32, 0.22);
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
}

.blog-single-card--title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 55px;
    line-height: 65px;
    text-align: center;
    color: #ffffff;
    width: 822px;
}

.blog-single-card--date {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    font-feature-settings: 'liga' off;
    color: #ffffff;
}

.blog-single-card--description {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    font-feature-settings: 'liga' off;
    color: #212b2d;
    width: 770px;
    margin: auto;
}
.blog-single-card-first-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    color: #838383;
    margin: 0 auto 42px;
}
.header-box-size {
    height: 443px;
    /*background: url('../../assets/images/pages/BlogPage/BlogPageHaderImege.jpg');*/
    border-radius: 32px;
    margin-bottom: 42px;
    background-color: #000000;
    background-image: url('../../assets/images/pages/BlogPage/BlogPageHaderImege.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.height-inherit {
    height: inherit;
}
.ghost-btn {
    padding: 12px 24px;
    font-weight: 500;
}
@media (max-width: 376px) {
    .blog-single-card--title {
        font-size: 26px;
        line-height: 30.52px;
        width: auto;
    }
    .blog-image {
        width: 329px;
    }
    .header-box-size {
        width: 343px;
        height: 406px;
        margin-bottom: 24px;
    }
    .blog-single-card--description,
    .blog-single-card-first-text {
        width: 343px;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .blog-single-card--title {
        font-size: 35px;
        line-height: 41.09px;
    }
    .blog-single-card--description,
    .blog-single-card-first-text {
        width: 688px;
    }
}
@media (min-width: 667px) and (max-width: 768px) {
    .header-box-size {
        width: 688px;
        height: 395px;
    }
}
</style>
