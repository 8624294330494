<template>
    <section class="mt-42 mb-160">
        <div class="container">
            <div class="home-card">
                <div class="text-center">
                    <h2 class="main-blue-color">Fill out your information</h2>
                    <h3 class="mt-32 mb-32 text-left">Full name as it appears on your identification</h3>
                    <div class="text-left mb-32">
                        Enter the name you have on your official documents like Social Security Card or Government ID.
                        This should be your full legal name, not a nickname.
                    </div>
                </div>
                <div class="mb-32">
                    <div class="d-flex">
                        <div class="w-100 mr-16">
                            <label class="mr-16">First Name</label>
                            <input class="w-100" v-model="firstName" type="text" placeholder="First Name" />
                        </div>
                        <div class="w-100 mr-16">
                            <label class="mr-16">Middle Name (optional)</label>
                            <input class="w-100" v-model="middleName" type="text" placeholder="Middle Name" />
                        </div>
                        <div class="w-100 mr-16">
                            <label class="mr-16">Last or Family Name</label>
                            <input class="w-100" type="text" placeholder="Last or Family Name" />
                        </div>
                    </div>
                    <div>
                        <div class="mt-32 mb-32">
                            <h3>Date of Birth</h3>
                        </div>
                    </div>
                </div>
                <div class="mb-32">
                    <div class="d-flex">
                        <div class="w-100 mr-16">
                            <label>Month</label>
                            <CustomDropdown
                                placeholder="Month"
                                :modelValue="this.monthStr"
                                :list="[
                                    {text:'January',value:'january'},
                                    {text : 'February',value : 'february'},
                                    {text : 'March',value : 'march'},
                                    {text : 'April',value : 'april'},
                                    {text : 'May',value : 'may'},
                                    {text : 'June',value : 'june'},
                                    {text : 'July',value : 'july'},
                                    {text : 'August',value : 'august'},
                                    {text : 'September',value : 'september'},
                                    {text : 'October',value : 'october'},
                                    {text : 'November',value : 'november'},
                                    {text : 'December',value : 'december'},
                                ]"
                                v-model="monthStr"
                            />
                        </div>
                        <div class="w-100 mr-16">
                            <label>Day</label>
                            <input class="w-100" type="text" placeholder="DD" />
<!--                            <CustomDropdown-->
<!--                                placeholder="Day"-->
<!--                                :modelValue="this.day"-->
<!--                                :list="generateDayData()"-->
<!--                                v-model="day"-->
<!--                                class="mr-16 w-95"-->
<!--                            />-->
                        </div>
                        <div class="w-100 mr-16">
                            <label>Year</label>
                            <input class="w-100" type="text" placeholder="Year" />
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-32">
                    <div>
                        <router-link to="/QuestionPage" class="text-blue-btn">Back</router-link>
                    </div>
                    <div>
                        <button class="blue-btn" @click="success">Next</button>
                    </div>
                </div>
                <div class="text-error text-right">{{ error }}</div>
            </div>
        </div>
    </section>
</template>

<script>
import checkValidation from '../../validation/checkValidation'
import CustomDropdown from '../../../components/helpers/CustomDropdown.vue'
import { getDaysInMonth, generatYear } from '../../generation/date'

const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

const monthNumber = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
}

export default {
    components: { CustomDropdown },
    methods: {
        getCurrrentMonthNumber() {
            const numberMonth =
                +(String(this.parseBOD('month', this.$store.state.PersonalStore?.personalData?.dob))[0] == '0'
                    ? +this.parseBOD('month', this.$store.state.PersonalStore?.personalData?.dob)[1] - 1
                    : +this.parseBOD('month', this.$store.state.PersonalStore?.personalData?.dob)) - 1 || ''
            return numberMonth < 0 ? 0 : numberMonth
        },
        parseBOD(period, value) {
            if (value.length < 2 && value === undefined) return

            const splitValue = value.split('-')

            if (period === 'year') return splitValue[0]
            if (period === 'month') return splitValue[1]
            if (period === 'day') return splitValue[2]
        },
        generatYearData() {
            return generatYear()
        },
        generateDayData() {
            return getDaysInMonth(this.month, this.year)
        },
        success() {
            this.$router.push('/ConfirmYourProgram')
        },
        goNext() {
            const validation = {}

            checkValidation(validation) === true ? this.success() : (this.error = 'All fields are mandatory')
        },
        setRegisterData() {
            const month = monthNumber[this.monthStr]

            this.$store.dispatch('addToPersonal', ['firstName', this.firstName])
            this.$store.dispatch('addToPersonal', ['lastName', this.lastName])
            this.$store.dispatch('addToPersonal', [
                'dob',
                this.year + '-' + this.zeroFill(month) + '-' + this.zeroFill(this.day),
            ])
        },
    },
    data() {
        return {
            monthStr: monthList[this.getCurrrentMonthNumber()],
            error: '',
        }
    },
}
</script>
<style>
.home-card {
    background: #ffffff;
    box-shadow: 0px 8px 16px -4px rgba(0, 12, 32, 0.12);
    border-radius: 32px;
    padding: 44px 84px;
    gap: 32px;
}

.home-card input {
    padding: 16px 12px;
    gap: 8px;
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
}
</style>
