export const getToken = () => {
    return localStorage.getItem('TOKEN')
}

export const saveToken = (token) => {
    localStorage.setItem('TOKEN', token)
}

export const destroyToken = () => {
    localStorage.removeItem('TOKEN')
    localStorage.removeItem('userData')
    localStorage.removeItem('SubscriberId')
    localStorage.removeItem('currentUser')
}

export const checkToken = () => {
    return !['', null, undefined].includes(getToken())
}

export default { getToken, saveToken, destroyToken, checkToken }
