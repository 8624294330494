<template>
    <section class="validateSection mb-160">
        <div class="container">
            <div class="validateCard">
                <div class="text-center mb-32">
                    <h2 class="white-space">Validate your eligible section</h2>
                </div>
                <div class="validateSectionCard">
                    <div class="subtitle mb-32 mt-32 white-space">Before you get started</div>
                    <div>
                        The personal information you enter will be used to see if you qualify for a discount on your
                        internet. This information will only be used to find out if you qualify.
                        <ul>
                            <li>
                                Verify your identity using the
                                last four digits of your Social Security Number, a Tribal ID number, or
                                other government ID (Driver's License, Passport, etc.). Providing a Social Security
                                Number may reduce the need to provide extra documentation.
                            </li>
<!--                            <li>Enter an <a href="#">email address</a> so we can contact you.</li>-->
                        </ul>
                    </div>
                </div>
                <div class="mt-32 text-right next-m-t">
                    <router-link to="/ZipCodePage" class="blue-btn w-84">Next</router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    components: {},
    methods: {},
    data() {
        return {}
    },
}
</script>
<style>
@import '../../../assets/styles/components/sections/HomePage/04_Validate.css';
</style>
<style scoped>
@media only screen and (max-width: 768px) {
    .container {
        max-width: 690px !important;
        max-height: 546px !important;
    }
    .validateCard {
        padding-bottom: 17px;
    }
    .next-m-t {
        margin-top: 15px;
    }
}
@media (max-width: 667px) {
    h2 {
        font-weight: 500;
        font-size: 30px;
        line-height: 35px;
        white-space: unset !important;
    }
    .container {
        width: inherit !important;
        max-height: 713px !important;
        margin-left: 10px;
        padding: 0
    }
    .subtitle {
        font-size: 25px;
        line-height: 29.35px;
    }
    .validateCard {
        width: inherit !important;
        padding-bottom: 44px;
    }
    .next-m-t {
        margin-top: 32px;
    }
}
@media (max-width: 769px) {
    .validateCard {
        width: 690px;
        height: 546px;
        padding-left: 16px;
        padding-right: 16px;
    }
}
@media (max-width: 376px) {
    .validateCard {
        width: auto;
        height: auto;
    }
    .container {
        max-width: 343px !important;
    }
    .text-center {
        margin-bottom: 10px;
        margin-top: -25px;
    }
    .subtitle {
        margin-top: 10px !important;
    }
}
.white-space {
    white-space: nowrap;
}
.subtitle {
    margin-top: 10px;
}
.container{
    box-shadow: unset;
    padding: 0;
}
.validateCard {
    max-width: 1290px;
}
</style>
