<template>
    <section class="section section-profileTitle">
        <div class="container container-1170">
            <h2>Check <span class="main-green-color">Eligibility</span></h2>
        </div>
    </section>

    <section class="section section-profile pb-80">
        <div class="container container-1170">
            <div class="row">
                <div class="col-md-3">
                    <ProflieBreadcrumbs :stepNum="profileStep" />
                </div>
                <div class="col-md-8 col content-style">
                    <h3 class="mb-16">Please verify your information before Submitting</h3>
                    <p class="mb-32">
                        Before we check if you qualify for the
                        <router-link class="main-green-color" to="/">Affordable Connectivity Benefit</router-link>, make
                        sure your information is right.
                    </p>
                    <div class="position-relative general-card mb-32">
                        <table class="verify-table">
                            <tr>
                                <td>Full Legal Name:</td>
                                <td>
                                    <b>
                                        {{ personalData?.firstName }}
                                    </b>
                                    &nbsp;
                                    <b>
                                        {{ personalData?.lastName }}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td>Date of birth:</td>
                                <td>
                                    <b>{{ personalData?.dob }}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>Last 4 Numbers of SSN:</td>
                                <td>
                                    <b>{{ personalData?.ssn4 }}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>Phone number:</td>
                                <td>
                                    <b>{{ personalData?.contactPhoneNumber }}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>
                                    <b>{{ personalData?.address }}</b>
                                </td>
                            </tr>
                        </table>
                        <div class="position-absolute edit-btn-position">
                            <div class="btn-edit">
                                <router-link to="/PersonalInfoPage?profileStep=1" class="btn-round btn-black-ghost pl-42"
                                    >Edit</router-link
                                >
                            </div>
                        </div>
                    </div>

                    <div class="success-alert--without-icon">
                        <div class="d-flex mb-32 ">
                            <div style="margin-left: 10px;display: flex; justify-content: center;}">
                                <input class="mr-8 ml-8 " type="checkbox" name="agree" v-model="agree" />
                            </div>

                            <div class="mt-3">
                                <label
                                    >By checking this box, you are consenting that all of the information you are
                                    providing may be collected, used, shared, and retained for the purposes of applying
                                    for and/or receiving the
                                    <router-link class="main-green-color" to="/"
                                        >Affordable Connectivity Benefit</router-link
                                    >.</label
                                >
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-32">
                        <router-link to="/TribalStatusPage?profileStep=5" class="btn-round btn-black-ghost"
                            >Back</router-link
                        >
                        <div @click="goNext" class="btn-round btn-green">Continue</div>
                    </div>
                    <div class="text-error text-right">{{ error }}</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ProflieBreadcrumbs from '@/components/helpers/ProfileBreadcrumbs.vue'
import checkValidation from '@/components/validation/checkValidation'
import checkCheckbox from '@/components/validation/checkCheckbBox'

export default {
    mounted() {
        if (this.$store.state.PersonalStore?.personalData?.firstName.length < 1) {
            this.$router.push('/ZipCodePage')
        }
    },
    components: {
        ProflieBreadcrumbs,
    },
    methods: {
        setRegisterData() {
            this.$router.push('/AgreementPage?profileStep=7')
        },
        goNext() {
            const validation = {
                agree: checkCheckbox(this.agree),
            }

            if (!this.agree) {
                this.error = 'Checkbox is mandatory'
                return
            }

            checkValidation(validation) === true ? this.setRegisterData() : (this.error = 'All fields are mandatory')
        },
    },
    computed: {
        profileStep() {
            return this.$route.query.profileStep
        },
        personalData: {
            get() {
                return this.$store.state.PersonalStore.personalData
            },
        },
    },
    data() {
        return {
            error: '',
            agree: false
        }
    },
}
</script>
<style scoped>
    .success-alert--without-icon{
        background: #F1F5F9;
        border-radius: 16px;
    }
    label{
        padding-right: 10px;
    }
    td,td b{
        font-family: Roboto;
    }
    @media (min-width: 1200px) {

        .content-style,.position-relative{
            min-width: 813px;
        }
    }
    @media (max-width: 800px) {
        .content-style{
            margin: 0 auto
        }
    }
    @media (min-width: 768px) and (max-width: 780px) {
        .content-style,.position-relative{
            max-width: 690px !important;
        }
    }
    @media (min-width: 375px) and (max-width: 400px) {
        .content-style,.position-relative{
            min-width: 346px !important;
            min-height: 342px;
        }
        td {
            display: block;
            font-size: 14px;
            line-height: 22px;
        }
        .position-absolute{
            display: contents;
        }
        .btn-edit{
            top: 10px
        }
    }
</style>
