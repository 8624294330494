import { SET_AddPersonalData } from '../actions.type'
import axios from "../../_services/axios"

const initialPersonalData = {
    firstName: '',
    middleName: '',
    lastName: '',
    address: '',
    state: '',
    city: '',
    zipCode: '',
    urbanizationCode: '',
    dob: '',
    ssn4: '',
    tribalId: '',
    alternateId: '',
    eligibilityProgramCode: '',
    publicHousingCode: '',
    consentInd: '',
    contactPhoneNumber: '',
    contactEmail: '',
    contactAddress: '',
    contactCity: '',
    contactState: '',
    contactZipCode: '',
    contactUrbCode: '',
    carrierUrl: '',
}
const initialPersonalDataSave = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    contactPhoneNumber: "",
    shippingAddress1: "",
    shippingCity: "",
    shippingState: "",
    state: "",
    shippingZipcode: "",
    dob: "",
}

export const state = {
    personalData: {
        firstName: '',
        middleName: '',
        lastName: '',
        address: '',
        state: '',
        city: '',
        zipCode: '',
        urbanizationCode: '',
        dob: '',
        ssn4: '',
        tribalId: '',
        // bqpFirstName: '',
        // bqpLastName: '',
        // bqpDob: '',
        // bqpSsn4: '',
        // bqpTribalId: '',
        // bqpAlternateId: '',
        alternateId: '',
        eligibilityProgramCode: '',
        publicHousingCode: '',
        consentInd: '',
        contactPhoneNumber: '',
        contactEmail: '',
        contactAddress: '',
        contactCity: '',
        contactState: '',
        contactZipCode: '',
        contactUrbCode: '',
        carrierUrl: '',
    },
    personalDataSave: {
        firstName: "",
        lastName: "",
        emailAddress: "",
        contactPhoneNumber: "",
        shippingAddress1: "",
        shippingCity: "",
        shippingState: "",
        state: "",
        shippingZipcode: "",
        dob: "",
    },
    checkTribal: false
}
export const actions = {
    addToPersonal(context, value) {
        return context.commit(SET_AddPersonalData, {
            ...context.state.personalData,
            [value[0]]: value[1],
        })
    },
    checkZipCode (_, state) {
        return new Promise((resolve, reject) => {
            axios.get(`/public/acp_supported_state/${state}`)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        })
    },
}
export const mutations = {
    SET_AddPersonalData(state, payload) {
        state.personalData = payload
    },
    SET_checkTribal(state, payload) {
        state.checkTribal = payload
    },
    SET_personalDataSave(state, payload) {
        state.personalDataSave = payload
    },
    CLEAR_personalData(state) {
        state.personalData = initialPersonalData
        state.personalDataSave = initialPersonalDataSave
    }
}
const getters = {
    getPersonalData: function (state) {
        return `${state.personalData}`
    },
    eligibilityProgramCode: s => s.personalData.eligibilityProgramCode
}
export default {
    state,
    actions,
    mutations,
    getters,
}
