<template>
    <section class="sliderSection">
        <!--<div class="text-center mb-24">
            <div>Test link:</div>
            <div>
                <router-link to="/QualityACPPage">QualityACPPage</router-link>
            </div>
            <div>
                <router-link to="/LatestBlogPage">LatestBlogPage</router-link>
            </div>
            <div>
                <router-link to="/BlogPageList">BlogPage</router-link>
            </div>
            <div>
                <router-link to="/QualityPlanPage">QualityPlanPage</router-link>
            </div>
            <div>
                <router-link to="/NotQualifedPage">NotQualifedPage</router-link>
            </div>
            <div>
                <router-link to="/RegistrationPage">RegistrationPage</router-link>
            </div>
            <div>
                <router-link to="/ShippigDetailsPage">ShippigDetailsPage</router-link>
            </div>
            <div>
                <router-link to="/VerificationPage">VerificationPage</router-link>
            </div>
            <div>
                <router-link to="/EnrollmentResultPage">EnrollmentResultPage</router-link>
            </div>
            <div>
                <router-link to="/ConfirmProgrammPage">ConfirmProgrammPage</router-link>
            </div>
        </div>-->

        <div class="container">
            <div class="sliderContainer position-relative">
                <div class="sliderTitle">Stay Connected</div>
                <div class="sliderParargaph">
                    The Affordable Connectivity Program (ACP) is a U.S. government program that helps many low income
                    households pay for broadband service and internet connected devices
                </div>
                <div class="d-flex justify-content-center mb-41">
                    <router-link to="/ZipCodePage" class="white-btn mr-30">Apply now</router-link>
                    <router-link to="/AboutACPPage" class="ghost-btn mr-30">Learn more</router-link>
                </div>
                <div class="slider_top_dialog"># administered by USAC with oversight from the FCC</div>
                <div class="slider_bottom_dialog">
                    <div class="d-flex">
                        <div class="mr-16">
                            <img class="images-size"  :src="receiptDiscount" alt="" />
                        </div>
                        <div class="text-left sticky-text">
                            Get the benefits for broadband service and internet connected devices
                        </div>
                    </div>
                </div>
            </div>
            <div class="row slider-footer  justify-content-center mt-5 mt-sm-0">
                <div class="  col-4">
                    <div class="text-center">
                        <div class="count">1M+</div>
                        <div class="f-size-20">over a million registered members</div>
                    </div>
                </div>
                <div class="  col-4">
                    <div class="text-center">
                        <div class="count">$136M+</div>
                        <div class="f-size-20">saved money across the country</div>
                    </div>
                </div>
                <div class="  col-4">
                    <div class="text-center">
                        <div class="count">2M+</div>
                        <div class="f-size-20">vacancies across the country</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    components: {},
    methods: {},
    data() {
        return {
            receiptDiscount: require('../../../assets/images/icons/receiptDiscount.svg'),
        }
    },
}
</script>
<style>
.f-size-20 {
    font-weight: 500;
    line-height: 23.48px;
    font-size: 20px;
    font-family: Raleway;
}
.slider_top_dialog {
    padding: 10px 14px;
    gap: 10px;
    position: absolute;
    width: 235px;
    height: 52px;
    left: 40px;
    top: -11px;
    background: #f8fafc;
    box-shadow: 0px 8px 16px -4px rgba(0, 12, 32, 0.12);
    border-radius: 16px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #003875;
}

.slider_bottom_dialog {
    padding: 16px 8px;
    gap: 8px;
    position: absolute;
    width: 300px;
    height: 140px;
    right: -30px;
    top: 270px;
    background: #ffffff;
    box-shadow: 0px 8px 16px -4px rgba(0, 12, 32, 0.12);
    border-radius: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1e293b;
}
.sticky-text{
  font-weight: 500;
  font-size: 19px;
  line-height: 22.99px;
}

@import '../../../assets/styles/components/sections/HomePage/01_Slider.css';
</style>
<style scoped>
    .container{
        box-shadow: unset;
    }
    .sliderContainer{
        border-radius: 32px;
    }
    .ghost-btn{
        color: white;
    }
    .ghost-btn:hover{
        color: #0a58ca;
    }

    @media (max-width: 568px) {
        .slider_top_dialog {
            left: 0;
            width: 199px !important;
            height: 44px !important;
            padding: 4px 5px !important;
        }
        .f-size-20{
            line-height: 16px;
            font-size: 14px;
        }
        .count {
            font-size: 25px!important;
            line-height: 30px!important;
        }
        .sliderTitle {
            font-size: 35px!important;
            line-height: 41px!important;
        }
        .sliderContainer{
            width: 343px !important;
            height: 482px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        .slider_bottom_dialog{
            width: 343px !important;
            height: 66px !important;
            margin-top: 180px !important;
            margin-right: 30px !important;
        }
        .white-btn{
            position: absolute;
            width: 240px;
            height: 46px;
            margin-right:0;
        }
        .ghost-btn{
            position: absolute;
            width: 240px;
            height: 46px;
            margin-right:0;
            margin-top: 60px;
        }
    }
    @media (max-width: 768px) {
        .count {
            font-family: Lato;
            text-align: center;
            font-size: 45px;
            font-weight: 600;
            line-height: 54px;
            letter-spacing: 0em;

        }
        .container{
            max-width: 768px !important;
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            padding-left: calc(var(--bs-gutter-x) * 0.5);
        }
        .sliderContainer{
            max-width: 689px;

        }
    }
    @media (max-width: 766px) {
        .container{
            max-width: 689px;
            padding: 0;
        }
        .sliderContainer{
            width: inherit;
        }
    }
    @media (max-width: 1024px) {
        .slider_bottom_dialog {
            width: 185px;
            height: 100px;
            padding: 8px 8px;
            margin: 51px 15px 0 0;

        }
        .sticky-text{
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;

        }
        .images-size{
            width: 30px;
            height: 30px;
            margin-right: -6px;
        }
    }
    @media (min-width: 1025px) {
        .sliderParargaph{
            min-width: 664px;
        }
        .slider-footer{
            max-width: 691px;
            margin: 0 auto;
        }
    }

</style>
