export default (req) => {
    const _req = { ...req }
    Object.keys(req).forEach((el) => {
        if (Array.isArray(req[el])) {
            req[el].length === 0 && (delete _req[el])
            return
        }
        if (req[el] !== null && typeof req[el] === 'object') {
            Object.keys(req[el]).length === 0 && (delete _req[el])
            return
        }
        req[el] === 0 || req[el] ? null : delete _req[el]
    });
    return _req
}
