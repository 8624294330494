<template>
    <section class="section section--top--space section--bottom--space">
        <div class="container container-1170">
            <div class="check-enroll-container">
                <div class="section-title text-center container-667">
                    <span class="main-green-color"> {{ $t('enroll.titleCheckYour') }} </span>
                </div>
                <div class="text-center container-667">
                    <h3 style="color: #272d37">{{ $t('enroll.subtitle') }}</h3>
                    <div>{{ $t('enroll.body1') }}</div>
                </div>
                <div>
                    <div class="position-relative general-card general-card-970 mb-32 d-flex justify-content-between">
                        <div class="flex-item">
                            <label>{{ $t('enroll.appId') }}:</label>
                            <input
                                @keyup="checkForActivaite()"
                                placeholder="ID"
                                type="text"
                                class="w-100"
                                v-model="applicationId"
                            />
                        </div>
                        <div class="flex-item">
                            <label>{{ $t('enroll.eligibilityCheckId') }}:</label>
                            <input
                                @keyup="checkForActivaite()"
                                placeholder="ID"
                                type="text"
                                class="w-100"
                                v-model="eligibilityCheckId"
                            />
                        </div>
                        <div class="text-center check-enroll-status">
                            <button
                                :class="{ btnBackgroundGray: checkActivation }"
                                class="check-enroll-status-button btn-round btn-green btnBackgroundZero"
                                @click="redirect"
                            >
                                {{ $t('enroll.check') }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="text-center mobile-hidden">
                    <button
                        :class="{ btnBackgroundGray: checkActivation }"
                        class="check-enroll-status-button btn-round btn-green btnBackgroundZero"
                        :disabled="loading"
                        @click="redirect"
                    >
                      <Loader v-if="loading" color="white" borderRadius="20" />
                        {{ $t('enroll.check') }}
                    </button>
                </div>
                <div class="text-center">
                    <div class="text-error">{{ error }}</div>
                </div>
            </div>
            <div class="section-activeSimCard-small-background"></div>
        </div>
    </section>
</template>

<script>
import eligibilityService from '../../../common/services/checkEligibility'
import { checkString } from '../../validation/value'
import checkValidation from '../../validation/checkValidation'
import {
  destroyApplicationId,
  saveApplicationId,
  saveEligibilityCheckId,
} from '@/common/eligibility.service'
import { PENDING_RESOLUTION_HREF, WAITING_RESULT } from '@/eligibility-enroll/status'
import Loader from '@/components/helpers/Loader.vue'

export default {
    components: { Loader },
    computed: {
        personalData: {
            get() {
                return this.$store.state.PersonalStore.personalData
            },
        },
    },
    methods: {
      checkForActivaite() {
        this.error = ""
        const validation = checkString(this.applicationId) || checkString(this.eligibilityCheckId)
        checkValidation(validation) === true ? (this.checkActivation = false) : (this.checkActivation = true)
      },
      redirect() {
        this.error = ""
        const validation = checkString(this.applicationId) || checkString(this.eligibilityCheckId)
        validation ? this.success() : (this.error = this.$t('error.allMandatory'))
      },
        async success() {
          this.loading = true
          const resultData = await eligibilityService.postEligibilityCheck(this.applicationId, this.eligibilityCheckId)
          this.loading = false

          const appId = resultData?.data?.applicationId
          appId && saveApplicationId(appId)

          if (resultData.data.status === 'APP_CLOSED_OR_EXPIRED') {
            this.$router.push(`/Expired?id=${appId || resultData?.data?.eligibilityCheckId}`)
          }

          if (PENDING_RESOLUTION_HREF.includes(resultData.data.status)) {
            window.location.href = String(resultData.data._links.resolution.href)
            return
          }
          if (resultData.data.status === 'PENDING_CERT') {
            window.location.href = String(resultData.data._links.certification.href)
            return
          }
          if (WAITING_RESULT.includes(resultData.data.status)) {
            const eligibilityCheckId = resultData?.data?.eligibilityCheckId
            eligibilityCheckId && saveEligibilityCheckId(eligibilityCheckId)
            this.$router.push('/Reviewing')
            return
          }
          if (resultData.data.status === 'COMPLETE') {
            this.$router.push('/EnrollStep')
            return
          }
          destroyApplicationId()
          if (resultData.data?.errors?.length > 0) {
            this.error = resultData.data.errors.map(item => (item.message || item.description)).join('. ')
            return
          }
          this.error = resultData.data.message
        },
    },
    data() {
        return {
            checkActivation: true,
            eligibilityCheckId: '',
            applicationId: '',
            result: '',
            error: '',
            loading: false
        }
    },
}
</script>

<style scoped>
.general-card-970 {
    margin: auto;
    max-width: 970px;
    margin-bottom: 20px;
}

.btnBackgroundZero {
    background: none;
    color: #39404d !important;
}

.btnBackgroundGray {
    background: #777;
    pointer-events: none;
    color: #fff !important;
}

.tablet-table {
  margin-top: 40px;
}

.tablet-table tr {
  text-align: left;
}

.tablet-table td {
  padding: 20px;
}

.section-tablet-table {
  color: #fff;
  width: 100%;
}

.section-tablet-table td {
  border-bottom: 1px solid #475569;
  padding-top: 16px;
  padding-bottom: 16px;
}

.section-tablet-black {
  background: #000;
  padding-top: 38px;
  padding-left: 57px;
  padding-bottom: 53px;
  padding-right: 40px;
  margin-bottom: 144px;
}

.section-tablet-h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-transform: capitalize;
  color: #ffffff;
}

.section-tablet-h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -1.1px;
  color: #ffffff;
}

.container-667 {
  max-width: 667px !important;
  margin: 0 auto;
}
.check-enroll-status {
  display: none;
}

@media (max-width: 469px) {
  .check-enroll-status {
    display: block !important;
  }
}
</style>
