<template>
    <section class="mt-42 mb-160">
        <div class="container">
            <div class="home-card" :class="{'height-home-card': !showHide}" >
                <div class="text-center">
                    <h2 class="main-blue-color">Fill out your information</h2>
                    <h3 class="mt-32 mb-32 text-left">Home address where you will get internet</h3>
                </div>
                <div>
                    <div class="mb-16">
                        <label>Street Number and Name</label>
                        <input v-model="street" class="w-100" type="text" placeholder="Street Number and Name" />
                    </div>
                    <div class="mb-16">
                        <label>Apartment, Unit, etc.</label>
                        <input v-model="appartment" class="w-100" type="text" placeholder="Apartment, Unit, etc." />
                    </div>

                    <div class="row state-box ">
                        <div class="mb-16 col-xs-6 col-12">
                            <CustomDropdownSearch
                                    label="State or Territory"
                                    placeholder="State or Territory"
                                    :styleInputDropdownText="state_select_width"
                                    :list="stateToTerritories"
                                    v-model="stateToTerritory"
                            />
                        </div>
                        <div class="mb-16 col-xs-6 col-12 city">
                            <label>City or Town</label>
                            <input v-model="state" class="w-100" type="text" placeholder="City or Town" />
                        </div>
                    </div>

                    <div class="mb-32">
                        <input v-model="showHide" type="checkbox" /> My mailing address is different than my home
                        address
                    </div>
                    <div v-if="showHide">
                        <div class="mb-32">
                            <h3>Contact information</h3>
                        </div>
                        <div class="mb-16">
                            <label>Address <span class="label-required">*</span></label>
                            <input class="w-100" placeholder="Address" type="text" v-model="contactAddress" />
                        </div>
                        <div class="mb-16">
                            <label>City <span class="label-required">*</span></label>
                            <input class="w-100" placeholder="City" type="text" v-model="contactCity" />
                        </div>
                        <div class="mb-16">
                            <CustomDropdownSearch
                                label="State"
                                placeholder="State"
                                :list="states"
                                v-model="contactState"
                            />
                        </div>
                        <div class="mb-16">
                            <label>Zip Code <span class="label-required">*</span></label>
                            <input class="w-100" placeholder="Zip Code" type="text" v-model="contactZipCode" />
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-32">
                    <div>
                        <router-link to="/ZipCodePage" class="text-blue-btn">Back</router-link>
                    </div>
                    <div>
                        <button @click="goNext" class="blue-btn">Next</button>
                    </div>
                </div>
                <div class="text-error text-right">{{ error }}</div>
            </div>
        </div>
    </section>
</template>

<script>
import { checkString } from '../../validation/value'
import checkValidation from '../../validation/checkValidation'
import CustomDropdownSearch from '../../../components/helpers/CustomDropdown.vue'

export default {
    components: {
        CustomDropdownSearch,
    },
    watch:{
        size_width(){
            this.state_select_width = this.size_width >=1400 ? {width:'553px'} : ''
        }
    },
    methods: {
        width () {
            this.size_width = window.innerWidth
        },
        success() {
            if (this.showHide === true) {
                const contactState = this.findShortCodeState(this.state)?.shortcode
                this.$store.dispatch('addToPersonal', ['contactState', contactState])
                this.$store.dispatch('addToPersonal', ['contactAddress', this.address])
                this.$store.dispatch('addToPersonal', ['contactZipCode', this.zipCode])
            }

            this.$router.push('/ContactInformation')
        },
        goNext() {
            const validation = {
                contactState: this.showHide === true ? checkString(this.contactState) : true,
                contactAddress: this.showHide === true ? checkString(this.contactAddress) : true,
                contactZipCode: this.showHide === true ? checkString(this.contactZipCode) : true,
            }
            checkValidation(validation) === true ? this.success() : (this.error = 'Zip code is mandatory')
        },
    },
    data() {
        return {
            showHide: '',
            state: '',
            error: '',
            contactState: '',
            states: [],
            stateToTerritory: '',
            stateToTerritories: [],
            size_width:667,
            state_select_width: null
        }
    },

    created() {
        this.size_width = window.innerWidth
        window.addEventListener('resize', this.width)
    }

}
</script>
<style scoped>
.home-card {
    background: #ffffff;
    box-shadow: 0px 8px 16px -4px rgba(0, 12, 32, 0.12);
    border-radius: 32px;
    max-width: 1290px;
    padding: 44px 84px;
    gap: 32px;
}

.home-card input {
    padding: 16px 12px;
    gap: 8px;
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
}
.container{
    box-shadow: unset;
}
label{
    font-family: Inter;
    font-weight: 400;
    font-size: 14px ;
    line-height: 22px;
    color: #39404D;
}
    @media (min-width: 1400px) {
        .state-box div {
            width: 553px;
        }
        .state-box .city{
            width: 577px !important;
            margin-left: auto;
        }

    }
    @media (min-width: 1400px ) {
        .height-home-card{
            height: 642px;
        }
    }
</style>
