<template>
    <section class="mt-42 mb-160">
        <div class="container">
            <div class="home-card">
                <div class="text-center">
                    <h2 class="main-blue-color">Fill out your information</h2>
                    <h3 class="mt-32 mb-32 text-left">
                        Do you participate in Medicaid or the Supplemental Nutrition Assistance Program (SNAP)?
                    </h3>
                </div>
                <div class="mb-32">
                    <div class="mb-16">
                        <input type="radio" name="data" /> Yes, i think i participate in Medicaid or SNAP
                    </div>
                    <div><input type="radio" name="data" /> No, i dont think i participate</div>
                    <div class="mt-32">Last 4 digits of your Social Security Number</div>
                    <div class="mt-16">XXXX-XX- <input type="text" placeholder="XXXX" /></div>
                </div>
                <div class="d-flex justify-content-between mt-32">
                    <div>
                        <router-link to="/PersonalInformationPage" class="text-blue-btn">Back</router-link>
                    </div>
                    <div>
                        <router-link to="/ReviewPage" class="blue-btn">Next</router-link>
                    </div>
                </div>
            </div>
            <div class="text-error text-right">{{ error }}</div>
        </div>
    </section>
</template>

<script>
export default {
    components: {},
    methods: {},
    data() {
        return {
            error: '',
        }
    },
}
</script>
<style>
.home-card {
    background: #ffffff;
    box-shadow: 0px 8px 16px -4px rgba(0, 12, 32, 0.12);
    border-radius: 32px;
    padding: 44px 84px;
    gap: 32px;
}

.home-card input {
    padding: 16px 12px;
    gap: 8px;
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
}
</style>
