<template>
    <section class="mt-42 mb-80">
        <div class="container">
            <div class="text-center">
                <h2 class="w-752">
                    Please fill in the information to get
                    <span class="main-blue-color">benefits</span>
                </h2>
            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="register-card">
                <div class="text-center mb-32">
                    <h2 class="main-blue-color">Fill out your information</h2>
                </div>
                <div class="mb-32">
                    <h3>Shipping detail</h3>
                </div>
                <div class="d-flex mb-16">
                    <div class="w-100 mr-16">
                        <label>First name</label>
                        <input v-model="firstName" class="w-100" type="text" placeholder="First name" />
                    </div>
                    <div class="w-100">
                        <label>Last name</label>
                        <input v-model="lasttName" class="w-100" type="text" placeholder="Last name" />
                    </div>
                </div>
                <div class="mb-16">
                    <label>Address 1</label>
                    <input v-model="adress1" class="w-100" type="text" placeholder="Enter address 1" />
                </div>
                <div class="mb-16">
                    <label>Address 2</label>
                    <input v-model="adress2" class="w-100" type="text" placeholder="Enter address 2" />
                </div>
                <div class="mb-16">
                    <label>City</label>
                    <input v-model="city" class="w-100" type="text" placeholder="Enter your city" />
                </div>
                <div class="d-flex mb-16">
                    <div class="w-100 mr-16">
                        <label>State</label>
                        <input v-model="state" class="w-100" type="text" placeholder="Enter your state" />
                    </div>
                    <div class="w-100">
                        <label>Zip-code</label>
                        <input v-model="zipCode" class="w-100" type="text" placeholder="00-000" />
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-32">
                    <div>
                        <router-link to="/RegistrationPage" class="text-blue-btn">Back</router-link>
                    </div>
                    <div>
                        <router-link to="/VerificationPage" class="blue-btn">Next</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    components: {},
    methods: {},
    data() {
        return {}
    },
}
</script>
<style></style>
